import { Component, ChangeDetectorRef } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { IntegrationsManagerService } from 'src/app/Managers/integrations-manager.service';
import { ScreeningSettingsManagerService } from 'src/app/Managers/screening-settings-manager.service';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { WebSocketService } from 'src/app/Services/socket/web-socket.service';
import { JobsManagerService } from 'src/app/Managers/jobs-manager.service';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs';
@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrl: './integrations.component.scss',
})
export class IntegrationsComponent {
  integrationId: number = 0;
  platform_name: string = '';
  status: string = 'Active';
  partnerId: number = 0;
  atsName: string = '';
  integrationSyncStatus: any;
  allIntegrationJobProcessStatusSocket: boolean = false;
  integrationStatusMap: { [key: string]: any } = {};
  connectionStatus: boolean = false;
  addGreenhouseForm!: FormGroup;
  webhookForm!: FormGroup;

  apiKeyVisible: boolean = false;
  apiSecretVisible: boolean = false;
  currentStep: number = 0;
  totalSteps: number = 5;
  currentStepWebhook = 0;
  totalStepsWebhook: number = 2;
  platformName: string = '';
  isVisibleJobCollapse = [true, false];
  selectAllOpenJobs = false;
  checked = false;
  loading = false;
  indeterminate = false;
  user: any;
  socketMessage: any;

  code: string = '';
  state: string = '';
  reconnect: boolean = false;

  selectedImportJobs: readonly any[] = [];
  listOfCurrentPageData: readonly any[] = [];
  setOfCheckedId = new Set<any>();
  allOpenJobsChecked = false;
  allClosedJobsChecked = false;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public integrationsMgr: IntegrationsManagerService,
    public auth: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private notification: NzNotificationService,
    private webSocketService: WebSocketService,
    public jobsMgr: JobsManagerService,
    public screeningMgr: ScreeningSettingsManagerService
  ) {}

  async submitGreenhouseIntegration(): Promise<void> {
    if (this.addGreenhouseForm.valid) {
      const name = this.addGreenhouseForm.get('platform_name')?.value;
      const description = this.addGreenhouseForm.get(
        'platform_description'
      )?.value;
      const key = this.addGreenhouseForm.get('platform_api_key')?.value;

      let formData = {
        platform_name: name,
        platform_description: description,
        platform_api_key: key,
        ...(name === 'jobvite' && {
          api_secret: this.addGreenhouseForm.get('api_secret')?.value,
        }),
      };
      await this.integrationsMgr.addGreenhouseIntegration(formData);

      this.jobsMgr.importJobsData = [];
      this.jobsMgr.getApplicatonStatus(this.integrationsMgr.integrationId);
      this.jobsMgr.getImportJobsATS(
        this.integrationsMgr.integrationId,
        this.jobsMgr.jobStatus
      );
      this.currentStep = 1;
    } else {
      Object.values(this.addGreenhouseForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  async leverIntegrations(): Promise<void> {
    this.atsName = 'lever';
    let formData = {
      ats_name: 'lever',
    };
    await this.integrationsMgr.atsCreateLinkToken(formData);
    const leverLink = this.integrationsMgr.linkURL;
    if (leverLink) {
      window.location.href = leverLink;
    }
  }

  cancelGreenhouseModal(): void {
    this.integrationsMgr.addGreenhouseModal = false;
  }
  handleCreateTokenCancel(): void {
    this.integrationsMgr.isCreateTokenModal = false;
  }
  handleSelectJobCancel(): void {
    this.jobsMgr.selectJobModal = false;
    this.addGreenhouseForm.reset();
    this.atsName = '';
  }
  openAddIntegrationModal(): void {
    this.jobsMgr.selectJobModal = true;
    this.currentStep = 0;
    this.integrationsMgr.integrationId = null;
  }
  addIntegrationModalDirect(ats: string): void {
    this.jobsMgr.selectJobModal = true;
    this.atsName = ats;
    this.addGreenhouseForm.patchValue({
      platform_name: ats,
    });
    if (ats === 'jobvite') {
      this.addGreenhouseForm
        .get('api_secret')
        ?.setValidators([Validators.required]);
    } else {
      this.addGreenhouseForm.get('api_secret')?.clearValidators();
    }
    this.screeningMgr.getScreeningActions();
    this.currentStep = 0;
    this.integrationsMgr.integrationId = null;
  }
  toggleSubmenu() {
    const bodyTag = document.body;
    bodyTag.classList.toggle('is-submenu');
    this.auth.isSubmenu = !this.auth.isSubmenu;
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }
  get isSetOfCheckedIdEmpty(): boolean {
    return this.setOfCheckedId.size === 0;
  }

  onCurrentPageDataChange(listOfCurrentPageData: any): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfCurrentPageData.filter(
      ({ disabled }) => !disabled
    );
    this.checked = listOfEnabledData.every(({ id }) =>
      this.setOfCheckedId.has(id)
    );
    this.indeterminate =
      listOfEnabledData.some(({ id }) => this.setOfCheckedId.has(id)) &&
      !this.checked;
  }
  onItemChecked(id: any, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }
  onAllOpenJobsChecked(checked: boolean): void {
    this.setAllJobsChecked('open', checked);
  }

  onAllClosedJobsChecked(checked: boolean): void {
    this.setAllJobsChecked('closed', checked);
  }

  setAllJobsChecked(status: string, checked: boolean): void {
    this.jobsMgr.importJobsData.forEach((job) => {
      if (job.status.toLowerCase() === status) {
        if (checked) {
          this.setOfCheckedId.add(job.remote_id);
        } else {
          this.setOfCheckedId.delete(job.remote_id);
        }
      }
    });
    this.refreshCheckedStatus();
  }
  async nextStep(): Promise<void> {
    if (this.currentStep < this.totalSteps) {
      this.currentStep++;
      const requestData = this.jobsMgr.importJobsData.filter((data) =>
        this.setOfCheckedId.has(data.remote_id)
      );
      this.selectedImportJobs = requestData;
    } else {
      console.log('no step');
    }
  }

  async submitActionMaping(): Promise<void> {
    this.currentStep++;
  }

  async configureWebhook(): Promise<void> {
    const jobsRequest = this.selectedImportJobs.map((job) => ({
      job_id: job.remote_id,
      ats_name: job.ats,
      integration_id: job.integration_id,
    }));
    let data = {
      jobs: jobsRequest,
    };
    this.jobsMgr.importJobs(data);
    await this.jobsMgr.submitApplicatonStatus(
      this.integrationsMgr.integrationId,
      this.jobsMgr.applicationStatus
    );
    //this.integrationWebSocketStatus();
    this.integrationsMgr.getIntegrationsSettings();
    this.currentStep++;
    this.nextConfigureWebhook(
      this.integrationsMgr.integrationId,
      this.integrationsMgr.platform_name
    );
  }
  async submitandSkipWebhook(): Promise<void> {
    const jobsRequest = this.selectedImportJobs.map((job) => ({
      job_id: job.remote_id,
      ats_name: job.ats,
      integration_id: job.integration_id,
    }));
    let data = {
      jobs: jobsRequest,
    };
    this.jobsMgr.importJobs(data);
    await this.jobsMgr.submitApplicatonStatus(
      this.integrationsMgr.integrationId,
      this.jobsMgr.applicationStatus
    );
    //this.integrationWebSocketStatus();
    this.integrationsMgr.getIntegrationsSettings();
    this.jobsMgr.selectJobModal = false;
  }
  async updateActionMaping(): Promise<void> {
    await this.jobsMgr.submitApplicatonStatus(
      this.integrationsMgr.integrationId,
      this.jobsMgr.applicationStatus
    );
    this.integrationsMgr.getIntegrationsSettings();
  }
  changeFollowFeature(feature: boolean): void {
    console.log('boo', feature);
    const formData: any = {
      is_enabled: feature,
    };
    this.jobsMgr.updatedFollowUpFeature(formData);
  }

  previousStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }
  previousStepWebhook(): void {
    if (this.currentStepWebhook > 0) {
      this.currentStepWebhook--;
    }
  }
  goToStep(step: number): void {
    if (step >= 0 && step <= this.totalSteps) {
      this.currentStep = step;
    }
  }
  goToStepWebhook(step: number): void {
    if (step >= 0 && step <= this.totalStepsWebhook) {
      this.currentStepWebhook = step;
    }
  }
  toggleCollapse(index: number): void {
    this.isVisibleJobCollapse[index] = !this.isVisibleJobCollapse[index];
    if (index === 1 && this.isVisibleJobCollapse[index]) {
      this.jobsMgr.getImportJobsATSClosed(
        this.integrationsMgr.integrationId,
        'closed'
      );
    }
  }
  updateFollowUpQuestionFeature(): void {
    this.integrationsMgr.updateFollowUpQuestionFeature(this.partnerId);
  }
  platformChange(value: any): void {
    console.log(value);
    this.atsName = value;
    if (value === 'jobvite') {
      this.addGreenhouseForm
        .get('api_secret')
        ?.setValidators([Validators.required]);
    } else {
      this.addGreenhouseForm.get('api_secret')?.clearValidators();
    }
    this.addGreenhouseForm.get('api_secret')?.updateValueAndValidity();
    this.screeningMgr.getScreeningActions();
  }
  async openIntegrationDrawer(
    id: number,
    platform_name: string,
    status: string
  ): Promise<void> {
    this.status = status;
    this.platform_name = platform_name;
    this.jobsMgr.getApplicatonStatus(id);
    this.jobsMgr.getFollowUpFeature();
    this.integrationsMgr.integrationId = id;
    this.integrationsMgr.visibleIntegrationDrawer = true;
  }
  async nextConfigureWebhook(id: number, platform_name: string): Promise<void> {
    this.platform_name = platform_name;
    this.currentStepWebhook = 0;
    await this.integrationsMgr.getWeebhook(id);
    this.integrationId = id;

    this.webhookForm = this.fb.group({
      webhooks: this.fb.array(
        this.integrationsMgr.webhookData.config.map((webhook) =>
          this.fb.group({
            event_type: new FormControl({
              value: webhook.event_type,
              disabled: true,
            }),
            url: new FormControl({ value: webhook.url, disabled: true }),
            token:
              platform_name === 'lever'
                ? ['', [Validators.required]]
                : undefined,
          })
        )
      ),
    });
  }
  get webhooks() {
    return this.webhookForm.get('webhooks') as FormArray;
  }

  closeIntegrationDrawer(): void {
    this.integrationsMgr.visibleIntegrationDrawer = false;
  }
  async onSubmitWebhook() {
    this.jobsMgr.selectJobModal = false;
  }
  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      if (control instanceof FormArray) {
        control.controls.forEach((group) =>
          this.markFormGroupTouched(group as FormGroup)
        );
      } else {
        control.markAsDirty();
        control.updateValueAndValidity();
      }
    });
  }
  objectKeysFiltered(data: any, atsName: string): string[] {
    return Object.keys(data).filter((key) => key === atsName && data[key]);
  }

  changeScreeningAction(value: boolean, key: string): void {
    this.atsName = key;
    let formData = {
      map_actions_ats_to_clara: value,
    };
    this.screeningMgr.submitScreeningAction(this.atsName, formData);
  }
  // disconnect ATS =========
  disconnectIntegrationModal(): void {
    this.integrationsMgr.visibleDisconnectModal = true;
    this.integrationsMgr.visibleIntegrationDrawer = false;
  }
  backDisconnectIntegrationModal(): void {
    this.integrationsMgr.visibleDisconnectModal = false;
    this.integrationsMgr.visibleIntegrationDrawer = true;
  }
  cancelDisconnectIntegrationModal(): void {
    this.integrationsMgr.visibleDisconnectModal = false;
  }
  disconnectIntegration(): void {
    let data = {
      ats_name: this.platform_name,
    };
    this.integrationsMgr.disconnectIntegration(
      this.integrationsMgr.integrationId,
      data
    );
  }

  // Reconnect ATS =========
  openReconnectIntegrationModal(
    id: number,
    platform_name: string,
    status: string,
    platform_description: string
  ): void {
    this.status = status;
    this.platform_name = platform_name;
    this.integrationsMgr.integrationId = id;

    this.addGreenhouseForm.patchValue({
      platform_name: platform_name,
    });
    this.addGreenhouseForm.patchValue({
      platform_description: platform_description,
    });
    if (platform_name === 'jobvite') {
      this.addGreenhouseForm
        .get('api_secret')
        ?.setValidators([Validators.required]);
    } else {
      this.addGreenhouseForm.get('api_secret')?.clearValidators();
    }
    if (platform_name === 'lever') {
      this.addGreenhouseForm.get('platform_api_key')?.clearValidators();
    }

    this.integrationsMgr.visibleReconnectModal = true;
  }
  cancelReconnectIntegrationModal(): void {
    this.integrationsMgr.visibleReconnectModal = false;
    this.addGreenhouseForm.reset();
  }
  async connectPlatform(): Promise<void> {
    if (this.addGreenhouseForm.valid) {
      const name = this.addGreenhouseForm.get('platform_name')?.value;
      const description = this.addGreenhouseForm.get(
        'platform_description'
      )?.value;
      const key = this.addGreenhouseForm.get('platform_api_key')?.value;

      if (name === 'lever') {
        const formDataLever = {
          ats_name: name,
          connect: true,
        };
        await this.integrationsMgr.reconnectLeverLink(
          formDataLever,
          this.integrationsMgr.integrationId
        );
        const leverLink = this.integrationsMgr.linkURL;
        if (leverLink) {
          window.location.href = leverLink;
        }
      } else {
        const formData = {
          ats_name: name,
          platform_description: description,
          api_key: key,
          api_secret: this.addGreenhouseForm.get('api_secret')?.value || '',
        };
        await this.integrationsMgr.reconnectIntegration(
          this.integrationsMgr.integrationId,
          formData
        );
      }
    } else {
      Object.values(this.addGreenhouseForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  async ngOnInit(): Promise<void> {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    this.partnerId = this.user.partner.id ? this.user.partner.id : 0;

    await this.integrationsMgr.getIntegrationsSettings();
    this.integrationsMgr.getFollowUpQuestionFeature(this.partnerId);

    this.addGreenhouseForm = this.fb.group({
      platform_name: ['', Validators.required],
      platform_description: [''],
      platform_api_key: ['', Validators.required],
      api_secret: ['', Validators.required],
    });

    this.route.queryParams.subscribe(async (queryParams) => {
      const integrationLeverId = queryParams['integration_id']
        ? queryParams['integration_id']
        : '';
      this.code = queryParams['code'] ? queryParams['code'] : '';
      this.state = queryParams['state'] ? queryParams['state'] : '';
      this.reconnect = queryParams['reconnect']
        ? queryParams['reconnect']
        : false;

      if (this.code != '' && this.state != '' && integrationLeverId === '') {
        let formData = {
          data: {
            code: this.code,
            state: this.state,
          },
          ats_name: 'lever',
        };

        await this.integrationsMgr.addLeverIntegration(formData);
        this.jobsMgr.importJobsData = [];
        this.jobsMgr.getApplicatonStatus(this.integrationsMgr.integrationId);
        this.jobsMgr.getImportJobsATS(
          this.integrationsMgr.integrationId,
          this.jobsMgr.jobStatus
        );
        this.addGreenhouseForm.patchValue({
          platform_name: 'lever',
        });
        this.atsName = 'lever';
        this.screeningMgr.getScreeningActions();
        this.jobsMgr.selectJobModal = true;
        this.currentStep = 1;
      }
      if (this.code != '' && this.state != '' && integrationLeverId != '') {
        let formData = {
          data: {
            code: this.code,
            state: this.state,
          },
          ats_name: 'lever',
        };

        await this.integrationsMgr.reconnectLeverIntegration(
          formData,
          integrationLeverId
        );
      }
    });
  }
  integrationWebSocketStatus(): void {
    this.webSocketService.openWebSocket(this.user.id);
    this.webSocketService.connectionStatus$
      .pipe(filter((status) => status))
      .subscribe(() => {
        this.integrationsMgr.integrationsData.forEach((integration) => {
          const payload = {
            type: 'jobs_processing_status',
            integration_id: integration.id,
            partner_id: this.partnerId,
          };
          console.log('payload socket', payload);
          this.webSocketService.sendMessage(JSON.stringify(payload));
        });
      });

    this.webSocketService.messages$.subscribe((message) => {
      if (message !== null && message.integration_id) {
        this.integrationStatusMap[message.integration_id] = message;
        const allStatuses = Object.values(this.integrationStatusMap).every(
          (integration: any) =>
            integration.jobs_processing_status.toLowerCase() === 'done' ||
            integration.jobs_processing_status.toLowerCase() === 'pending'
        );
        this.allIntegrationJobProcessStatusSocket = allStatuses;
        this.cdr.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    this.atsName = '';
    this.code = '';
    this.state = '';
    this.reconnect = false;
    this.integrationsMgr.integrationId = null;
    this.webSocketService.closeWebSocket();
  }
}
