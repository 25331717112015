import { Component } from '@angular/core';
import { FollowUpQuestionsManagerService } from 'src/app/Managers/follow-up-questions-manager.service';
import { ApplicationManagerService } from 'src/app/Managers/applications-manager.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-shorter-follow-up-questions',
  templateUrl: './shorter-follow-up-questions.component.html',
  styleUrl: './shorter-follow-up-questions.component.scss',
})
export class ShorterFollowUpQuestionsComponent {
  isExpanded = false;
  showEditable = false;
  jobObjectId: string = '';
  form!: FormGroup;
  selectedType: string = 'free_text';
  processed_application_count: number = 0;
  deleted_questions: { question_obj_id: string }[] = [];
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public followUpMgr: FollowUpQuestionsManagerService,
    public applicationMgr: ApplicationManagerService,
    private notification: NzNotificationService,
    private cdr: ChangeDetectorRef
  ) {}

  openQuestionsModal(): void {
    this.followUpMgr.isVisibleJobQuestionsModal = true;
  }
  gotoShorterFollowUpQuestions(): void {
    this.router.navigate([
      '/jobs/shorter-follow-up-questions',
      this.jobObjectId,
    ]);
  }
  handleCancel(): void {
    this.followUpMgr.isVisibleJobQuestionsModal = false;
  }
  toggleReadMore() {
    this.isExpanded = !this.isExpanded;
  }
  toggleEditable() {
    this.showEditable = true;
    this.addFollowUpQuestion();
    this.cdr.detectChanges();
  }
  get followUpQuestions(): FormArray {
    return this.form.get('followUpQuestions') as FormArray;
  }

  addFollowUpQuestion(): void {
    const questionGroup = this.fb.group({
      category: ['', [Validators.required]],
      question: ['', [Validators.required]],
      type: ['free_text', [Validators.required]],
      options: this.fb.array([]),
      config: this.fb.group({
        is_multi_select: [false], // Checkbox for multi-select
        is_user_entered: [false], // Checkbox for user-entered answer
      }),
    });
    this.followUpQuestions.push(questionGroup);
  }

  removeFollowUpQuestion(index: number): void {
    const questionToRemove = this.followUpQuestions.at(index).value;
    console.log('Removing', questionToRemove);
    this.deleted_questions.push({
      question_obj_id: questionToRemove._id,
    });
    this.followUpQuestions.removeAt(index);
    console.log('Deleted questions:', this.deleted_questions);
  }
  addOption(index: number): void {
    const optionsArray = this.followUpQuestions
      .at(index)
      .get('options') as FormArray;

    const optionNumber = String.fromCharCode(97 + optionsArray.length); // 97 is the ASCII code for 'a'

    optionsArray.push(
      this.fb.group({
        number: [optionNumber],
        option: [''],
      })
    );
  }

  // Remove an option from the multi_options-options array
  removeOption(questionIndex: number, optionIndex: number): void {
    const optionsArray = this.followUpQuestions
      .at(questionIndex)
      .get('options') as FormArray;
    optionsArray.removeAt(optionIndex); // Remove the option at the specified index
  }

  // Method to handle question type selection
  selectType(type: string, index: number): void {
    const questionGroup = this.followUpQuestions.at(index) as FormGroup;
    questionGroup.get('type')?.setValue(type);

    // Clear options if type changes away from multi_options-select
    if (type !== 'multi_options') {
      const optionsArray = questionGroup.get('options') as FormArray;
      optionsArray.clear();
    }
  }

  // Get the options form array for a specific question
  getOptionsArray(index: number): FormArray {
    return this.followUpQuestions.at(index).get('options') as FormArray;
  }
  async onSubmitFollowUpQuestion(): Promise<void> {
    const invalidQuestion = this.followUpQuestions.value.find(
      (question: any) => {
        return (
          question.type === 'multi_options' &&
          (!question.options ||
            question.options.length === 0 ||
            question.options.some(
              (opt: any) => !opt.option || opt.option.trim() === ''
            ))
        );
      }
    );

    if (invalidQuestion) {
      this.notification.create(
        'error',
        'Validation Error',
        'Please enter valid options for all multi-option questions'
      );
      return; // Stop form submission if validation fails
    }
    if (this.form.valid) {
      const formData = {
        follow_up_questions: this.followUpQuestions.value.map(
          (question: any) => {
            const result: any = {
              category: question.category,
              question: question.question,
              type: question.type,
              options: question.options,
              config: {
                is_multi_select: question.config.is_multi_select,
                is_user_entered: question.config.is_user_entered,
              },
              answer: question.answer || [],
            };
            console.log(question);
            // Add _id if _id exists
            if (question._id) {
              result._id = question._id;
            }

            return result;
          }
        ),
        send_fq_to_existing_applicants:
          this.followUpMgr.statusFollowUpQuestions,
        deleted_questions: this.deleted_questions,
      };
      //console.log('Form Data:', formData);
      await this.followUpMgr.saveJobFollowUpQuestions(
        this.jobObjectId,
        formData
      );
      this.router.navigate(['jobs/candidates/', this.jobObjectId]);
    } else {
      this.notification.create('error', 'Error', 'Fill out Fields');
      const formArray = this.form.get('followUpQuestions') as FormArray;
      formArray.controls.forEach((group) => {
        const formGroup = group as FormGroup;
        Object.keys(formGroup.controls).forEach((key) => {
          const control = formGroup.get(key);
          if (control && control.invalid) {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
        });
      });
    }
  }
  changeNotificationsSettings(status: any): void {
    this.followUpMgr.statusFollowUpQuestions = status;
  }
  toggleEdit(categoryId: number, questionIndex: number): void {
    const category = this.followUpMgr.questionsOnJob.find(
      (c) => c.id === categoryId
    );
    if (category) {
      const question = category.questions[questionIndex];
      question.isEditable = !question.isEditable;
    }
  }
  async ngOnInit() {
    this.form = this.fb.group({
      followUpQuestions: this.fb.array([]),
    });
    this.route.params.subscribe(async (params) => {
      this.jobObjectId = params['jobObjectId'];
    });
    await this.applicationMgr.jobDetail(this.jobObjectId, async () => {
      this.processed_application_count =
        this.applicationMgr.jobDetailData['processed_application_count'];
    });

    await this.followUpMgr.getJobFollowUpQuestions(this.jobObjectId);
    if (this.followUpMgr.questionsOnJob.length > 0) {
      this.followUpMgr.questionsOnJob.forEach((question: any) => {
        const optionsArray =
          question.type === 'multi_options'
            ? question.options.map((opt: any) =>
                this.fb.group({
                  number: [opt.number || ''],
                  option: [opt.option || ''],
                })
              )
            : [];
        const questionGroup = this.fb.group({
          category: [question.category || '', [Validators.required]],
          question: [question.question || '', [Validators.required]],
          type: [question.type || 'free_text', [Validators.required]],
          options: this.fb.array(optionsArray),
          config: this.fb.group({
            is_multi_select: [
              (question.config && question.config.is_multi_select) || false,
            ],
            is_user_entered: [
              (question.config && question.config.is_user_entered) || false,
            ],
          }),
          _id: [question._id],
          answer: [question.answer || ''],
        });
        this.followUpQuestions.push(questionGroup);
        this.showEditable = true;
      });
    }
  }
  ngOnDestroy() {
    this.followUpMgr.questionsOnJob = [];
    this.showEditable = false;
    this.processed_application_count = 0;
    this.deleted_questions = [];
  }
}
