import { Component, Input } from '@angular/core';
import { FollowUpQuestionsManagerService } from 'src/app/Managers/follow-up-questions-manager.service';
import { ApplicationManagerService } from 'src/app/Managers/applications-manager.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-job-follow-upquestions',
  templateUrl: './job-follow-upquestions.component.html',
  styleUrl: './job-follow-upquestions.component.scss',
})
export class JobFollowUPQuestionsComponent {
  isExpanded = false;
  @Input()
  jobObjectId!: string;
  constructor(
    private router: Router,
    public followUpMgr: FollowUpQuestionsManagerService,
    public applicationMgr: ApplicationManagerService
  ) {}

  gotoShorterFollowUpQuestions(): void {
    this.router.navigate([
      '/jobs/shorter-follow-up-questions',
      this.jobObjectId,
    ]);
  }

  changeNotificationsSettings(status: any): void {
    const formData: any = {
      send_follow_up_questions: status ? 'true' : 'false',
    };
    this.followUpMgr.sentStatusFollowUpQuestions(formData, this.jobObjectId);
  }

  ngOnInit() {
    if (this.jobObjectId) {
      this.followUpMgr.getStatusFollowUpQuestions(this.jobObjectId);
      this.followUpMgr.getJobFollowUpQuestions(this.jobObjectId);
    }
  }
  ngOnDestroy() {
    this.followUpMgr.questionsOnJob = [];
  }
}
