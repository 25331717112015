<div class="page bg-white">
    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">
        <nz-spin [nzSpinning]="this.candidateMgr.isLoadingProfile">
            <nz-breadcrumb>
                <nz-breadcrumb-item>
                    <a routerLink="/"><span nz-icon nzType="home"></span></a>
                </nz-breadcrumb-item>
                <nz-breadcrumb-item
                    *ngIf="user.role === 'Recruiter' || userRole === 'Partner Admin' || userRole === 'Account Owner'"><a
                        routerLink="/candidates">Candidates</a></nz-breadcrumb-item>
                <nz-breadcrumb-item>Candidate Details</nz-breadcrumb-item>
            </nz-breadcrumb>
            <div *ngIf="candidateMgr.candidateProfile">
                <div class="sub-tagline">
                    <h1 class="font-heading">{{candidateMgr.candidateProfile.initials}}</h1>
                </div>
                <div nz-row [nzGutter]="16">
                    <!-- <div nz-col [nzSpan]="12" *ngIf="candidateMgr.candidateProfile.website_addresses.length > 0">
                        <div class="border-box font-small">
                            <span class="mr-36">Website</span>
                            <span class="mr-36"
                                *ngFor="let website of candidateMgr.candidateProfile.website_addresses">{{website.value}}</span>
                        </div>
                    </div> -->
                    <div nz-col [nzSpan]="12" *ngIf="candidateMgr.candidateProfile.remote_id">
                        <div class="border-box font-small">
                            <span class="mr-36">CLARA ID</span>
                            <span>{{candidateMgr.candidateProfile.remote_id}}</span>
                        </div>
                    </div>

                </div>
                <div nz-row [nzGutter]="16">
                    <div nz-col [nzSpan]="12" *ngIf="candidateMgr.candidateProfile.first_name">
                        <div class="border-box box-shadow">
                            <h4>General Information</h4>
                            <div class="info-item font-md">
                                <span class="mr-36">First Name</span>
                                <b>{{candidateMgr.candidateProfile.first_name }}</b>
                            </div>
                            <div class="info-item font-md">
                                <span class="mr-36">Last Name</span>
                                <b>{{candidateMgr.candidateProfile.last_name }}</b>
                            </div>

                            <div class="info-item font-md">
                                <span class="mr-36">Location</span>
                                <b lass="mr-36"
                                    *ngFor="let add of candidateMgr.candidateProfile.addresses">{{add.value}}</b>
                            </div>
                        </div>
                        <div class="border-box box-shadow">
                            <h4>Contact Details</h4>
                            <div class="info-item font-md">
                                <span class="mr-36">Phone Number:</span>
                                <b lass="mr-36"
                                    *ngFor="let phone of candidateMgr.candidateProfile.phone_numbers">{{phone.value}}</b>
                            </div>
                            <div class="info-item font-md">
                                <span class="mr-36">Email:</span>
                                <div>
                                    <b class="d-block"
                                        *ngFor="let email of candidateMgr.candidateProfile.email_addresses">{{email.value}}
                                    </b><br>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div nz-col [nzSpan]="12"
                        *ngIf="candidateMgr.candidateProfile.applications && candidateMgr.candidateProfile.applications.length > 0">
                        <div class="border-box box-shadow"
                            *ngIf="(!candidateMgr.isLoadingProfile && candidateMgr.metrics) && (candidateMgr.metrics.learning_agility.is_answered || candidateMgr.metrics.critical_thinking.is_answered || candidateMgr.metrics.distance_traveled.is_answered)">
                            <h4>Scores</h4>
                            <p>The scores in CLARA are estimated from your follow-up questions, based on a consistent
                                and precise assessment process,
                                developed in collaboration with cognitive science professionals at CLARA.</p>
                            <div class="score-panel"
                                (click)="openScoreLevelModal(candidateMgr.metrics.learning_agility,'Learning Agility')"
                                *ngIf="candidateMgr.metrics.learning_agility && candidateMgr.metrics.learning_agility.is_answered">
                                <div class="score-panel-header">
                                    <div class="score-level">
                                        <b>{{candidateMgr.metrics.learning_agility.level
                                            | titlecase}}</b>
                                        <span>Learning Agility</span>
                                    </div>
                                    <div class="ico-link">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="m13.337 7.845-7.173 7.173-1.178-1.179 7.172-7.172H5.837V5h9.166v9.167h-1.666V7.845z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>

                                    </div>
                                </div>
                                <div class="score-level-progress bg-purple" [ngClass]="candidateMgr.metrics.learning_agility.level
                                                                    | lowercase"></div>
                            </div>
                            <div class="score-panel"
                                (click)="openScoreLevelModal(candidateMgr.metrics.critical_thinking,'Critical Thinking')"
                                *ngIf="candidateMgr.metrics.critical_thinking && candidateMgr.metrics.critical_thinking.is_answered">
                                <div class="score-panel-header">
                                    <div class="score-level">
                                        <b>{{candidateMgr.metrics.critical_thinking.level
                                            | titlecase}}</b>
                                        <span>Critical Thinking</span>
                                    </div>
                                    <div class="ico-link">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="m13.337 7.845-7.173 7.173-1.178-1.179 7.172-7.172H5.837V5h9.166v9.167h-1.666V7.845z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>

                                    </div>
                                </div>
                                <div class="score-level-progress bg-purple" [ngClass]="candidateMgr.metrics.critical_thinking.level
                                                                    | lowercase"></div>
                            </div>
                            <div class="score-panel"
                                (click)="openScoreLevelModal(candidateMgr.metrics.distance_traveled,'Distance Traveled')"
                                *ngIf="candidateMgr.metrics.distance_traveled && candidateMgr.metrics.distance_traveled.is_answered">
                                <div class="score-panel-header">
                                    <div class="score-level">
                                        <span>Distance Traveled</span>
                                    </div>
                                    <div class="ico-link">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="m13.337 7.845-7.173 7.173-1.178-1.179 7.172-7.172H5.837V5h9.166v9.167h-1.666V7.845z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>

                                    </div>
                                </div>
                                <div class="score-panel-body">
                                    {{candidateMgr.metrics.distance_traveled.partner_recommendation
                                    | titlecase}}
                                </div>
                            </div>
                        </div>

                        <div class="border-box box-shadow"
                            *ngIf="candidateMgr.candidateProfile.applications && candidateMgr.candidateProfile.applications.length > 0">
                            <h4>Applications</h4>
                            <div class="candidate-score"
                                *ngFor="let item of candidateMgr.candidateProfile.applications">
                                <ng-container *ngIf="item.score != null">
                                    <div nz-row [nzGutter]="16" nzJustify="space-between">
                                        <div nz-col [nzSpan]="12">
                                            <h4>{{item.job.title}} <span class="font-light">{{item.applied_at}}</span>
                                            </h4>
                                        </div>
                                        <div nz-col [nzSpan]="12" class="text-right jos-status">
                                            <nz-tag class="ico-badge" *ngIf="item.job.status === 'closed'">
                                                <nz-badge nzColor="#a8a7ac"></nz-badge>
                                                Closed
                                            </nz-tag>
                                            <span class="font-small" *ngIf="item.job.remote_id">ID
                                                {{item.job.remote_id}}</span>
                                        </div>
                                    </div>

                                    <div class="progress"
                                        (click)="goToJobPage(item.job._id,item.job.status,item.job.ats,item._id)">
                                        <div class="progress-value"
                                            [ngClass]="item.job.status.toLowerCase() === 'open' ? 'theme' : 'gray'"
                                            [ngStyle]="{'width': item.score+'%'}">
                                        </div>
                                        <div class="progress-label">
                                            <span class="font-lg">
                                                {{ isScoreAvailable(item) }}

                                            </span><br><span class="font-light">Clara
                                                Score</span>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="item.score === null">
                                    <div nz-row [nzGutter]="16" nzJustify="space-between">
                                        <div nz-col [nzSpan]="12">
                                            <h4>{{item.job.title}} <span class="font-light">{{item.applied_at
                                                    }}</span>
                                            </h4>
                                        </div>
                                        <div nz-col [nzSpan]="12" class="text-right">
                                            <span class="font-small" *ngIf="item.job.remote_id">ID
                                                {{item.job.remote_id}}</span>
                                        </div>
                                    </div>

                                    <div class="progress"
                                        (click)="goToJobPage(item.job._id,item.job.status,item.job.ats,item._id)">
                                        <div class="progress-value no-score">
                                        </div>
                                        <div class="progress-label"><span class="font-lg font-color-light">
                                                Not Available <span class="ico-sm" nz-popover
                                                    [nzPopoverContent]="contentTemplate" nzPopoverPlacement="topLeft"
                                                    nz-icon nzType="info-circle" nzTheme="outline"></span>
                                            </span><br><span class="font-light">Clara
                                                Score</span>
                                        </div>
                                        <ng-template #contentTemplate>
                                            <div class="info-popover">
                                                Applications for jobs before CLARA was adopted are not processed.
                                            </div>
                                        </ng-template>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </nz-spin>
        <nz-modal [(nzVisible)]="isScoreLevelsModal" [nzClosable]="false" [nzFooter]="null" nzWidth="572px" nzCentered
            [nzMaskClosable]="false">
            <ng-container *nzModalContent>
                <div class="level-modal">

                    <div class="close" (click)="handleCancelModal()">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m10 8.822 4.125-4.125 1.178 1.178L11.178 10l4.125 4.125-1.178 1.178L10 11.178l-4.125 4.125-1.178-1.178L8.822 10 4.697 5.875l1.178-1.178L10 8.822z"
                                fill="#1C1A20" fill-opacity=".6" />
                        </svg>

                    </div>

                    <h2 class="font-heading">{{ scoreType }}</h2>
                    <div *ngIf="scoreType === 'Learning Agility'">
                        <p>Learning Agility refers to the ability of taking in new information and applying it to
                            familiar situations or the
                            opposite scenario in which you use previously learned information and adapt and apply it to
                            a new situation.
                            This construct is made up of cognitive agility, people agility, change agility, results
                            agility and self-awareness.</p>

                        <p> It involves such things as cognitive agility, people agility, change agility, results
                            agility and self-awareness.</p>
                    </div>
                    <div *ngIf="scoreType === 'Critical Thinking'">
                        <p> Critical thinking means making well-reasoned decisions. We define critical thinking as the
                            intellectually disciplined
                            process of actively and skillfully conceptualizing, applying, analyzing, synthesizing,
                            and/or evaluating information
                            gathered from, or generated by, observation, experience, reflection, reasoning, or
                            communication, as a guide to belief
                            and action.</p>

                        <p> It involves processes and skills such as problem solving, analysis, evaluating, asking
                            questions, and inferring.</p>
                    </div>
                    <div *ngIf="scoreType === 'Distance Traveled'">
                        <p>Distance Traveled is a measure of an individual's accomplishments and capacities that
                            considers their starting point and
                            trajectory of progress. It reflects the sum of what they’ve learned along the way to get to
                            where they are. This
                            distance varies and is the overall sum of the situations and circumstance they’ve faced in
                            terms of social identities,
                            income, wealth, occupation, education, social capital, community, culture, and other
                            factors.</p>
                    </div>

                    <div class="score-panel">
                        <div class="score-panel-header">
                            <div class="score-level">
                                <b>{{ scoreData?.level | titlecase }}</b>
                                <span>{{ scoreType }}</span>
                            </div>

                        </div>
                        <div *ngIf="scoreData?.level" class="score-level-progress bg-purple"
                            [ngClass]="scoreData?.level | lowercase"></div>
                    </div>
                    <div class="score-panel-description" [innerHTML]="scoreData?.partner_recommendation">

                    </div>
                    <div class="custom-footer">
                        <button nz-button nzBlock nzSize="large" [nzType]="'default'" nzShape="round"
                            (click)="handleCancelModal()"><span class="color-theme">Close</span></button>
                    </div>
                </div>
            </ng-container>
        </nz-modal>
    </main>

</div>