import { Injectable } from '@angular/core';
import { JobAISettingsService } from '../Services/job-aisettings.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { aiSettings } from '../models/models';

@Injectable({
  providedIn: 'root',
})
export class JobAISettingsManagerService {
  public jobObjectId: string = '';
  public isLoading: boolean = false;
  public isUpdatingAiSettings: boolean = false;
  aisSettingsData: aiSettings[] = [];
  aisChartData: aiSettings[] = [];
  aisSettingsActive: aiSettings[] = [];
  aisSettingsInactive: aiSettings[] = [];
  weightPayload: any = {};
  constructor(
    public aiServices: JobAISettingsService,
    private notification: NzNotificationService
  ) {}
  public getAiSettings(jobId: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.aiServices.aiServices(jobId).subscribe({
        next: (c: any) => {
          this.aisSettingsData = c.response;

          this.aisSettingsActive = c.response.filter(
            (setting: any) => setting.value != 0 && setting.value !== null
          );
          this.aisSettingsInactive = c.response.filter(
            (setting: any) => setting.value === 0 || setting.value === null
          );
          const totalWeight = this.aisSettingsActive.reduce(
            (sum, item) => sum + item.value,
            0
          );
          this.aisSettingsActive.forEach((item) => {
            item.field_percentage = ((item.value / totalWeight) * 100).toFixed(
              1
            );
          });
          this.isLoading = false;

          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isLoading = false;
          reject(error);
        },
      });
    });
  }

  public updateAiSettings(
    data: aiSettings,
    objectId: any,
    action_type: number
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.weightPayload = data;
      const updatedAiSettings = {
        ...data,
        action_type: action_type,
      };
      this.isUpdatingAiSettings = true;
      this.aiServices.updateAiSettings(updatedAiSettings, objectId).subscribe({
        next: (c: any) => {
          this.isUpdatingAiSettings = false;
          if (action_type === 2) {
            this.notification.create('info', 'Preview Scores', '');
            resolve();
          } else if (action_type === 3 || action_type === 1) {
            this.getAiSettings(objectId).then(() => {
              this.notification.create(
                'success',
                'Candidate Scoring Successful',
                'Your candidate list was successfully scored.'
              );
              resolve();
            });
          }

          // this.getAiSettings(jobId).then(() => {
          //   if (action_type === 2) {
          //     this.notification.create('info', 'Preview Scores', '');
          //   } else if (action_type === 3) {
          //     this.notification.create(
          //       'success',
          //       'Candidate Scoring Successful',
          //       'Your candidate list was successfully scored.'
          //     );
          //   }
          //   resolve();
          // });
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isUpdatingAiSettings = false;
          reject(error);
        },
      });
    });
  }
}
