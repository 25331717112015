import { Injectable } from '@angular/core';
import { CommentsService } from '../Services/comments.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Comment } from '../models/models';

@Injectable({
  providedIn: 'root',
})
export class CommentsManagerService {
  public isLoading: boolean = false;
  public jobObjectId: string = '';
  public applicationId = '';
  public candidatesId = 0;
  comments: Comment[] = [];
  commentsApplications: Comment[] = [];
  commentsCandidates: Comment[] = [];
  constructor(
    public commentServices: CommentsService,
    private notification: NzNotificationService
  ) {}
  public getComments(jobObjectId: string) {
    this.isLoading = true;
    this.commentServices.getComments(jobObjectId).subscribe((c: any) => {
      this.comments = c.response.reverse();
      this.isLoading = false;
    });
  }

  public addComment(comment: any, jobObjectId: string) {
    this.isLoading = true;
    this.commentServices.addComment(comment, jobObjectId).subscribe({
      next: (res: any) => {
        this.getComments(this.jobObjectId);
        this.isLoading = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoading = false;
      },
    });
  }
  public getCommentsApplications(jobObjectId: string) {
    this.isLoading = true;
    this.commentServices
      .getCommentsApplications(jobObjectId)
      .subscribe((c: any) => {
        this.commentsApplications = c.response.reverse();
        this.isLoading = false;
      });
  }
  public addCommentApplications(comment: any, id: string) {
    this.isLoading = true;
    this.commentServices.addCommentApplications(comment, id).subscribe({
      next: (res: any) => {
        this.getCommentsApplications(id);
        this.isLoading = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoading = false;
      },
    });
  }

  public getCommentsCandidates(jobObjectId: string) {
    this.isLoading = true;
    this.commentServices
      .getCommentsCandidates(jobObjectId)
      .subscribe((c: any) => {
        this.commentsCandidates = c.response.reverse();

        this.isLoading = false;
      });
  }
  public addCommentCandidates(comment: any, jobObjectId: string) {
    this.isLoading = true;
    this.commentServices.addCommentCandidates(comment, jobObjectId).subscribe({
      next: (res: any) => {
        this.getCommentsCandidates(this.jobObjectId);
        this.isLoading = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoading = false;
      },
    });
  }
}
