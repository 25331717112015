<div class="page">

    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">

        <div nz-row [nzGutter]="16">
            <div nz-col [nzSpan]="20">
                <nz-breadcrumb>
                    <nz-breadcrumb-item>
                        <a routerLink="/"><span nz-icon nzType="home"></span></a>
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item><a routerLink="/jobs">Jobs</a></nz-breadcrumb-item>
                    <nz-breadcrumb-item>Upload Candidates</nz-breadcrumb-item>
                </nz-breadcrumb>
            </div>
        </div>

        <div class="heading">
            <a routerLink="/jobs"><span nz-icon class="heading-icon" nzType="left" nzTheme="outline"></span></a>
            <h1 class="font-heading">Upload Candidates</h1>
        </div>
        <div nz-row [nzGutter]="16">
            <div nz-col [nzSpan]="18">
                <div class="white-box">
                    <div class="white-box-header">
                        <div class="job-steps">
                            <div class="steps-item" [ngClass]="getStepClass(1)">
                                <div class="step-dot">
                                    <div class="white-dot"></div><span class="checkmark" nz-icon nzType="check"></span>
                                </div>
                                <span class="step-title">Bulk Upload</span>
                            </div>
                            <div class="steps-item" [ngClass]="getStepClass(2)">
                                <div class="step-dot">
                                    <div class="white-dot"></div><span class="checkmark" nz-icon nzType="check"></span>
                                </div>
                                <span class="step-title">Finalize</span>
                            </div>

                        </div>

                    </div>
                    <div class="white-box-body" [ngClass]="{'no-padding': currentStep === 2}">
                        <ng-container *ngIf="currentStep === 1">
                            <app-upload-resums (filesSelected)="onFilesSelected($event)"></app-upload-resums>
                        </ng-container>
                        <ng-container *ngIf="currentStep === 2">
                            <div class="resume-finalize">
                                <p>Review the Results</p>
                                <span>Review the system output and make edits as necessary.</span>
                            </div>
                            <div class="finalize-header">
                                <span>File</span>
                                <span>Process</span>
                            </div>
                            <div class="finalize-list">
                                <div class="finalize-item"
                                    *ngFor="let resume of sjmMgr.resumeFinilizeData; let i = index">
                                    <span>{{ resume.name }}</span>
                                    <nz-switch [(ngModel)]="resumeProcessStatuses[i]"></nz-switch>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="white-box-footer">
                        <button (click)="previousStep()" nz-button nzSize="large" [nzType]="'default'"
                            nzShape="round">Back</button>

                        <button *ngIf="currentStep === 1" nz-button nzSize="large" [nzType]="'primary'" nzShape="round"
                            (click)="uploadResums()" [disabled]="selectedFiles.length === 0">
                            Next:Finalize</button>

                        <button *ngIf="currentStep === 2" nz-button nzSize="large" [nzType]="'primary'" nzShape="round"
                            (click)="submitFinalization()">
                            <span *ngIf="sjmMgr.uploadingResums" nz-icon nzType="loading"
                                nzTheme="outline"></span>Upload and Process
                            Candidates</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>