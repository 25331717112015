import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class ScreeningSettingsService {
  constructor(private http: HttpClient) {}
  getScreeningSettings() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(environment.apiURL + `settings/screening-steps/`, {
      headers: header,
    });
  }
  getScreeningActions() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `settings/partner/screening-actions/`,
      {
        headers: header,
      }
    );
  }
  getATSActions(ats: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `settings/partner/screening-actions/${ats}`,
      {
        headers: header,
      }
    );
  }

  submitScreeningAction(ats: string, formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.put(
      environment.apiURL + `settings/partner/screening-actions/${ats}`,
      formData,
      {
        headers: header,
      }
    );
  }
  submitScreeningActionIntegration(integrationId: number, formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.put(
      environment.apiURL +
        `deincore/ats/integration/clara-ats-mapping/${integrationId}`,
      formData,
      {
        headers: header,
      }
    );
  }
  getCompanyBasicSettings() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `settings/partner/company-basics-settings`,
      {
        headers: header,
      }
    );
  }
  saveCompanyAboutUS(content: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.put(
      environment.apiURL + `settings/partner/company-basics-about`,
      content,
      {
        headers: header,
      }
    );
  }
  saveCompanyLogo(logo: any) {
    const formData = new FormData();
    formData.append('file', logo.file);
    formData.append('type', logo.type);
    return this.http.put(
      environment.apiURL + `settings/partner/company-basics-upload-image`,
      formData
    );
  }
  deleteCompanyImage(type: string) {
    return this.http.delete(
      environment.apiURL +
        `settings/partner/company-basics-delete-image/${type}`
    );
  }
  saveCompanyBanner(banner: any) {
    const formData = new FormData();
    formData.append('file', banner.file);
    formData.append('type', banner.type);
    return this.http.put(
      environment.apiURL + `settings/partner/company-basics-upload-image`,
      formData
    );
  }
  saveCompanyColor(colorData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.put(
      environment.apiURL + `settings/partner/company-basics-color`,
      colorData,
      {
        headers: header,
      }
    );
  }
}
