import { Component, HostListener } from '@angular/core';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { PermissionService } from 'src/app/Services/permission.service';
import { ImpersonationManagerService } from 'src/app/Managers/impersonation-manager.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent {
  user: any;
  isMyProfile = false;
  constructor(
    public auth: AuthenticationService,
    private permissionService: PermissionService,
    public imprMgr: ImpersonationManagerService
  ) {}

  // @HostListener('document:click', ['$event'])
  // onDocumentClick(event: MouseEvent) {
  //   const target = event.target as HTMLElement;
  //   if (
  //     !target.closest('.sub-menu') &&
  //     !target.closest('.hamburger') &&
  //     !target.closest('.setting-icon') &&
  //     !target.closest('.breadcrumb-menu-item')
  //   ) {
  //     this.closeSubmenu();
  //   }
  // }

  toggleSubmenu() {
    const bodyTag = document.body;
    bodyTag.classList.toggle('is-submenu');
    this.auth.isSubmenu = !this.auth.isSubmenu;
  }

  closeSubmenu() {
    const bodyTag = document.body;
    bodyTag.classList.remove('is-submenu');
    this.auth.isSubmenu = false;
    this.isMyProfile = false;
  }

  logout(): void {
    this.imprMgr.impersonating = false;
    this.permissionService.clearPermissionsOnLogout();
    this.auth.logout();
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    const userRole = this.user.role?.trim();
    if (
      userRole === 'Admin' ||
      userRole === 'Partner Admin' ||
      userRole === 'Account Owner'
    ) {
      this.imprMgr.currentImpersonation();
    }
  }
}
