import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ScreeningSettingsService } from '../Services/screening-settings.service';
import { screeningSettings } from '../models/models';

@Injectable({
  providedIn: 'root',
})
export class ScreeningSettingsManagerService {
  public isLoading: boolean = false;
  public isLoadingBtn: boolean = false;
  public isVisiableScreeningAction: boolean = false;
  public isVisibleScreeningModal: boolean = false;
  screeningSettingData: screeningSettings[] = [];
  atsAction: string[] = [];
  screeningActionsData: any = {};
  companyBasicData: any = {};
  constructor(
    private notification: NzNotificationService,
    private screeningServices: ScreeningSettingsService
  ) {}
  public getScreeningSettings(): void {
    this.isLoading = true;
    this.screeningServices.getScreeningSettings().subscribe({
      next: (c: any) => {
        this.screeningSettingData = c.response;
        this.isLoading = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoading = false;
      },
    });
  }
  public getATSActions(ats: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.screeningServices.getATSActions(ats).subscribe({
        next: (c: any) => {
          this.atsAction = c.application_status;
          this.isLoading = false;
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isLoading = false;
          reject(error);
        },
      });
    });
  }

  public getScreeningActions(): void {
    this.isLoading = true;
    this.screeningServices.getScreeningActions().subscribe({
      next: (c: any) => {
        this.screeningActionsData = c.response;
        this.isLoading = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoading = false;
      },
    });
  }

  public submitScreeningAction(ats: string, formData: any): void {
    this.isLoadingBtn = true;
    this.screeningServices.submitScreeningAction(ats, formData).subscribe({
      next: (c: any) => {
        this.isVisiableScreeningAction = false;
        this.getScreeningActions();
        this.notification.create(
          'success',
          'Done!',
          'Mapping Updated Successfully'
        );
        this.isLoadingBtn = false;
        this.isVisibleScreeningModal = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoadingBtn = false;
      },
    });
  }
  public submitScreeningActionIntegration(
    integrationId: number,
    formData: any
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoadingBtn = true;
      this.screeningServices
        .submitScreeningActionIntegration(integrationId, formData)
        .subscribe({
          next: (c: any) => {
            this.notification.create(
              'success',
              'Done!',
              'Mapping Added Successfully'
            );
            this.isLoadingBtn = false;
            resolve();
          },
          error: (error: any) => {
            if (error.status !== 500) {
              this.notification.create('error', 'Error', error.error.error);
            }
            this.isLoadingBtn = false;
            reject(error);
          },
        });
    });
  }
  public getCompanyBasicSettings(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.screeningServices.getCompanyBasicSettings().subscribe({
        next: (c: any) => {
          this.companyBasicData = c.response;
          this.isLoading = false;

          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isLoading = false;
          reject(error);
        },
      });
    });
  }
  public saveCompanyAboutUS(content: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.screeningServices.saveCompanyAboutUS(content).subscribe({
        next: (c: any) => {
          this.getCompanyBasicSettings();
          this.notification.create('success', 'Done!', 'Saved Successfully.');
          this.isLoading = false;
          console.log('c.response', c.response);
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isLoading = false;
          reject(error);
        },
      });
    });
  }
  public saveCompanyLogo(logo: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.screeningServices.saveCompanyLogo(logo).subscribe({
        next: (c: any) => {
          this.getCompanyBasicSettings();
          this.notification.create(
            'success',
            'Done!',
            'Logo saved successfully.'
          );
          this.isLoading = false;
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isLoading = false;
          reject(error);
        },
      });
    });
  }
  public deleteCompanyImage(type: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.screeningServices.deleteCompanyImage(type).subscribe({
        next: (c: any) => {
          this.getCompanyBasicSettings();
          this.notification.create('success', 'Done!', 'Deleted successfully.');
          this.isLoading = false;
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isLoading = false;
          reject(error);
        },
      });
    });
  }
  public saveCompanyBanner(banner: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.screeningServices.saveCompanyBanner(banner).subscribe({
        next: (c: any) => {
          this.getCompanyBasicSettings();
          this.notification.create(
            'success',
            'Done!',
            'Banner saved successfully.'
          );
          this.isLoading = false;
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isLoading = false;
          reject(error);
        },
      });
    });
  }
  public saveCompanyColor(colorData: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.screeningServices.saveCompanyColor(colorData).subscribe({
        next: (c: any) => {
          this.getCompanyBasicSettings();
          this.notification.create(
            'success',
            'Done!',
            'Color saved successfully.'
          );
          this.isLoading = false;
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isLoading = false;
          reject(error);
        },
      });
    });
  }
}
