import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FollowUpQuestionsService {
  constructor(private http: HttpClient) {}

  getFollowUpQuestions(id: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL +
        `deincore/candidate/applications/${id}/get-followup-questions/`,
      {
        headers: header,
      }
    );
  }
  submitFollowUpQuestions(formData: any, id: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http.post(
      environment.apiURL +
        `deincore/candidate/applications/${id}/submit-answers/`,
      formData,
      {
        headers: header,
      }
    );
  }
  getStatusFollowUpQuestions(id: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http.get(
      environment.apiURL +
        `deincore/partner/job/${id}/send-status-followup-questions/`,

      {
        headers: header,
      }
    );
  }
  saveJobFollowUpQuestions(id: string, formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http.post(
      environment.apiURL + `deincore/partner/job/${id}/followup-questions/`,
      formData,
      {
        headers: header,
      }
    );
  }
  updateJobFollowUQuestion(id: string, formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http.put(
      environment.apiURL + `deincore/partner/job/${id}/followup-questions/`,
      formData,
      {
        headers: header,
      }
    );
  }
  getJobFollowUpQuestions(id: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http.get(
      environment.apiURL + `deincore/partner/job/${id}/followup-questions/`,

      {
        headers: header,
      }
    );
  }
  sentStatusFollowUpQuestions(formData: any, id: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    return this.http.put(
      environment.apiURL +
        `deincore/partner/job/${id}/send-status-followup-questions/`,
      formData,
      {
        headers: header,
      }
    );
  }
}
