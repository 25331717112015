import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { DashboardManagerService } from 'src/app/Managers/dashboard-manager.service';
import { IntegrationsManagerService } from 'src/app/Managers/integrations-manager.service';
import { PermissionService } from 'src/app/Services/permission.service';
import { JobsManagerService } from 'src/app/Managers/jobs-manager.service';
import { ApplicationManagerService } from 'src/app/Managers/applications-manager.service';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  intervalSubscription!: Subscription;
  visibleNotificationDrawer = false;
  visibleJobModal = false;
  jobActionStatus: { [key: string]: boolean } = {};
  user: any;

  constructor(
    public auth: AuthenticationService,
    public dashboardMgr: DashboardManagerService,
    public integrationsMgr: IntegrationsManagerService,
    public permissionService: PermissionService,
    public jobsMgr: JobsManagerService,
    public applicationMgr: ApplicationManagerService,
    private cdRef: ChangeDetectorRef,
    private ngZone: NgZone
  ) {}

  openNotificationDrawer(): void {
    this.visibleNotificationDrawer = true;
  }

  closeNotificationDrawer(): void {
    this.visibleNotificationDrawer = false;
  }

  readNotification(id: number): void {
    this.dashboardMgr.readNotification(id);
  }

  getUnreadNotificationsCount(): number {
    return this.dashboardMgr.notificationsList.filter(
      (notification) => notification.status === 'unread'
    ).length;
  }

  async openJobsModal(): Promise<void> {
    this.visibleJobModal = true;
    this.startCheckingCounts();
  }

  closeJobsModal(): void {
    this.visibleJobModal = false;
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  isRecent(dateString: string): boolean {
    const postingDate = new Date(dateString);
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);
    return postingDate >= sevenDaysAgo;
  }

  async processCandidates(id: string) {
    this.jobActionStatus[id] = true;
    await this.jobsMgr.candidatesProcessing(id);
    // this.jobsMgr.jobsActiveDataHome = [];
    for (let page = this.jobsMgr.homeJobPage; page >= 1; page--) {
      await this.jobsMgr.getActiveJobsHome(page);
    }
    this.startCheckingCounts();
  }

  loadMoreHomeJobs() {
    this.jobsMgr.homeJobPage++;
    this.jobsMgr.getActiveJobsHome(this.jobsMgr.homeJobPage);
  }

  startCheckingCounts() {
    const checkInterval = 15000;
    this.intervalSubscription = interval(checkInterval).subscribe(async () => {
      if (!this.auth.isLoggedIn()) {
        if (this.intervalSubscription) {
          this.intervalSubscription.unsubscribe();
        }
        return;
      }
      for (const job of this.jobsMgr.jobsActiveDataHome) {
        if (job.received_application_count > job.processed_application_count) {
          await this.applicationMgr.jobApplicationCounts(job._id);
          this.ngZone.run(() => {
            this.updateJobCounts(
              this.applicationMgr.currentCounts.job_obj_id,
              this.applicationMgr.currentCounts
            );
          });
        }
      }
    });
  }

  updateJobCounts(jobId: string, updatedCounts: any) {
    const jobIndex = this.jobsMgr.jobsActiveDataHome.findIndex(
      (j) => j._id === jobId
    );

    if (jobIndex !== -1) {
      this.jobsMgr.jobsActiveDataHome[jobIndex] = {
        ...this.jobsMgr.jobsActiveDataHome[jobIndex],
        ...updatedCounts,
      };

      this.jobsMgr.jobsActiveDataHome = [...this.jobsMgr.jobsActiveDataHome];

      if (
        updatedCounts.received_application_count ===
        updatedCounts.processed_application_count
      ) {
        this.jobActionStatus[jobId] = false;
      }

      this.ngZone.run(() => {
        this.cdRef.detectChanges();
      });
    }
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    const userRole = this.user.role?.trim();

    if (userRole === 'Partner Admin' || userRole === 'Account Owner') {
      this.integrationsMgr.getJobsStatus('open');
    }

    this.dashboardMgr.getNotifications();

    if (
      this.user.role === 'Partner Admin' ||
      this.user.role === 'Account Owner' ||
      this.user.role === 'Recruiter'
    ) {
      this.jobsMgr.getActiveJobsHome(1);
    }
  }

  ngOnDestroy() {
    this.jobsMgr.homeJobPage = 1;
    this.jobsMgr.jobsActiveDataHome = [];
    this.integrationsMgr.isIntegrationsDone = false;
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }
}
