import { Component } from '@angular/core';
import { UsersManagerService } from 'src/app/Managers/users-manager.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss'],
})
export class PartnersComponent {
  isLoading: boolean = false;
  validateForm!: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    public usersServiceManger: UsersManagerService
  ) {}

  addModal(): void {
    this.usersServiceManger.isPartnerModal = true;
  }
  handleCancel(): void {
    this.usersServiceManger.isPartnerModal = false;
    this.validateForm.reset();
  }
  async submitForm(): Promise<void> {
    if (this.validateForm.valid) {
      await this.usersServiceManger.addNewPartner(this.validateForm.value);
      this.validateForm.reset();
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  handlePageIndexChange(pageIndex: number): void {
    this.usersServiceManger.partnerPage = pageIndex;
    this.usersServiceManger.getPartners(this.usersServiceManger.partnerPage);
  }
  onDeletePartner(id: number) {
    this.usersServiceManger.deletePartner(id);
  }
  customerSubscriptionChange(value: boolean): void {
    if (value) {
      this.validateForm.get('plan')?.setValidators([Validators.required]);
    } else {
      this.validateForm.get('plan')?.clearValidators();
    }
    this.validateForm.get('plan')?.updateValueAndValidity();
  }
  ngOnInit(): void {
    this.usersServiceManger.getPartners(1);
    this.validateForm = this.fb.group({
      partner_name: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]],
      stripe_customer: [true],
      plan: ['', [Validators.required]],
    });
  }
  ngOnDestroy() {
    this.usersServiceManger.partnerPage = 1;
  }
}
