<nz-spin [nzSpinning]="candidateMgr.isLoadingCandidates">
    <div class="btn-group">
        <button (click)="goToTab('bookmarked')" [class.active]="currentTab === 'bookmarked'">Bookmarked</button>
        <button (click)="goToTab('candidates')" [class.active]="currentTab === 'candidates'">All Candidates</button>
    </div>
    <ng-container *ngIf="currentTab === 'bookmarked'">
        <div class="fiter-form" *ngIf="!candidateMgr.isLoadingCandidates && candidateMgr.bookmarksData.length > 0">
            <div nz-row [nzGutter]="16">
                <div nz-col [nzSpan]="5">
                    <form nz-form [formGroup]="searchFormBookmark" (ngSubmit)="submitSearchBookmark()">
                        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" nzSize="large">
                            <input type="text" (input)="onSearchInputBookmark()" nz-input placeholder="Search"
                                formControlName="search" />
                        </nz-input-group>
                        <ng-template #suffixIconButton>
                            <button nz-button nzType="default" class="bg-gray" nzSearch nzSize="large"><span nz-icon
                                    nzType="search"></span></button>
                        </ng-template>
                    </form>
                </div>
            </div>
        </div>

        <ng-container *ngIf="!candidateMgr.isLoadingCandidates && candidateMgr.bookmarksData.length === 0">
            <div class="fiter-form">
                <div nz-row [nzGutter]="16">
                    <div nz-col [nzSpan]="5">
                        <form nz-form [formGroup]="searchFormCandidate" (ngSubmit)="submitSearchCandidates()">
                            <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" nzSize="large">
                                <input type="text" (input)="onSearchInputCandidates()" nz-input placeholder="Search"
                                    formControlName="search" />
                            </nz-input-group>
                            <ng-template #suffixIconButton>
                                <button nz-button nzType="default" class="bg-gray" nzSearch nzSize="large"><span nz-icon
                                        nzType="search"></span></button>
                            </ng-template>
                        </form>
                    </div>
                </div>
            </div>
            <div nz-row [nzGutter]="16">
                <div nz-col nzXs="24" nzSm="12" nzMd="8" nzLg="6" nzXl="4"
                    *ngFor="let data of candidateMgr.candidateData">
                    <div class="fancy-card">
                        <div class="fancy-card-header" [ngClass]="{'no-bookmarked': !data.is_bookmarked}">
                            <div>
                                <h3>{{data.initials}}</h3>
                                <p>ID {{ data.remote_id ? data.remote_id : data['_id'] }}</p>
                            </div>
                            <div class="ico-bookmark" (click)="bookmarkCandidate(data._id,data.is_bookmarked)">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4.167 1.667h11.666a.833.833 0 0 1 .834.833v15.953a.417.417 0 0 1-.639.353L10 15.026l-6.028 3.78a.417.417 0 0 1-.639-.353V2.5a.833.833 0 0 1 .834-.833z"
                                        fill="#E5DBFC" />
                                </svg>

                            </div>
                        </div>
                        <div class="fancy-card-body">
                            <!-- <div class="body-title">
                                                Senior Product Manager
                                            </div> -->
                            <p class="color-black">Jobs Applied:</p>
                            <p *ngFor="let item of data.jobs; let i = index" class="truncate" [title]="item.title">
                                <ng-container *ngIf="i < 2">
                                    {{ item.title }}
                                </ng-container>
                            </p>
                        </div>
                        <div class="fancy-card-footer">
                            <a (click)="goToCandidatePage(data._id)">See more</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="load-more-container" *ngIf="candidateMgr.nextPageUrl != null">
                <button nz-button nzType="primary" nzSize="large" nzShape="round" (click)="loadMoreCandidate()">Load
                    More</button>
            </div>
        </ng-container>
        <div nz-row [nzGutter]="16">
            <div nz-col nzXs="24" nzSm="12" nzMd="8" nzLg="6" nzXl="4" *ngFor="let data of candidateMgr.bookmarksData">
                <div class="fancy-card">
                    <div class="fancy-card-header">
                        <div>
                            <h3>{{data.initials}}</h3>
                            <p>ID {{ data.remote_id ? data.remote_id : data['_id']}}</p>
                        </div>
                        <div class="ico-bookmark" (click)="removeBookmarked(data._id,true)">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4.167 1.667h11.666a.833.833 0 0 1 .834.833v15.953a.417.417 0 0 1-.639.353L10 15.026l-6.028 3.78a.417.417 0 0 1-.639-.353V2.5a.833.833 0 0 1 .834-.833z"
                                    fill="#E5DBFC" />
                            </svg>

                        </div>
                    </div>
                    <div class="fancy-card-body">
                        <!-- <div class="body-title">
                            Senior Product Manager
                        </div> -->
                        <p class="color-black">Jobs Applied:</p>
                        <p *ngFor="let item of data.jobs_applied; let i = index" class="truncate" [title]="item.title">
                            <ng-container *ngIf="i < 2">
                                {{ item.title }}
                            </ng-container>
                        </p>
                    </div>
                    <div class="fancy-card-footer">
                        <a (click)="goToCandidatePage(data._id)">See more</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="load-more-container" *ngIf="!candidateMgr.noMoreBookmarks">
            <button nz-button nzType="primary" nzSize="large" nzShape="round" (click)="loadMoreBookmarked()">Load
                More</button>
        </div>
    </ng-container>
    <ng-container *ngIf="currentTab === 'candidates'">
        <div class="fiter-form">
            <div nz-row [nzGutter]="16">
                <div nz-col [nzSpan]="5">
                    <form nz-form [formGroup]="searchFormCandidate" (ngSubmit)="submitSearchCandidates()">
                        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" nzSize="large">
                            <input type="text" (input)="onSearchInputCandidates()" nz-input placeholder="Search"
                                formControlName="search" />
                        </nz-input-group>
                        <ng-template #suffixIconButton>
                            <button nz-button nzType="default" class="bg-gray" nzSearch nzSize="large"><span nz-icon
                                    nzType="search"></span></button>
                        </ng-template>
                    </form>
                </div>
            </div>
        </div>

        <div nz-row [nzGutter]="16">
            <div nz-col nzXs="24" nzSm="12" nzMd="8" nzLg="6" nzXl="4" *ngFor="let data of candidateMgr.candidateData">
                <div class="fancy-card">
                    <div class="fancy-card-header" [ngClass]="{'no-bookmarked': !data.is_bookmarked}">
                        <div>
                            <h3>{{data.initials}}</h3>
                            <p>ID {{ data.remote_id ? data.remote_id : data['_id']}}</p>
                        </div>
                        <div class="ico-bookmark" (click)="bookmarkCandidate(data._id,data.is_bookmarked)">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4.167 1.667h11.666a.833.833 0 0 1 .834.833v15.953a.417.417 0 0 1-.639.353L10 15.026l-6.028 3.78a.417.417 0 0 1-.639-.353V2.5a.833.833 0 0 1 .834-.833z"
                                    fill="#E5DBFC" />
                            </svg>

                        </div>
                    </div>
                    <div class="fancy-card-body">
                        <!-- <div class="body-title">
                                    Senior Product Manager
                                </div> -->
                        <p class="color-black">Jobs Applied:</p>
                        <p *ngFor="let item of data.jobs; let i = index" class="truncate" [title]="item.title">
                            <ng-container *ngIf="i < 2">
                                {{ item.title }}
                            </ng-container>
                        </p>
                    </div>
                    <div class="fancy-card-footer">
                        <a (click)="goToCandidatePage(data._id)">See more</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="load-more-container" *ngIf="candidateMgr.nextPageUrl != null">
            <button nz-button nzType="primary" nzSize="large" nzShape="round" (click)="loadMoreCandidate()">Load
                More</button>
        </div>
    </ng-container>
</nz-spin>