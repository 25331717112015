<div class="page">
    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">
        <nz-breadcrumb>
            <nz-breadcrumb-item>
                <a routerLink="/"><span nz-icon nzType="home"></span></a>
            </nz-breadcrumb-item>
            <nz-breadcrumb-item><a class="breadcrumb-menu-item"
                    (click)="toggleSubmenu()">Settings</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Company Basics</nz-breadcrumb-item>
        </nz-breadcrumb>
        <nz-spin [nzSpinning]="screeningMgr.isLoading">
            <div nz-row [nzGutter]="16">
                <div nz-col>
                    <div class="heading-flex">
                        <h1>Company Basics</h1>
                    </div>
                    <nz-tabset>
                        <nz-tab nzTitle="About">
                            <div class="sub-info-action">
                                <p>Your company’s ‘About’ page will be visible to candidates applying <br>through CLARA.
                                    If
                                    left
                                    blank, no ‘About’ page will be
                                    displayed.</p>
                                <button nz-button nzType="default" nzShape="round" *ngIf="isEditableAboutUs"
                                    (click)="toggleAboutUs()">Edit <span nz-icon nzType="edit"
                                        nzTheme="outline"></span></button>
                                <div class="buttons-info" *ngIf="!isEditableAboutUs">
                                    <button nz-button nzType="default" nzShape="round" (click)="discardAboutUs()"
                                        class="mr-8">Discard</button>
                                    <button nz-button nzType="primary" nzShape="round"
                                        (click)="saveAboutUs()">Save</button>
                                </div>
                            </div>

                            <div class="editor" [ngClass]="{'disabled-menu-bar': isEditableAboutUs}">
                                <!-- <quill-editor [(ngModel)]="aboutUsContent" [modules]="editorConfig"
                                    class="editor-content">
                                </quill-editor> -->
                                <!-- app.component.html -->

                                <ckeditor [editor]="Editor" [config]="config" [(ngModel)]="aboutUsContent"></ckeditor>

                                <div class="preview-button">
                                    <button class="btn-preview" (click)="openAboutModal()">
                                        Preview
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M20 3h2v6h-2V5h-4V3h4zM4 3h4v2H4v4H2V3h2zm16 16v-4h2v6h-6v-2h4zM4 19h4v2H2v-6h2v4z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </nz-tab>
                        <nz-tab nzTitle="Candidate-Facing Appearance">
                            <div class="sub-info-action">
                                <p>Candidate-facing pages like the application page, and the company ‘About’ page can be
                                    modified to match your company
                                    brand.</p>
                                <div class="float-right">
                                    <button class="btn-preview" (click)="openAboutModal()">
                                        Preview
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M20 3h2v6h-2V5h-4V3h4zM4 3h4v2H4v4H2V3h2zm16 16v-4h2v6h-6v-2h4zM4 19h4v2H2v-6h2v4z"
                                                fill="#1C1A20" fill-opacity=".6" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div class="appearance">
                                <div class="appearance-item">
                                    <div class="flex-1">
                                        <span class="color-black">Primary Action Containers (hex)</span> <br />
                                        <span class="font-med">This color is used in buttons, against a white
                                            background.</span>
                                    </div>
                                    <div class="input-value">
                                        <input nz-input [(ngModel)]="primary_containers" nzSize="large"
                                            (blur)="validateAndSubmitHexColor('primary_containers')"
                                            (input)="validateHexColor('primary_containers')"
                                            (keydown)="handleKeydown($event, 'primary_containers')"
                                            [ngClass]="{ 'invalid-input': !isValidHexColor(primary_containers) }" />
                                    </div>
                                </div>
                                <div class="appearance-item">
                                    <div class="flex-1">
                                        <span class="color-black">Primary Action Text (hex)</span> <br />
                                        <span class="font-med">This color is used in text, inside the primary action
                                            containers.</span>
                                    </div>
                                    <div class="input-value">
                                        <input nz-input [(ngModel)]="primary_text" nzSize="large"
                                            (blur)="validateAndSubmitHexColor('primary_text')"
                                            (input)="validateHexColor('primary_text')"
                                            (keydown)="handleKeydown($event, 'primary_containers')"
                                            [ngClass]="{ 'invalid-input': !isValidHexColor(primary_text) }" />
                                    </div>
                                </div>
                                <div class="appearance-item">
                                    <div class="flex-1">
                                        <span class="color-black">Secondary Action Color (hex)</span> <br />
                                        <span class="font-med">This color is used in links, and bright text against
                                            white background.</span>
                                    </div>
                                    <div class="input-value">
                                        <input nz-input [(ngModel)]="secondary_color" nzSize="large"
                                            (blur)="validateAndSubmitHexColor('secondary_color')"
                                            (input)="validateHexColor('secondary_color')"
                                            (keydown)="handleKeydown($event, 'primary_containers')"
                                            [ngClass]="{ 'invalid-input': !isValidHexColor(secondary_color) }" />
                                    </div>
                                </div>
                                <div class="appearance-item">
                                    <div class="flex-1">
                                        <span class="color-black">Logo mark</span> <br />
                                        <span class="font-med">This color is used in links, and bright text against
                                            white background.<br>(300 x 200 px high or less).</span>
                                    </div>
                                    <div class="input-value">
                                        <div class="upload-container" appDnd (fileDropped)="onLogoDropped($event)"
                                            *ngIf="!logoFileName">
                                            <input nz-input type="file" nzSize="large" accept=".png, .svg, .jpg, .jpeg"
                                                (change)="changeLogoFile($event)" />
                                            <div class="button-icon">
                                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M3.5 22.167h21V24.5h-21v-2.333zM15.167 6.799v13.034h-2.334V6.8L5.75 13.883l-1.65-1.65 9.9-9.9 9.9 9.9-1.65 1.65-7.083-7.081v-.003z"
                                                        fill="#1C1A20" fill-opacity=".7" />
                                                </svg>
                                            </div>
                                            <h3><strong>Click to upload</strong> or drag and drop (JPG, PNG,SVG,JPEG)
                                            </h3>
                                        </div>
                                        <div class="preview-file" *ngIf="logoFileName">
                                            <div class="d-flex align-middle">
                                                <div class="button-icon"><span class="checkmark-green" nz-icon
                                                        nzType="check"></span></div>
                                                <span class="file-name">{{ logoFileName }}</span>
                                            </div>
                                            <span class="cancel-file" nz-icon nzType="close" nzTheme="outline"
                                                (click)="deleteLogo()"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="appearance-item">
                                    <div class="flex-1">
                                        <span class="color-black">Banner Image</span> <br />
                                        <span class="font-med">This image will appear in the About page, before the
                                            copy. (1128 x 191 px).</span>
                                    </div>
                                    <div class="input-value">
                                        <div class="upload-container" appDnd (fileDropped)="onBannerDropped($event)"
                                            *ngIf="!bannerFileName">
                                            <input nz-input type="file" nzSize="large" accept=".png, .jpg, .jpeg"
                                                (change)="changeBannerFile($event)" />
                                            <div class="button-icon">
                                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M3.5 22.167h21V24.5h-21v-2.333zM15.167 6.799v13.034h-2.334V6.8L5.75 13.883l-1.65-1.65 9.9-9.9 9.9 9.9-1.65 1.65-7.083-7.081v-.003z"
                                                        fill="#1C1A20" fill-opacity=".7" />
                                                </svg>
                                            </div>
                                            <h3><strong>Click to upload</strong> or drag and drop (JPG, PNG,JPEG)
                                            </h3>
                                        </div>
                                        <div class="preview-file" *ngIf="bannerFileName">
                                            <div class="d-flex align-middle">
                                                <div class="button-icon"><span class="checkmark-green" nz-icon
                                                        nzType="check"></span></div>
                                                <span class="file-name">{{ bannerFileName }}</span>
                                            </div>
                                            <span class="cancel-file" nz-icon nzType="close" nzTheme="outline"
                                                (click)="deleteBanner()"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nz-tab>
                    </nz-tabset>
                </div>
            </div>
        </nz-spin>
        <nz-modal [(nzVisible)]="isVisibleAboutModal" nzTitle="About Page Preview" (nzOnCancel)="handleAboutCancel()"
            [nzFooter]="null" [nzBodyStyle]="{ 'height': '95vh', 'overflow-y': 'auto' }" [nzStyle]="{ top: '0' }"
            nzWidth="1024px" nzClassName="no-border-radius" [nzMaskClosable]="false">
            <ng-container *nzModalContent>
                <div class="logo-preview">
                    <img [src]="selectedLogoFile" alt="">
                    <span>Powered by <strong>CLARA</strong></span>
                </div>
                <div class="banner-preview">
                    <img [src]="selectedBannerFile" alt="">
                </div>
                <div [innerHTML]="updatedTextWithLinks"></div>
            </ng-container>
        </nz-modal>
    </main>
</div>