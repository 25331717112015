<nz-modal [(nzVisible)]="isVisible" nzTitle="Assign New Job" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()"
    nzWidth="700px" [nzFooter]="null" [nzMaskClosable]="false">
    <ng-container *nzModalContent>
        <nz-spin [nzSpinning]="this.userMgr.isLoading">
            <div class="center-form">
                <nz-tabset>
                    <nz-tab nzTitle="Hiring Managers">
                        <div class="user-group">
                            <nz-table #usersHiringManager [nzData]="userMgr.usersHR" [nzFrontPagination]="false">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th>Role</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let user of usersHiringManager.data">
                                        <td>
                                            <div class="custom-radio">
                                                <input type="radio" [id]="'radio' + user.id" name="radio-group"
                                                    (change)="onChangeUser(user.id)" [checked]="user.isAssignedJob" />
                                                <label [for]="'radio' + user.id"></label>
                                            </div>
                                        </td>
                                        <td>{{ user.user_profile?.first_name }} </td>
                                        <td>{{ user.role }}</td>
                                        <td>{{ user.email }}</td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </div>
                    </nz-tab>
                    <nz-tab nzTitle="Recruiters">
                        <div class="user-group">
                            <nz-table #usersHiringRecruiters [nzData]="userMgr.usersRecruiter"
                                [nzFrontPagination]="false">
                                <thead>
                                    <tr>
                                        <th [nzChecked]="checked" [nzIndeterminate]="indeterminate" nzLabel="Select all"
                                            (nzCheckedChange)="onAllChecked($event)">
                                        </th>
                                        <th>Name</th>
                                        <th>Role</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let user of usersHiringRecruiters.data">
                                        <td [nzChecked]="setOfCheckedId.has(user.id)"
                                            (nzCheckedChange)="onItemChecked(user.id, $event)">
                                        </td>
                                        <td>{{ user.user_profile?.first_name }} </td>
                                        <td>{{ user.role }}</td>
                                        <td>{{ user.email }}</td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </div>
                    </nz-tab>
                </nz-tabset>

                <div class="custom-footer">
                    <button class="btn-theme-clara d-block mb-10" (click)="assignJob()">
                        <span *ngIf="this.userMgr.isAssigningUser" nz-icon nzType="loading" nzTheme="outline"></span>
                        &nbsp;Submit
                    </button>
                    <button nz-button nzType="text" nzBlock (click)="handleOk()" nzShape="round">
                        Cancel
                    </button>
                </div>
            </div>
        </nz-spin>
    </ng-container>
</nz-modal>