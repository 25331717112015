import { Component } from '@angular/core';
import { UsersManagerService } from 'src/app/Managers/users-manager.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  validateForm!: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    public usersServiceManger: UsersManagerService
  ) {}
  submitForm(): void {
    if (this.validateForm.valid) {
      this.usersServiceManger.forgotUser(this.validateForm.value);
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      email: ['', [Validators.email, Validators.required]],
    });
  }
  ngOnDestroy() {
    this.usersServiceManger.isEmailSent = false;
  }
}
