<div class="fullscreen bg-gradient">
    <div class="shape1"></div>
    <div class="shape2"></div>

    <div class="login-wrap">
        <h1>Create Account</h1>
        <nz-alert class="pb-20" *ngIf="isError" nzType="error" nzMessage="Invalid Username or Password!"
            nzShowIcon></nz-alert>

        <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
            <label for="firstName">First Name*</label>
            <nz-form-item>
                <nz-form-control nzErrorTip="Please input your first name!">
                    <input nzSize="large" nz-input formControlName="firstName" placeholder="Email" id="firstName" />
                </nz-form-control>
            </nz-form-item>
            <label for="lastName">Last Name*</label>
            <nz-form-item>
                <nz-form-control nzErrorTip="Please input your first name!">
                    <input nzSize="large" nz-input formControlName="lastName" placeholder="Email" id="lastName" />
                </nz-form-control>
            </nz-form-item>
            <label for="email">Email*</label>
            <nz-form-item>
                <nz-form-control nzErrorTip="Please input your first name!">
                    <input nzSize="large" nz-input formControlName="email" placeholder="Email" id="email" />
                </nz-form-control>
            </nz-form-item>
            <label for="password">New Password*</label>
            <nz-form-item>
                <nz-form-control nzErrorTip="Please input your New Password!">
                    <nz-input-group [nzSuffix]="suffixTemplate" nzSize="large">
                        <input [type]="passwordVisible ? 'text' : 'password'" nzSize="large" nz-input
                            formControlName="password" placeholder="Password" id="password" />
                    </nz-input-group>
                    <ng-template #suffixTemplate>
                        <span nz-icon [nzType]="passwordVisible ? 'eye' : 'eye-invisible'"
                            (click)="passwordVisible = !passwordVisible"></span>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <div class="register-info">
                    <div class="register-info-item">
                        <span class="pr-10 material-symbols-outlined">
                            check
                        </span>
                        <span>Must be at least 8 characters</span>
                    </div>
                    <div class="register-info-item">
                        <span class="pr-10 material-symbols-outlined">
                            check
                        </span>
                        <span>Must contain at least 1 special character</span>
                    </div>
                </div>
            </nz-form-item>

            <div class="custom-footer">
                <button nz-button nzBlock nzSize="large" [nzType]="'primary'" nzShape="round"><span *ngIf="isLoading"
                        nz-icon nzType="loading" nzTheme="outline"></span>
                    Get Started</button>
                <a routerLink="/login"> <button nz-button nzType="link" nzBlock nzSize="large">Back to
                        login</button></a>
            </div>
        </form>
    </div>
</div>


<nz-modal [(nzVisible)]="isVisibleModal" nzTitle="Add Information" (nzOnCancel)="handleCancel()" [nzFooter]="null"
    nzWidth="600px" [nzMaskClosable]="false">
    <ng-container *nzModalContent>
        <nz-steps [nzCurrent]="currentStep" class="wizard">
            <nz-step></nz-step>
            <nz-step></nz-step>
        </nz-steps>
        <div class="multi-form">
            <form [formGroup]="form" nzLayout="vertical" nzNoColon nz-form (ngSubmit)="nextStep()">
                <ng-container *ngIf="currentStep === 0">
                    <!-- Company Information Form -->
                    <h2>Company Information</h2>
                    <nz-form-item>
                        <nz-form-label>Company Name*</nz-form-label>
                        <nz-form-control>
                            <input nz-input placeholder="Company Name" nzSize="large" formControlName="companyName" />
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label>Website</nz-form-label>
                        <nz-form-control>
                            <input nz-input placeholder="Website" nzSize="large" formControlName="website" />
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label>Phone Number</nz-form-label>
                        <div nz-col>
                            <div nz-row>
                                <div nz-col [nzSpan]="16">
                                    <nz-form-control>
                                        <input nz-input placeholder="Phone Number" nzSize="large"
                                            formControlName="phoneNumber" />
                                    </nz-form-control>
                                </div>
                                <div nz-col [nzSpan]="1">&nbsp;</div>
                                <div nz-col [nzSpan]="7">
                                    <nz-form-control>
                                        <input nz-input placeholder="EXT" nzSize="large" formControlName="ext" />
                                    </nz-form-control>
                                </div>
                            </div>
                        </div>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label>Address</nz-form-label>
                        <div nz-col>
                            <nz-form-control class="mb-10">
                                <input nz-input placeholder="Street 1" nzSize="large" formControlName="street1" />
                            </nz-form-control>
                            <nz-form-control class="mb-10">
                                <input nz-input placeholder="Street 2" nzSize="large" formControlName="street2" />
                            </nz-form-control>
                            <nz-form-control class="mb-10">
                                <input nz-input placeholder="City" nzSize="large" formControlName="city" />
                            </nz-form-control>
                            <div nz-row>
                                <div nz-col [nzSpan]="7">
                                    <nz-form-control>
                                        <input nz-input placeholder="State/Region" nzSize="large"
                                            formControlName="state" />
                                    </nz-form-control>
                                </div>
                                <div nz-col [nzSpan]="1">&nbsp;</div>
                                <div nz-col [nzSpan]="16">
                                    <nz-form-control>
                                        <input nz-input placeholder="Postal Code / Zip Code" nzSize="large"
                                            formControlName="zipCode" />
                                    </nz-form-control>
                                </div>
                            </div>
                        </div>
                    </nz-form-item>
                </ng-container>
                <ng-container *ngIf="currentStep === 1">
                    <!-- Invite User Form -->
                    <h2>Invite User</h2>
                    <nz-form-item>
                        <nz-form-label>First Name</nz-form-label>
                        <nz-form-control>
                            <input nz-input placeholder="First Name" nzSize="large" formControlName="userFirstName" />
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label>Last Name</nz-form-label>
                        <nz-form-control>
                            <input nz-input placeholder="Last Name" nzSize="large" formControlName="userLastName" />
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label>Email</nz-form-label>
                        <nz-form-control>
                            <input nz-input placeholder="Email" nzSize="large" formControlName="userEmail" />
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label>Role</nz-form-label>
                        <nz-form-control>
                            <nz-select nzAllowClear nzPlaceHolder="Choose Role" nzSize="large"
                                formControlName="userRole">
                                <nz-option nzValue="Company Administrator" nzLabel="Company Administrator"></nz-option>
                                <nz-option nzValue="Recruiter" nzLabel="Recruiter"></nz-option>
                                <nz-option nzValue="Hiring Manager" nzLabel="Hiring Manager"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </ng-container>

                <div class="custom-footer">
                    <button nz-button nzBlock class="mb-10" nzSize="large" nzType="primary"
                        nzShape="round">{{currentStep === 1 ?
                        'Finish' : 'Next'}}</button>
                    <button nz-button nzBlock nzSize="large" (click)="prevStep()"
                        *ngIf="currentStep > 0">Previous</button>
                </div>
            </form>
        </div>
    </ng-container>

</nz-modal>