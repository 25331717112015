<div class="follow-up">

    <div class="flex-center" *ngIf="followUpMgr.questionsOnJob.length === 0">
        <div class="info-box">
            <div class="ico-wrap">
                <span class="ico-icon">
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.723 19.833h16.61v-14H4.667V21.45l2.056-1.616zm.808 2.334L2.333 26.25V4.667A1.167 1.167 0 0 1 3.5 3.5h21a1.167 1.167 0 0 1 1.167 1.167V21a1.167 1.167 0 0 1-1.167 1.167H7.53zm5.302-5.834h2.334v2.334h-2.334v-2.334zm-2.838-6.051A4.085 4.085 0 1 1 14 15.167h-1.167v-2.334H14a1.75 1.75 0 1 0-1.716-2.093l-2.29-.458z"
                            fill="#1C1A20" fill-opacity=".6" />
                    </svg>
                </span>
            </div>

            <p><span class="color-theme">Follow-Up Questions</span><br>
                <strong>You can ask follow-up questions to new Candidates.</strong> <br>
                To get started, click on Add Follow Up Questions.
            </p>
            <button (click)="gotoShorterFollowUpQuestions()" nz-button nzType="primary" nzSize="large"
                nzShape="round">Get
                Started</button>
        </div>
    </div>
    <div *ngIf="followUpMgr.questionsOnJob.length > 0">
        <div class="job-questions">
            <div class="job-questions-header">
                <div class="question-title">
                    <span>Follow Up Questions</span>
                    <span class="question-number">{{followUpMgr.questionsOnJob.length}}</span>
                </div>
                <div>
                    <button nz-button nzType="default" nzSize="large" class="btn-gray" nzShape="round"
                        (click)="gotoShorterFollowUpQuestions()">Manage
                        Question(s)</button>
                </div>
            </div>

            <div class="job-questions-body">
                <div class="job-question-item" *ngFor="let category of followUpMgr.questionsOnJob">
                    <div class="question-heading">
                        <span *ngIf="category.type === 'multi_options'">Multi Options</span>
                        <span *ngIf="category.type === 'yes_no'">Yes / No</span>
                        <span *ngIf="category.type === 'free_text'">Free text</span>
                        <span *ngIf="category.type === 'scale'">Scale</span>
                        <span class="tag ml-16">{{ category.category }}</span>
                    </div>
                    <div class="editable-text">
                        <div>
                            {{category.question }}</div>
                        <div class="editable-icon" (click)="gotoShorterFollowUpQuestions()">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5 19h1.414l9.314-9.314-1.414-1.414L5 17.586V19zm16 2H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L9.243 19H21v2zM15.728 6.858l1.414 1.414 1.414-1.414-1.414-1.414-1.414 1.414z"
                                    fill="#1C1A20" fill-opacity=".7"></path>
                            </svg>
                        </div>
                    </div>
                    <div *ngIf="category.options.length > 0" class="option-list">
                        <div *ngFor="let option of category.options; let i = index">
                            <span>{{i+1}}.&nbsp;{{ option.option }}</span>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>


</div>