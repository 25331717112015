import { Injectable } from '@angular/core';
import {
  integrations,
  webhook,
  jobRefinementUsers,
  followupQuestionsSettings,
} from '../models/models';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { IntegrationsService } from '../Services/integrations.service';

@Injectable({
  providedIn: 'root',
})
export class IntegrationsManagerService {
  public isLoading: boolean = false;
  public isLoadingFollowupSettings: boolean = false;
  public visibleIntegrationDrawer = false;
  public visibleDisconnectModal = false;
  public visibleReconnectModal = false;
  public isBtnLoading: boolean = false;
  public isLoadingPage: boolean = false;
  public isLoadingAssignJob: boolean = false;
  public isVisibleAssignJob: boolean = false;
  isCreateLinkModal: boolean = false;
  addGreenhouseModal: boolean = false;
  allIntegrationJobProcessStatus: boolean = false;
  isCreateTokenModal: boolean = false;
  isIntegrationsDone: boolean = false;
  integrationsData: integrations[] = [];
  jobRefinementUsers: jobRefinementUsers[] = [];
  webhookData!: webhook;
  autoJobProcessing: boolean = false;
  followUpFeature: boolean = false;
  getFollowFeature: boolean = false;
  webhookLoading: boolean = false;
  mapStatusLoading: boolean = false;
  loadingRefineUsers: boolean = false;
  changingStatus: boolean = false;
  reconnecting: boolean = false;
  linkToken: string = '';
  linkURL: string = '';
  integrationId: any = null;
  platform_name: string = '';
  publicToken: string = '';
  followupSettings!: followupQuestionsSettings;
  constructor(
    private notification: NzNotificationService,
    private integrationsServices: IntegrationsService
  ) {}
  public getFollowUpQuestionFeature(id: number): void {
    this.getFollowFeature = true;
    this.integrationsServices.getFollowUpQuestionFeature(id).subscribe({
      next: (c: any) => {
        this.followUpFeature = c.followup_questions_settings.is_enabled;
        this.getFollowFeature = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.getFollowFeature = false;
      },
    });
  }
  public updateFollowUpQuestionFeature(partnerId: number): void {
    this.isLoading = true;
    this.integrationsServices.updateFollowUpQuestionFeature().subscribe({
      next: (c: any) => {
        this.isLoading = false;
        this.getFollowUpQuestionFeature(partnerId);
        this.notification.create(
          'success',
          'Follow-Up Questions!',
          'The email sending process has started in the background.'
        );
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoading = false;
      },
    });
  }
  public getIntegrationsSettings(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoadingPage = true;
      this.integrationsServices.getIntegrationsSettings().subscribe({
        next: (c: any) => {
          this.integrationsData = c.response.results;
          if (this.integrationsData.length > 0) {
            const status = this.integrationsData.every(
              (integration) =>
                integration.jobs_processing_status.toLowerCase() === 'done' ||
                integration.jobs_processing_status.toLowerCase() === 'pending'
            );
            this.allIntegrationJobProcessStatus = status;
          }
          this.isCreateLinkModal = false;
          this.isLoadingPage = false;
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isLoading = false;
          reject(error);
        },
      });
    });
  }

  public getJobsStatus(status: string): void {
    this.isLoading = true;
    this.integrationsServices.getActiveJobsStatus(status).subscribe({
      next: (c: any) => {
        this.integrationsData = c.results;

        if (c.results.length === 0) {
          this.isIntegrationsDone = true;
        } else {
          this.isIntegrationsDone = false;
        }
        this.isCreateLinkModal = false;
        this.isLoading = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoading = false;
      },
    });
  }

  public addGreenhouseIntegration(data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isBtnLoading = true;
      this.integrationsServices.addGreenhouseIntegration(data).subscribe({
        next: (c: any) => {
          this.getIntegrationsSettings();
          this.integrationId = c.response.id;
          this.platform_name = c.response.platform_name;
          this.addGreenhouseModal = false;
          this.isBtnLoading = false;
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isBtnLoading = false;
          reject(error);
        },
      });
    });
  }
  public addLeverIntegration(data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoadingPage = true;
      this.integrationsServices.addLeverIntegration(data).subscribe({
        next: (c: any) => {
          this.getIntegrationsSettings();
          this.isLoadingPage = false;
          this.integrationId = c.response.id;
          this.platform_name = c.response.platform_name;
          resolve();
        },
        error: (error: any) => {
          this.isLoadingPage = false;
          reject(error);
        },
      });
    });
  }
  public reconnectLeverIntegration(data: any, id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoadingPage = true;
      this.integrationsServices.reconnectLeverIntegration(data, id).subscribe({
        next: (c: any) => {
          this.getIntegrationsSettings();
          this.isLoadingPage = false;
          this.integrationId = c.response.id;
          this.platform_name = c.response.platform_name;
          resolve();
        },
        error: (error: any) => {
          this.isLoadingPage = false;
          reject(error);
        },
      });
    });
  }

  public atsCreateLinkToken(data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isBtnLoading = true;
      this.integrationsServices.atsCreateLinkToken(data).subscribe({
        next: (c: any) => {
          this.linkURL = c.response.url;
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isBtnLoading = false;
          reject(error);
        },
      });
    });
  }
  public reconnectLeverLink(data: any, id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isBtnLoading = true;
      this.integrationsServices.reconnectLeverLink(data, id).subscribe({
        next: (c: any) => {
          this.linkURL = c.response.url;
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isBtnLoading = false;
          reject(error);
        },
      });
    });
  }
  public getAccountToken(data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isBtnLoading = true;
      this.integrationsServices.getAccountToken(data).subscribe({
        next: (c: any) => {
          this.isCreateTokenModal = false;
          this.isBtnLoading = false;
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isBtnLoading = false;
          reject(error);
        },
      });
    });
  }

  public getJobProcessingSettings(): void {
    this.isLoading = true;
    this.integrationsServices.getJobProcessingSettings().subscribe({
      next: (c: any) => {
        this.autoJobProcessing = c.response.auto_job_processing;
        this.isLoading = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoading = false;
      },
    });
  }

  public updateJobProcessingSettings(data: any): void {
    this.isLoading = true;
    this.integrationsServices.updateJobProcessingSettings(data).subscribe({
      next: (c: any) => {
        this.isLoading = false;
        this.getJobProcessingSettings();
        this.notification.create(
          'success',
          'Updated!',
          'Auto Job Processing has been updated!'
        );
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoading = false;
      },
    });
  }
  public followupQuestionsSettings(): void {
    this.isLoadingFollowupSettings = true;
    this.integrationsServices.followupQuestionsSettings().subscribe({
      next: (c: any) => {
        this.followupSettings = c.response;
        this.isLoadingFollowupSettings = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoadingFollowupSettings = false;
      },
    });
  }
  public updateFollowupQuestionsSettings(data: any): void {
    this.isLoadingFollowupSettings = true;
    this.integrationsServices.updateFollowupQuestionsSettings(data).subscribe({
      next: (c: any) => {
        this.isLoadingFollowupSettings = false;
        this.followupQuestionsSettings();
        this.notification.create(
          'success',
          'Updated!',
          'Follow up questions have been updated!'
        );
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoadingFollowupSettings = false;
      },
    });
  }
  public getWeebhook(id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.webhookLoading = true;
      this.integrationsServices.getWeebhook(id).subscribe({
        next: (c: any) => {
          this.webhookData = c.response;
          this.webhookLoading = false;
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.webhookLoading = false;
          reject(error);
        },
      });
    });
  }
  public postWeebhook(id: number, data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.webhookLoading = true;
      this.integrationsServices.postWeebhook(id, data).subscribe({
        next: (c: any) => {
          this.webhookData = c.response;
          this.webhookLoading = false;
          this.visibleIntegrationDrawer = false;
          this.notification.create(
            'success',
            'Done!',
            'The webhook configuration has been updated.'
          );
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.webhookLoading = false;
          reject(error);
        },
      });
    });
  }
  public getJobRefinementUsers(): void {
    this.loadingRefineUsers = true;
    this.integrationsServices.getJobRefinementUsers().subscribe({
      next: (c: any) => {
        this.jobRefinementUsers = c.response;
        this.loadingRefineUsers = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.loadingRefineUsers = false;
      },
    });
  }
  public updateJobRefinementUsers(data: any): void {
    this.loadingRefineUsers = true;
    this.integrationsServices.updateJobRefinementUsers(data).subscribe({
      next: (c: any) => {
        this.loadingRefineUsers = false;
        this.isLoadingAssignJob = false;
        this.isVisibleAssignJob = false;
        this.notification.create(
          'success',
          '',
          'Job Settings Updated Successfully!'
        );
        this.getJobRefinementUsers();
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.loadingRefineUsers = false;
      },
    });
  }
  public disconnectIntegration(id: string, data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.changingStatus = true;
      this.integrationsServices.disconnectIntegration(id, data).subscribe({
        next: (c: any) => {
          this.visibleDisconnectModal = false;
          this.getIntegrationsSettings();
          this.changingStatus = false;
          this.notification.create('success', '', 'Integration disconnected');
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.changingStatus = false;
          reject(error);
        },
      });
    });
  }
  public reconnectIntegration(id: string, data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isBtnLoading = true;
      this.integrationsServices.reconnectIntegration(id, data).subscribe({
        next: (c: any) => {
          this.getIntegrationsSettings();
          this.isBtnLoading = false;
          this.visibleReconnectModal = false;
          this.notification.create('success', '', 'Integration reconnected');
          resolve();
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isBtnLoading = false;
          reject(error);
        },
      });
    });
  }
}
