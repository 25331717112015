import { Component } from '@angular/core';
import { UsersManagerService } from 'src/app/Managers/users-manager.service';
import { metrics } from 'src/app/models/users';

@Component({
  selector: 'app-candidate-my-profile',
  templateUrl: './candidate-my-profile.component.html',
  styleUrl: './candidate-my-profile.component.scss',
})
export class CandidateMyProfileComponent {
  isScoreLevelsModal: boolean = false;
  scoreData: any = {};
  scoreType: string = '';
  constructor(public userMgr: UsersManagerService) {}
  user: any;
  openScoreLevelModal(data: any, type: string): void {
    this.scoreData = data;
    this.scoreType = type;
    this.isScoreLevelsModal = true;
  }
  handleCancelModal(): void {
    this.isScoreLevelsModal = false;
  }
  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    this.userMgr.getMyCandidateProfile();
  }
  ngOnDestroy() {
    this.userMgr.metrics = new metrics();
  }
}
