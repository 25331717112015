<div class="page">
    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">
        <nz-breadcrumb>
            <nz-breadcrumb-item>
                <a routerLink="/"><span nz-icon nzType="home"></span></a>
            </nz-breadcrumb-item>
            <nz-breadcrumb-item><a class="breadcrumb-menu-item"
                    (click)="toggleSubmenu()">Settings</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>My Account</nz-breadcrumb-item>
        </nz-breadcrumb>

        <div nz-row [nzGutter]="16">
            <div nz-col>
                <div class="heading-flex">
                    <h1>My Account</h1>
                    <button *ngIf="user.role !== 'Admin'" nz-button nzType="default"
                        (click)="editProfileModal(userMgr.myProfileData)">Edit</button>
                </div>
                <div class="description-list">
                    <div class="description-list-item">
                        <div class="description-list-term">First Name</div>
                        <div class="description-list-detail">
                            {{userMgr.myProfileData?.user_profile?.first_name}}</div>
                    </div>
                    <div class="description-list-item">
                        <div class="description-list-term">Last Name</div>
                        <div class="description-list-detail">
                            {{userMgr.myProfileData?.user_profile?.last_name}}</div>
                    </div>
                    <div class="description-list-item">
                        <div class="description-list-term">Phone Number</div>
                        <div class="description-list-detail">
                            {{userMgr.myProfileData?.user_profile?.phone_number}}</div>
                    </div>
                    <div class="description-list-item">
                        <div class="description-list-term">Email</div>
                        <div class="description-list-detail">{{userMgr.myProfileData?.email}}</div>
                    </div>
                    <div class="description-list-item">
                        <div class="description-list-term">Password</div>
                        <div class="description-list-detail"><button nzShape="round" nz-button nzType="default"
                                (click)="changePasswordModal()">Update Password</button>
                        </div>
                    </div>
                    <div class="description-list-item justify-content-between">
                        <div class="description-list-term"></div>
                        <div class="description-list-detail"><button nz-button nzType="default" (click)="logout()">Log
                                Out</button>
                        </div>
                    </div>
                </div>
                <div class="remove-account" *ngIf="user.role !== 'Admin'">
                    <button nz-button nzType="text" (click)="removeAccountModal()">Remove Account</button>
                </div>
            </div>

        </div>
        <nz-modal [(nzVisible)]="userMgr.isProfileModal" (nzOnCancel)="handleProfileCancel($event)" [nzFooter]="null"
            [nzMaskClosable]="false">
            <ng-container *nzModalContent>
                <div class="center-form">
                    <div class="form-header">
                        <h2>My Account</h2>
                    </div>
                    <form [formGroup]="formEditProfile" nz-form nzNoColon nzLabelAlign="left" nzLayout="vertical"
                        (ngSubmit)="editProfile()">
                        <ng-container>
                            <nz-form-item>
                                <nz-form-label>First Name*</nz-form-label>
                                <nz-form-control nzErrorTip="Please input First Name!">
                                    <input nz-input nzSize="large" placeholder="First Name"
                                        formControlName="first_name" />
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-label>Last Name*</nz-form-label>
                                <nz-form-control nzErrorTip="Please input your Last Name!">
                                    <input nz-input nzSize="large" placeholder="Last Name`"
                                        formControlName="last_name" />
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-label>
                                    <span>Phone Number</span>
                                    <span class="font-xs">&nbsp;&nbsp;(+xxxxxxxxxxxxxxx)</span>
                                </nz-form-label>
                                <nz-form-control
                                    nzErrorTip="Please enter a valid phone number type the + sign, then the country code followed by the local number!">
                                    <input nz-input nzSize="large" placeholder="Phone Number"
                                        formControlName="phone_number" />
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item>
                                <nz-form-label>Email*</nz-form-label>
                                <nz-form-control nzErrorTip="Please input your Email!">
                                    <input nz-input nzSize="large" placeholder="email" formControlName="email" />
                                </nz-form-control>
                            </nz-form-item>

                        </ng-container>

                        <div class="custom-footer">
                            <button nz-button nzBlock nzSize="large" [nzType]="'primary'" class="mb-10"
                                nzShape="round"><span *ngIf="this.userMgr.isAddingUser" nz-icon nzType="loading"
                                    nzTheme="outline"></span> Save</button>
                            <button nz-button nzType="link" nzBlock
                                (click)="handleProfileCancel($event)">Cancel</button>
                        </div>
                    </form>
                </div>
            </ng-container>

        </nz-modal>
        <nz-modal [(nzVisible)]="userMgr.isChangePasswordModal" (nzOnCancel)="handleChangePasswordCancel($event)"
            [nzFooter]="null" nzWidth="400px" [nzMaskClosable]="false">
            <ng-container *nzModalContent>
                <div class="center-form">
                    <div class="form-header">
                        <h2>Update Password</h2>
                    </div>
                    <form [formGroup]="formEditPassword" nz-form nzNoColon nzLayout="vertical"
                        (ngSubmit)="changePassword()">
                        <ng-container>
                            <!-- Company Information Form -->
                            <nz-form-item>
                                <label for="currentPassword">Current Password*</label>
                                <nz-form-control nzErrorTip="Please input your Password!">
                                    <nz-input-group [nzSuffix]="suffixCurrentPassword" nzSize="large">
                                        <input [type]="currentPasswordVisible ? 'text' : 'password'" nzSize="large"
                                            nz-input formControlName="current_password" placeholder="Current Password"
                                            id="currentPassword" />
                                    </nz-input-group>
                                    <ng-template #suffixCurrentPassword>
                                        <span nz-icon [nzType]="currentPasswordVisible ? 'eye' : 'eye-invisible'"
                                            (click)="currentPasswordVisible = !currentPasswordVisible"></span>
                                    </ng-template>
                                </nz-form-control>
                            </nz-form-item>

                            <nz-form-item>
                                <label for="newPassword">New Password*</label>
                                <nz-form-control
                                    nzErrorTip="Password must be at least 8 characters and at least 1 special character!">
                                    <nz-input-group [nzSuffix]="suffixNewPassword" nzSize="large">
                                        <input [type]="newPasswordVisible ? 'text' : 'password'" nzSize="large" nz-input
                                            formControlName="password" placeholder="Password" id="newPassword" />
                                    </nz-input-group>
                                    <ng-template #suffixNewPassword>
                                        <span nz-icon [nzType]="newPasswordVisible ? 'eye' : 'eye-invisible'"
                                            (click)="newPasswordVisible = !newPasswordVisible"></span>
                                    </ng-template>
                                </nz-form-control>
                            </nz-form-item>


                        </ng-container>
                        <div class="custom-footer">
                            <button nz-button nzBlock nzSize="large" [nzType]="'primary'" nzShape="round"
                                class="mb-10"><span *ngIf="this.userMgr.isAddingUser" nz-icon nzType="loading"
                                    nzTheme="outline"></span>Change
                                Password</button>
                            <button nz-button nzType="link" nzBlock (click)="handleChangePasswordCancel($event)"
                                nzShape="round">Cancel</button>
                        </div>
                    </form>
                </div>

            </ng-container>

        </nz-modal>
        <nz-modal [(nzVisible)]="isVisibleRemoveAccountModal" (nzOnCancel)="removeAccountCancel()" [nzFooter]="null"
            nzWidth="350px" [nzMaskClosable]="false">
            <ng-container *nzModalContent>
                <div class="center-form">
                    <div class="form-header">
                        <h2>Remove Account</h2>
                    </div>
                    <form [formGroup]="formEditPassword" nz-form nzNoColon nzLayout="vertical">
                        <ng-container>
                            <div class="custom-alert">
                                <nz-alert class="" nzType="warning" nzMessage="You are removing your account!"
                                    nzDescription="This action cannot be undone. Are you sure you want to remove your account?"
                                    nzShowIcon></nz-alert>
                            </div>

                        </ng-container>
                        <div class="custom-footer">
                            <button nz-button nzBlock nzSize="large" [nzType]="'primary'" nzShape="round" class="mb-10"
                                nzDanger (click)="removeMyAccount()"><span *ngIf="this.userMgr.isAddingUser" nz-icon
                                    nzType="loading" nzTheme="outline"></span> Remove My Account</button>
                            <button nz-button nzType="text" nzSize="large" nzBlock (click)="removeAccountCancel()"
                                nzShape="round">Cancel</button>
                        </div>
                    </form>
                </div>

            </ng-container>

        </nz-modal>

    </main>
</div>