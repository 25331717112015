import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FollowUpQuestionsManagerService } from 'src/app/Managers/follow-up-questions-manager.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
@Component({
  selector: 'app-follow-up-questions',
  templateUrl: './follow-up-questions.component.html',
  styleUrl: './follow-up-questions.component.scss',
})
export class FollowUpQuestionsComponent {
  validateForm!: UntypedFormGroup;
  formGroup!: FormGroup;
  selectedOptions: { [key: string]: string[] } = {};
  applicationId: string = '';
  age: string = '';
  token: string = '';
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  currentStep = 0;
  user: any;
  isVisbileMaterial: boolean = false;
  isSkipAdditional: boolean = false;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public followUpMgr: FollowUpQuestionsManagerService,
    private notification: NzNotificationService
  ) {}
  replaceEscapeSequences(html: any): any {
    if (html == null) {
      return '';
    }
    return html
      .replace(/\\n/g, '<br />') // Replace newlines with <br>
      .replace(/\n/g, '<br />') // Replace literal newlines with <br>
      .replace(/\\f/g, '') // Remove form feed characters
      .replace(/\f/g, '') // Remove literal form feed
      .replace(/\\t/g, '&emsp;') // Replace tab with spaces
      .replace(/\t/g, '&emsp;') // Replace literal tab with spaces
      .replace(/\\u00a0/g, '&nbsp;') // Non-breaking space
      .replace(/\\u0026/g, '&amp;') // Ampersand
      .replace(/\*\*(.+?)\*\*/g, '<b>$1</b>') // Bold for text between **
      .replace(/[^\x00-\x7F]/g, ''); // Remove non-ASCII characters
  }

  openQuestionsModal(data: any): void {
    this.followUpMgr.isVisibleQuestionsModal = true;
    this.initForm(data);
  }
  handleCancel(): void {
    this.followUpMgr.isVisibleQuestionsModal = false;
  }
  cancelSuccessModal(): void {
    this.followUpMgr.successModal = false;
  }
  cancelMaterialModal(): void {
    this.isVisbileMaterial = false;
  }
  visibleMaterialModal(): void {
    this.isVisbileMaterial = true;
  }
  handleCancelSubmitModal(): void {
    this.followUpMgr.isVisbileConfirmSubmit = false;
  }
  joinDein(): void {
    this.router.navigate(['/create-account'], {
      queryParams: {
        token: this.token,
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
      },
    });
  }
  backToHome(): void {
    this.router.navigate(['/']);
  }
  goToStep(index: number): void {
    this.currentStep = index;
  }

  prevStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }
  skipAdditional(): void {
    if (this.currentStep < this.followUpMgr.questions.length + 1) {
      this.currentStep++;
      this.isSkipAdditional = true;
    }
  }
  nextStep(): void {
    if (this.currentStep < this.followUpMgr.questions.length + 1) {
      if (this.formGroup.invalid) {
        this.notification.create(
          'error',
          'Error',
          'Please limit the text to 1000 characters.'
        );
      } else {
        this.currentStep++;
        //this.initForm(this.followUpMgr.questions);
      }

      // this.initForm(this.followUpMgr.questions);
    } else {
      this.followUpMgr.isVisbileConfirmSubmit = true;
    }
  }
  initForm(data: any): void {
    const formControls: any = {};
    data.forEach((category: any) => {
      category.questions.forEach((question: any) => {
        const controlName = question.config?.is_user_entered
          ? 'custom_' + question._id
          : 'question_' + question._id;
        const answer = question.answer || [];

        // Set up form controls based on question type
        switch (question.type) {
          case 'free_text':
          case 'short':
          case 'long':
            formControls[controlName] = new FormControl(
              answer[0],
              Validators.maxLength(1000)
            );
            break;
          case 'multi_options':
            if (question.config.is_multi_select) {
              // Create a FormArray for multi-select options
              formControls['question_' + question._id] = this.fb.array(
                question.options.map(
                  (option: { number: any }) =>
                    new FormControl(
                      answer.includes(option.number) // Check if option is in the answer array
                    )
                )
              );

              // Add a control for user-entered text if applicable
              if (question.config.is_user_entered) {
                formControls['custom_' + question._id] = new FormControl(
                  question.custom || ''
                );
              }
            } else {
              // Existing single select handling...
              formControls['question_' + question._id] = new FormControl(
                answer[0]
              );
              if (question.config.is_user_entered) {
                formControls['custom_' + question._id] = new FormControl(
                  question.custom || ''
                );
              }
            }

            break;

          case 'scale':
            formControls[controlName] = new FormControl(
              answer[0],
              Validators.maxLength(1000)
            );
            break;

          case 'yes_no':
            formControls[controlName] = new FormControl(answer[0] || false);
            break;

          default:
            formControls[controlName] = new FormControl('');
        }
      });
    });

    // Handle additional insights if available
    if (this.followUpMgr.additional_insights?.length > 0) {
      for (const insight of this.followUpMgr.additional_insights) {
        const controlName = 'question_' + insight._id;
        formControls[controlName] = new FormControl(
          insight.answers ? insight.answers[0] : null
        );
      }
    }

    // Finally, create the form group
    this.formGroup = this.fb.group(formControls);
  }

  getSelectedOption(question: any): string | null {
    const selectedNumber = this.formGroup.get(
      'question_' + question._id
    )?.value;
    const selectedOption = question.options.find(
      (option: { number: any }) => option.number === selectedNumber
    );
    return selectedOption ? selectedOption.option : null;
  }
  getSelectedAnswers(question: any): string | null {
    const formArray = this.formGroup.get(
      'question_' + question._id
    ) as FormArray;

    if (!formArray) {
      return null; // FormArray not found
    }

    // Map over the options and get the option text if selected
    const selectedOptions = question.options
      .map((option: { option: any }, index: number) =>
        formArray.at(index).value ? option.option : null
      )
      .filter((option: null) => option !== null); // Filter out null values

    return selectedOptions.length > 0 ? selectedOptions.join(', ') : null;
  }
  otherRadio(question: any, value: any): string | null {
    if (!question || !question.answer || !question.options) {
      return null; // Ensure question, answer, and options exist
    }

    // Find the option that matches the provided value
    const foundOption = question.options.find(
      (option: { number: string }) => option.number === value
    );

    // If the option is found, return the corresponding option text
    return foundOption ? foundOption.option : null;
  }

  getUserEnteredAnswer(question: any): string {
    const customAnswer = this.formGroup.get('custom_' + question._id)?.value;
    return customAnswer ? customAnswer : '(No other answer provided!)';
  }
  geGeneralAnswer(question: any): string {
    const answer = this.formGroup.get('question_' + question._id)?.value;
    const isSingleCharacter = /^[a-zA-Z]$/.test(answer); // Regex for a single alphabetic character only
    if (isSingleCharacter) {
      return '';
    }
    return answer ? answer : '';
  }

  submitAnswers(): void {
    const payload = {
      answers: this.getAnswers(),
      additional_insights: this.isSkipAdditional
        ? []
        : this.getAdditionalInsights(),
    };
    console.log('Payload to submit:', payload);
    // Now submit the payload to your service or handle it as needed
    this.followUpMgr.submitFollowUpQuestions(payload, this.applicationId);
  }
  getAnswers(): Array<{
    question_obj_id: string;
    answer: any; // This can be an array or any other type
    custom?: string; // Optional custom field
  }> {
    return this.followUpMgr.questions
      .flatMap((item) =>
        item.questions.map((question) => {
          let answer: any[] = []; // Initialize answer as an empty array
          const custom = this.formGroup.get('custom_' + question._id)?.value;

          if (
            question.type === 'multi_options' &&
            question.config?.is_multi_select
          ) {
            // Retrieve selected options from FormArray
            const formArray = this.formGroup.get(
              'question_' + question._id
            ) as FormArray;
            answer = question.options
              .filter((_, index) => formArray.at(index).value) // Get only selected options
              .map((option) => option.number);
            if (custom && String(custom).trim() !== '') {
              answer.push('custom');
            }
          } else {
            const singleAnswer = this.formGroup.get(
              'question_' + question._id
            )?.value;
            if (singleAnswer) {
              answer.push(singleAnswer); // Add to answer array
              console.log('let check the answer', singleAnswer);
            }
            if (custom && String(custom).trim() !== '') {
              answer.push('custom');
            }
          }

          // Only return if there are valid answers or custom data
          if (answer.length > 0 || (custom && String(custom).trim() !== '')) {
            const result: {
              question_obj_id: string;
              answer: any;
              custom?: string;
            } = {
              question_obj_id: question._id,
              answer: answer, // Keep it as an array
            };

            // Add custom only if it exists and is not empty
            if (custom && String(custom).trim() !== '') {
              result.custom = custom;
            }

            return result;
          }

          return null; // Return null for empty answers
        })
      )
      .filter(
        (
          answer
        ): answer is {
          question_obj_id: string;
          answer: any;
          custom?: string;
        } => answer !== null
      ); // Type guard to filter out null values
  }

  getAdditionalInsights(): Array<{ question_id: string; answers: string[] }> {
    if (
      !this.followUpMgr.additional_insights ||
      this.followUpMgr.additional_insights.length === 0
    ) {
      return [];
    }

    return this.followUpMgr.additional_insights
      .map((insight) => {
        const answer = this.formGroup.get('question_' + insight._id)?.value;
        if (answer && answer.trim() !== '') {
          return {
            question_id: insight._id,
            answers: [answer],
          };
        }
        return null;
      })
      .filter(
        (insight): insight is { question_id: string; answers: string[] } =>
          insight !== null
      );
  }
  cancelFollowUpQuestion(): void {
    this.followUpMgr.isVisibleQuestionsModal = false;
  }

  sumbitPayload(data: any): void {
    let payload: any = {
      answers: [],
    };
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const id = parseInt(key.split('_')[1]);
        payload.answers.push({ id: id, answer: data[key] });
      }
    }
    return payload;
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    this.activatedRoute.queryParams.subscribe(async (params) => {
      this.token = params['token'];
      this.firstName = params['first_name'];
      this.lastName = params['last_name'];
      this.email = params['email'];
      this.applicationId = params['application_id'];
      this.age = params['age'];
      await this.followUpMgr.getFollowUpQuestions(params['application_id']);
    });
  }
}
