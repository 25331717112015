import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router'; // Import the Router module
import { PermissionService } from 'src/app/Services/permission.service';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    public auth: AuthenticationService,
    private permissionService: PermissionService,
    private notification: NzNotificationService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Get the csrftoken from the cookie or wherever it is stored
    const csrftoken = document.cookie
      .split('; ')
      .find((row) => row.startsWith('csrftoken'))
      ?.split('=')[1];

    // Clone the request and add the csrftoken to the headers
    const clonedRequest = request.clone({
      headers: request.headers.set('X-Csrftoken', csrftoken || ''),
    });

    // Pass the cloned request to the next handler
    return next.handle(clonedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        // Check for specific status codes and handle accordingly
        if (error.status === 401) {
          // Handle unauthorized access, redirect to login page

          // this.removeCookie('csrftoken');
          // this.removeCookie('user');
          this.permissionService.clearPermissionsOnLogout();
          this.auth.logout();
        } else if (error.status === 403) {
          // Redirect to the '/not-authorized' route for forbidden access
          // this.router.navigate(['/not-authorized']);
        } else if (error.status === 500) {
          this.notification.create(
            'error',
            'Error',
            'Server has encounter unexpected error!'
          );
        } else {
          // Handle other errors or log them as needed
        }

        // Pass the error along to the calling code
        return throwError(error);
      })
    );
  }
}
