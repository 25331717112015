import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SjmManagerService } from 'src/app/Managers/sjm-manager.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-upload-candidates',
  templateUrl: './upload-candidates.component.html',
  styleUrl: './upload-candidates.component.scss',
})
export class UploadCandidatesComponent {
  currentStep: number = 1;
  totalSteps: number = 4;
  selectedFiles: File[] = [];
  resumeProcessStatuses: boolean[] = [];
  jobObjectId: string = '';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public sjmMgr: SjmManagerService,
    private notification: NzNotificationService
  ) {}
  getStepClass(step: number): string {
    if (step < this.currentStep) {
      return 'done';
    } else if (step === this.currentStep) {
      return 'active';
    } else {
      return 'inactive';
    }
  }
  nextStep(): void {
    if (this.currentStep < this.totalSteps) {
      this.currentStep++;
    } else {
      //this.submitForm();
    }
  }

  previousStep(): void {
    if (this.currentStep > 1) {
      this.currentStep--;
      this.selectedFiles = [];
    } else {
      this.router.navigate(['jobs/candidates/', this.jobObjectId]);
    }
  }
  onFilesSelected(files: any): void {
    this.selectedFiles = files;
    this.sjmMgr.resumeFinilizeData = files;
    this.resumeProcessStatuses = files.map(() => true);
  }
  async uploadResums(): Promise<void> {
    this.currentStep++;
  }
  async submitFinalization() {
    const payload = this.sjmMgr.resumeFinilizeData.map(
      (file: any, index: number) => ({
        file,
        process_status: this.resumeProcessStatuses[index],
      })
    );
    await this.sjmMgr.uploadResumes(payload, this.jobObjectId);
    this.notification.create(
      'success',
      'Candidates uploaded and processing',
      ''
    );
    const processing = 'processing';

    this.router.navigate(['jobs/candidates/', this.jobObjectId], {
      queryParams: { processing },
    });
  }

  async ngOnInit() {
    this.route.params.subscribe(async (params) => {
      this.jobObjectId = params['jobObjectId'];
    });
  }
  ngOnDestroy() {
    this.sjmMgr.resumeFinilizeData = [];
  }
}
