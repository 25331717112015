<div>
    <div class="gray-text-box">
        <h4 class="font-heading">Best Practices</h4>
        <ol>
            <li><strong>Limit to 2-3 key questions:</strong> Focus on essential aspects
                of the job to streamline the candidate’s response time.</li>
            <li><strong>Target core competencies:</strong> Craft questions that directly assess skills,
                experience, and qualities crucial for success,
                aligning with job requirements and company culture.</li>
            <li><strong>Simplify question types:</strong> Use scales (1-5), percentage
                splits, or options like “not at all” to “very likely” for quicker
                responses and more consistent comparisons. Open-ended questions can
                provide deeper insights but take longer to answer.</li>


        </ol>
        <ng-container *ngIf="isExpanded">
            <p>Some successful sample questions from other partners include:</p>
            <ul>
                <li>
                    When you consider sets of complementary traits, we are all a unique
                    balance. For example, when thinking about being
                    operational and strategic you might be 60% operational + 40%
                    strategic, or maybe 75% strategic + 25% operational.How
                    would you describe yourself when it comes to each of the following
                    three complementary sets of traits? (Be sure that
                    they add up to 100).
                    <ul>
                        <li>strategic and operational</li>
                        <li>consistent and flexible</li>
                        <li>structured and dynamic</li>
                    </ul>
                </li>
                <li>On a scale of 1 - 10, with 1 being not at all familiar and 10 being
                    extremely familiar, how would you rate your
                    familiarity with Google Workspace tools?</li>
                <li>Think of an instance from your professional or personal life where
                    you successfully mediated between two team people
                    who
                    were experiencing a challenge in getting along. Please briefly share
                    1-2 techniques you used to alleviate the
                    situation.</li>
                <li>On a scale of 1 - 10, with 1 being not at all effective and 10 being
                    extremely effective, how would your last
                    supervisor
                    rate you on your ability to work with people across the range of
                    experience from entry-level to senior management?</li>
            </ul>
        </ng-container>
        <div class="button-container">
            <button nz-button nzType="text" class="btn-dark" (click)="toggleReadMore()">{{
                isExpanded ?
                'Read Less' :
                'Read More' }}</button>
        </div>
    </div>


    <form [formGroup]="form" class="flex-modal" nzLayout="'vertical'">
        <p>Add custom questions to ask Candidates.</p>
        <div *ngIf="!showEditable" class="button-container mb-24">
            <button (click)="toggleEditable()" nz-button nzType="default" nzSize="large" class="btn-gray"
                nzShape="round"><span nz-icon nzType="plus" nzTheme="outline"></span>Add New Follow-Up
                Question</button>
        </div>

        <div class="editable-wrap" *ngIf="showEditable" formArrayName="followUpQuestions">
            <div class="editable-item" *ngFor="let question of followUpQuestions.controls; let i = index"
                [formGroupName]="i">
                <div class="editable-header">
                    <div class="btn-group md">
                        <button (click)="selectType('free_text', i)"
                            [class.active]="question.get('type')?.value === 'free_text'">
                            Free Text
                        </button>
                        <button (click)="selectType('yes_no', i)"
                            [class.active]="question.get('type')?.value === 'yes_no'">
                            Yes / No
                        </button>
                        <button (click)="selectType('multi_options', i)"
                            [class.active]="question.get('type')?.value === 'multi_options'">
                            Multi-options
                        </button>
                        <button (click)="selectType('scale', i)"
                            [class.active]="question.get('type')?.value === 'scale'">
                            Scale
                        </button>
                    </div>
                </div>
                <div class="editable-body">
                    <div nz-row [nzGutter]="16">
                        <div nz-col [nzSpan]="18">
                            <label>Add custom question</label>
                            <nz-form-control class="no-padding">
                                <textarea formControlName="question" nz-input rows="1" nzSize="large"
                                    placeholder="Enter question"></textarea>
                            </nz-form-control>
                            <!-- If Multi-options button click -->
                            <div class="multi-select" *ngIf="question.get('type')?.value === 'multi_options'">
                                <p>Enter the multi-options:</p>
                                <div formArrayName="options">
                                    <div class="multi-select-item"
                                        *ngFor="let option of getOptionsArray(i).controls; let j = index"
                                        [formGroupName]="j">
                                        <div class="numbering">{{j + 1}}</div>
                                        <nz-form-control class="no-padding">
                                            <textarea nz-input rows="1" nzSize="large"
                                                formControlName="option"></textarea>
                                        </nz-form-control>
                                        <div class="delete-option" (click)="removeOption(i, j)">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 8.005h16v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-13zm2 2v10h12v-10H6zm3 2h2v6H9v-6zm4 0h2v6h-2v-6zm-6-7v-2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v2h5v2H2v-2h5zm2-1v1h6v-1H9z"
                                                    fill="#52525B" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="options-checks" formGroupName="config">
                                    <div>
                                        <label nz-checkbox formControlName="is_multi_select">Enable
                                            multi-select</label>
                                    </div>
                                    <div>
                                        <label nz-checkbox formControlName="is_user_entered">Include the
                                            option of a
                                            user-entered answer</label>
                                    </div>
                                </div>
                                <div class="add-option-wrap">
                                    <button class="add-option" (click)="addOption(i)">Add
                                        Options</button>
                                </div>
                            </div>
                        </div>
                        <div nz-col [nzSpan]="6">
                            <label>Select category</label>
                            <nz-form-control class="no-padding">
                                <nz-select [nzSuffixIcon]="customSuffixIcon" nzPlaceHolder="Select" nzSize="large"
                                    formControlName="category">
                                    <nz-option nzLabel="Skills" nzValue="skills"></nz-option>
                                    <nz-option nzLabel="Education" nzValue="education"></nz-option>
                                    <nz-option nzLabel="Experience" nzValue="experience"></nz-option>
                                    <nz-option nzLabel="Other" nzValue="general"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </div>
                    </div>
                    <div class="editable-footer">
                        <div *ngIf="question.get('type')?.value === 'free_text'">The
                            candidate
                            will be provided
                            an open text box to
                            provide their answer.</div>
                        <div *ngIf="question.get('type')?.value === 'yes_no'">The candidate
                            will be provided
                            with Yes, No
                            options to answer this question.</div>
                        <div *ngIf="question.get('type')?.value === 'scale'">The candidate
                            will be provided
                            with a scale of 1
                            - 5 to answer this question.</div>
                        <div *ngIf="question.get('type')?.value === 'multi_options'"></div>
                        <button class="remove-button" (click)="removeFollowUpQuestion(i)">
                            Delete
                        </button>
                    </div>
                </div>

            </div>
            <div class="add-button">
                <button (click)="addFollowUpQuestion()" nz-button nzType="default" nzSize="large" class="btn-gray"
                    nzShape="round"><span nz-icon nzType="plus" nzTheme="outline"></span>Add New Follow-Up
                    Question</button>
            </div>
        </div>

    </form>
    <ng-template #customSuffixIcon>
        <span nz-icon nzType="caret-down" nzTheme="outline"></span>
    </ng-template>
</div>