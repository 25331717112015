import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class JobsService {
  constructor(private http: HttpClient) {}
  getActiveJobs(
    page: number,
    partnerID: string,
    search: string,
    status: string
  ) {
    const header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    const queryParams = `partner_id=${partnerID}&search=${search}&status=${status}`;
    const pageParam = partnerID === '' && search === '' ? `page=${page}&` : '';

    const url = `${environment.apiURL}deincore/partner/jobs/?${pageParam}${queryParams}`;

    return this.http.get(url, { headers: header });
  }
  getActiveJobsHome(page: number) {
    const header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    const url = `${environment.apiURL}deincore/partner/jobs/?page=${page}&page_size=3&status=open`;
    return this.http.get(url, { headers: header });
  }

  getClosedJobs(
    page: number,
    partnerID: string,
    search: string,
    status: string
  ) {
    const header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });

    const queryParams = `partner_id=${partnerID}&search=${search}&status=${status}`;
    const pageParam = partnerID === '' && search === '' ? `page=${page}&` : '';

    const url = `${environment.apiURL}deincore/partner/jobs/?${pageParam}${queryParams}`;

    return this.http.get(url, { headers: header });
  }

  getImportJobs(status: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `deincore/partner/jobs/import/?status=${status}`,
      {
        headers: header,
      }
    );
  }
  getImportJobsATS(id: number, status: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL +
        `deincore/partner/jobs/import/?integration_id=${id}&status=${status}`,
      {
        headers: header,
      }
    );
  }
  getApplicatonStatus(id: number) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `deincore/ats/integration/${id}/application-status`,
      {
        headers: header,
      }
    );
  }
  submitApplicatonStatus(id: number, formData: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.put(
      environment.apiURL + `deincore/ats/integration/${id}/application-status`,
      formData,
      {
        headers: header,
      }
    );
  }

  importJobs(jobs: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL + 'deincore/partner/jobs/import/',
      jobs,
      {
        headers: header,
      }
    );
  }
  getFollowUpFeature() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `/settings/partner/followup-questions-feature`,
      {
        headers: header,
      }
    );
  }
  updatedFollowUpFeature(data: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.put(
      environment.apiURL + `/settings/partner/followup-questions-feature`,
      data,
      {
        headers: header,
      }
    );
  }
  candidatesProcessing(id: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL + `deincore/partner/job/${id}/job-processing/`,
      {
        headers: header,
      }
    );
  }
  checkUserJobRequest(id: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `deincore/partner/job/${id}/job-request-access/`,
      {
        headers: header,
      }
    );
  }
  requestUserJobRefinementAccess(id: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.apiURL + `deincore/partner/job/${id}/job-request-access/`,
      {
        headers: header,
      }
    );
  }
  getJobRequirmentListing(id: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `deincore/partner/job/${id}/job-requirements/`,
      {
        headers: header,
      }
    );
  }
  updateJobRequirmentFeilds(id: string, data: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.put(
      environment.apiURL + `deincore/partner/job/${id}/job-requirements/`,
      data,
      {
        headers: header,
      }
    );
  }
  getPartnerJobFlow() {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(environment.apiURL + `settings/partner/job-flow`, {
      headers: header,
    });
  }

  updatePartnerJobFlow(data: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.put(
      environment.apiURL + `settings/partner/job-flow`,
      data,
      {
        headers: header,
      }
    );
  }

  getJobFlow(id: string) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.get(
      environment.apiURL + `deincore/partner/jobs/standalone/job-flow/${id}`,
      {
        headers: header,
      }
    );
  }
  updateJobFlow(id: string, data: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.put(
      environment.apiURL + `deincore/partner/jobs/standalone/job-flow/${id}`,
      data,
      {
        headers: header,
      }
    );
  }
  changeJobStage(id: string, data: any) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.patch(
      environment.apiURL +
        `deincore/partner/jobs/standalone/edit-job-stage/${id}`,
      data,
      {
        headers: header,
      }
    );
  }
}
