<div class="page">
    <!-- main -->
    <main class="main">

        <div class="heading">
            <a [routerLink]="['/apply-job']" [queryParams]="{ job_obj_id: jobObjectId }">
                <span nz-icon class="heading-icon" nzType="left" nzTheme="outline"></span>
            </a>

            <h1 class="font-heading">My Application</h1>
        </div>
        <div nz-row [nzGutter]="16">
            <div nz-col [nzSpan]="18">
                <div class="white-box">
                    <div class="white-box-header">
                        <div class="job-steps">
                            <div class="steps-item" [ngClass]="getStepClass(1)">
                                <div class="step-dot">
                                    <div class="white-dot"></div><span class="checkmark" nz-icon nzType="check"></span>
                                </div>
                                <span class="step-title">Details</span>
                            </div>
                            <div class="steps-item" [ngClass]="getStepClass(2)">
                                <div class="step-dot">
                                    <div class="white-dot"></div><span class="checkmark" nz-icon nzType="check"></span>
                                </div>
                                <span class="step-title">Upload</span>
                            </div>
                            <div class="steps-item" [ngClass]="getStepClass(3)">
                                <div class="step-dot">
                                    <div class="white-dot"></div><span class="checkmark" nz-icon nzType="check"></span>
                                </div>
                                <span class="step-title">Links</span>
                            </div>

                        </div>

                    </div>
                    <div class="white-box-body">
                        <ng-container *ngIf="currentStep === 1">
                            <div class="alert-email" *ngIf="sjmMgr.isEmail">
                                <div class="box-icon">
                                    <span nz-icon nzType="info-circle" nzTheme="outline"></span>
                                </div>
                                <div class="alert-text">You can submit up to 1 application per email.</div>
                            </div>
                            <form [formGroup]="detailForm" class="clara-inline-form">
                                <div nz-row [nzGutter]="16">
                                    <div nz-col [nzSpan]="8">
                                        <nz-form-item>
                                            <label nzFor="first_name">First Name*</label>
                                            <nz-form-control>
                                                <input nz-input nzSize="large" id="first_name"
                                                    formControlName="first_name" />
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div nz-col [nzSpan]="8">
                                        <nz-form-item>
                                            <label nzFor="last_name">Last Name*</label>
                                            <nz-form-control>
                                                <input nz-input nzSize="large" id="last_name"
                                                    formControlName="last_name" />
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div nz-col [nzSpan]="8">
                                        <nz-form-item>
                                            <label nzFor="email">Email*</label>
                                            <nz-form-control>
                                                <input nz-input nzSize="large" id="email" formControlName="email" />
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </form>
                        </ng-container>
                        <ng-container *ngIf="currentStep === 2">
                            <div class="border-box">
                                <div class="border-box-alert ">
                                    <div class="d-flex">
                                        <div class="box-icon mr-16">
                                            <span nz-icon nzType="info-circle" nzTheme="outline"></span>
                                        </div>
                                        <div class="d-flex">
                                            <b>You are required to provide the following: <small>Formats supported: PDF,
                                                    Word or Pages</small></b>
                                            <ul>
                                                <li>Resume</li>
                                                <ng-container
                                                    *ngIf="sjmMgr.jobInfo?.job_materials_and_links?.materials">
                                                    <ng-container
                                                        *ngFor="let item of sjmMgr.jobInfo.job_materials_and_links.materials">
                                                        <li *ngIf="item.is_display">
                                                            {{ item.material_name | titlecase }}
                                                        </li>
                                                    </ng-container>
                                                </ng-container>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div nz-row [nzGutter]="16" class="clara-inline-form">
                                <div nz-col [nzSpan]="12">
                                    <nz-form-item>
                                        <label nzFor="firstName">Resume <span class="color-red">*</span></label>
                                        <nz-form-control>
                                            <div class="upload-container" appDnd (fileDropped)="onFileDropped($event)"
                                                *ngIf="!selectedResumeFile">
                                                <input nz-input type="file" nzSize="large"
                                                    accept=".pdf, .doc, .docx, .pages"
                                                    (change)="changeResumeFile($event)" />
                                                <div class="button-icon">
                                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.5 22.167h21V24.5h-21v-2.333zM15.167 6.799v13.034h-2.334V6.8L5.75 13.883l-1.65-1.65 9.9-9.9 9.9 9.9-1.65 1.65-7.083-7.081v-.003z"
                                                            fill="#1C1A20" fill-opacity=".7" />
                                                    </svg>
                                                </div>
                                                <h3>Click to upload Resume file <span> or drag and drop <br> PDF, Word
                                                        or
                                                        Pages</span>
                                                </h3>
                                            </div>
                                            <div class="preview-file" *ngIf="selectedResumeFile">
                                                <div class="d-flex align-middle">
                                                    <span class="checkmark-green" nz-icon nzType="check"></span>
                                                    <span class="file-name">{{ selectedResumeFile.name }}</span>
                                                </div>
                                                <span class="cancel-file" nz-icon nzType="close" nzTheme="outline"
                                                    (click)="selectedResumeFile = null"></span>
                                            </div>

                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <ng-container *ngIf="sjmMgr.jobInfo?.job_materials_and_links?.materials">
                                    <ng-container
                                        *ngFor="let material of sjmMgr.jobInfo.job_materials_and_links.materials">
                                        <div nz-col [nzSpan]="12" *ngIf="material.is_display">
                                            <div>
                                                <nz-form-item>
                                                    <label>
                                                        {{ material.material_name | titlecase }}
                                                        <span *ngIf="material.is_mandatory" class="color-red">*</span>
                                                    </label>
                                                    <nz-form-control>
                                                        <!-- File Upload Container -->
                                                        <div *ngIf="!getMaterialFile(material)" class="upload-container"
                                                            appDnd
                                                            (fileDropped)="onFileDroppedForMaterial($event, material)">
                                                            <input nz-input type="file" nzSize="large"
                                                                accept=".pdf, .doc, .docx, .pages"
                                                                (change)="changeFile($event, material)" #fileDropRef />
                                                            <div class="button-icon" (click)="fileDropRef.click()">
                                                                <svg width="28" height="28" viewBox="0 0 28 28"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M3.5 22.167h21V24.5h-21v-2.333zM15.167 6.799v13.034h-2.334V6.8L5.75 13.883l-1.65-1.65 9.9-9.9 9.9 9.9-1.65 1.65-7.083-7.081v-.003z"
                                                                        fill="#1C1A20" fill-opacity=".7" />
                                                                </svg>
                                                            </div>
                                                            <h3>Click to upload {{ material.material_name | titlecase }}
                                                                <span> or drag and drop <br> PDF, Word
                                                                    or Pages</span>
                                                            </h3>

                                                        </div>

                                                        <!-- Preview for Uploaded Material -->
                                                        <div *ngIf="getMaterialFile(material)" class="preview-file">
                                                            <div class="d-flex align-middle">
                                                                <span class="checkmark-green" nz-icon
                                                                    nzType="check"></span>
                                                                <span class="file-name">{{
                                                                    getMaterialFile(material)?.name }}</span>
                                                            </div>
                                                            <span class="cancel-file" nz-icon nzType="close"
                                                                nzTheme="outline"
                                                                (click)="removeMaterialFile(material)"></span>
                                                        </div>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                        </div>

                                    </ng-container>
                                </ng-container>

                            </div>
                        </ng-container>
                        <ng-container *ngIf="currentStep === 3">
                            <form [formGroup]="linksForm" class="clara-inline-form">
                                <ng-container *ngIf="hasVisibleLinks(); else noLinks">
                                    <p class="font-weight-medium">Please provide the links below:</p>
                                    <div nz-row [nzGutter]="16">
                                        <ng-container *ngFor="let link of sjmMgr.jobInfo.job_materials_and_links.links">
                                            <div nz-col [nzSpan]="12" *ngIf="link.is_display">
                                                <nz-form-item>
                                                    <label>
                                                        {{ link.link_name | titlecase }}
                                                        <span *ngIf="link.is_mandatory" class="color-red">*</span>
                                                    </label>
                                                    <nz-form-control
                                                        nzErrorTip="Please input a valid link e.g., www.example.com">
                                                        <input nz-input nzSize="large" [id]="link.link_name"
                                                            formControlName="{{link.link_name}}"
                                                            placeholder="e.g., www.example.com" />
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <ng-template #noLinks>
                                    <nz-alert nzType="info" nzMessage="Links are not required for this job. You can submit
                                        your application without
                                        providing any links." nzShowIcon></nz-alert>
                                </ng-template>
                            </form>
                        </ng-container>
                    </div>
                    <div class="white-box-footer">
                        <button (click)="previousStep()" *ngIf="currentStep === 2 || currentStep === 3" class="mr-16"
                            nz-button nzSize="large" [nzType]="'default'" nzShape="round">Back</button>

                        <button *ngIf="currentStep === 1" nz-button nzSize="large" [nzType]="'primary'" nzShape="round"
                            [disabled]="!detailForm.valid" (click)="onSubmitDetailForm()"><span
                                *ngIf="sjmMgr.validatingEmail" nz-icon nzType="loading" nzTheme="outline"></span>Next:
                            Upload</button>

                        <button *ngIf="currentStep === 2" nz-button
                            [disabled]="selectedResumeFile === null || isSubmitDisabled" nzSize="large"
                            [nzType]="'primary'" nzShape="round" (click)="nextLinks()">
                            Next: Links</button>
                        <button [disabled]="!linksForm.valid" *ngIf="currentStep === 3" nz-button nzSize="large"
                            [nzType]="'primary'" nzShape="round" (click)="submitApplications()">
                            <span *ngIf="sjmMgr.submitingApplications" nz-icon nzType="loading"
                                nzTheme="outline"></span>Submit my Application</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>