<div class="page">
    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">
        <nz-breadcrumb>
            <nz-breadcrumb-item>
                <a routerLink="/"><span nz-icon nzType="home"></span></a>
            </nz-breadcrumb-item>
            <nz-breadcrumb-item><a class="breadcrumb-menu-item"
                    (click)="toggleSubmenu()">Settings</a></nz-breadcrumb-item>
            <nz-breadcrumb-item>Candidate Sorting</nz-breadcrumb-item>
        </nz-breadcrumb>

        <div nz-row [nzGutter]="16" *ngIf="sortingMgr.sortingData">
            <div nz-col>
                <div class="heading-flex">
                    <h1>Candidate Sorting</h1>
                    <button nz-button nzType="primary" (click)="editProfileModal()">Edit</button>
                </div>
                <div class="sub-info-action">
                    <div>
                        <p>Customize the automatic sorting for jobs in CLARA.</p>
                        <p><strong>Please note:</strong> Making changes to these settings at the company
                            level
                            will impact any new jobs added to your organization.
                            If you wish to change the requirements for jobs already processed, please do so
                            at
                            the individual job level.</p>
                    </div>
                </div>
                <!-- <div class="heading-flex">
                        <p>Automatic sorting activation</p>
                        <nz-switch [ngModel]="switchValue" (ngModelChange)="automateSorging()"></nz-switch>
                    </div> -->
                <nz-spin [nzSpinning]="this.sortingMgr.isLoading">
                    <div class="description-list">
                        <div class="description-list-item">
                            <div class="description-list-term">If the Fit Score is between</div>
                            <div class="description-list-detail">
                                <nz-tag>{{sortingMgr.sortingData.not_qualified.min_score}}-{{sortingMgr.sortingData.not_qualified.max_score
                                    | number:'1.0-0'}}</nz-tag>
                            </div>
                        </div>
                        <div class="description-list-item">
                            <div class="description-list-term">Then move candidate into</div>
                            <div class="description-list-detail"><nz-tag>Not Qualified</nz-tag></div>
                        </div>
                        <div *ngIf="!sortingMgr.sortingData.not_qualified?.enabled" class="disable-description"></div>
                    </div>
                    <nz-divider nzText="And"></nz-divider>
                    <div class="description-list">
                        <div class="description-list-item">
                            <div class="description-list-term">If the Fit Score is between</div>
                            <div class="description-list-detail">
                                <nz-tag>{{sortingMgr.sortingData.pre_qualified.min_score
                                    }}-{{sortingMgr.sortingData.pre_qualified.max_score | number:'1.0-0'}}</nz-tag>

                            </div>
                        </div>
                        <div class="description-list-item">
                            <div class="description-list-term">Then move candidate into</div>
                            <div class="description-list-detail"><nz-tag>Pre Qualified</nz-tag></div>
                        </div>
                        <div *ngIf="!sortingMgr.sortingData.pre_qualified?.enabled" class="disable-description"></div>
                    </div>
                </nz-spin>

            </div>
        </div>

        <nz-modal [(nzVisible)]="sortingMgr.isVisibleProfileModal" nzTitle="Edit Candidate Sorting"
            (nzOnCancel)="handleProfileCancel()" [nzFooter]="null" nzWidth="600px" [nzMaskClosable]="false">
            <ng-container *nzModalContent>
                <div class="center-form">
                    <div class="border-box">
                        <p class="mb-30">Use the slider to select how to sort candidates based on the
                            CLARA Fit Score.</p>
                        <div class="flex-boxes">
                            <div class="small-box">
                                <h4>{{sortingMgr.updateSortingData.not_qualified.max_score | number:'1.0-0'}}</h4>
                                <p>Fit Score</p>
                            </div>

                        </div>
                        <div class="slider-area">
                            <nz-slider [(ngModel)]="sortingMgr.updateSortingData.not_qualified.max_score"
                                (ngModelChange)="onChangeScore($event)" [nzTipFormatter]="titleTemplate"></nz-slider>
                            <ng-template #titleTemplate let-value>
                                <span>{{ value | number:'1.0-0' }}</span>
                            </ng-template>
                        </div>
                        <div class="info-slider">
                            <p>Candidates with scores between 0-{{sortingMgr.updateSortingData.not_qualified.max_score |
                                number:'1.0-0'}}
                                will
                                be sorted into the Not Qualified
                                category.</p>
                            <p>Candidates with scores between
                                {{sortingMgr.updateSortingData.pre_qualified.min_score}}-100
                                will be sorted into the Pre-Qualified
                                category.</p>
                        </div>

                    </div>

                    <div class="custom-footer">
                        <button (click)="submitSorting()" nz-button nzBlock nzSize="large" [nzType]="'primary'"
                            class="mb-10" nzShape="round"><span *ngIf="this.sortingMgr.isUpdating" nz-icon
                                nzType="loading" nzTheme="outline"></span> Save
                            Changes</button>
                        <button nz-button nzType="link" nzBlock (click)="handleProfileCancel()"
                            nzShape="round">Cancel</button>
                    </div>

                </div>
            </ng-container>

        </nz-modal>
    </main>
</div>