import { Injectable } from '@angular/core';
import { CandidateSortingService } from '../Services/candidate-sorting.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { candidateSortingSettings } from '../models/models';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CandidateSortingManagerService {
  jobObjectId: string = '';
  percent = 0;
  public isLoading: boolean = false;
  public isVisibleProfileModal = false;
  public isUpdating: boolean = false;
  public isUpdatingPercent: boolean = false;

  sortingData!: candidateSortingSettings;
  updateSortingData!: candidateSortingSettings;

  sortingJobData!: candidateSortingSettings;
  updateSortingJobData!: candidateSortingSettings;

  constructor(
    public canidateSortingService: CandidateSortingService,
    private notification: NzNotificationService
  ) {}
  public getCandidatesSorting(): void {
    this.isLoading = true;
    this.canidateSortingService.getCandidatesSorting().subscribe({
      next: (c: any) => {
        this.sortingData = c.response;
        this.updateSortingData = c.response;
        this.isLoading = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoading = false;
      },
    });
  }
  public getCandidatesJobSorting(jobObjectId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.canidateSortingService
        .getCandidatesJobSorting(jobObjectId)
        .subscribe({
          next: (c: any) => {
            this.sortingJobData = c.response;
            this.updateSortingJobData = c.response;
            this.isLoading = false;
            resolve();
          },
          error: (error: any) => {
            if (error.status !== 500) {
              this.notification.create('error', 'Error', error.error.error);
            }
            this.isLoading = false;
            reject(error);
          },
        });
    });
  }
  public updateCandidatesSorting(data: any): void {
    this.isUpdating = true;
    this.canidateSortingService.updateCandidatesSorting(data).subscribe({
      next: (c: any) => {
        this.isUpdating = false;
        this.isVisibleProfileModal = false;
        this.getCandidatesSorting();
        this.notification.create(
          'success',
          'Candidate Sorting Updated',
          'The candidate sorting settings have been successfully updated!'
        );
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isUpdating = false;
      },
    });
  }
  public updateCandidatesJobSorting(data: any, jobObjectId: string): void {
    this.isUpdatingPercent = true;
    this.percent = 0;
    const interval$ = interval(50);
    interval$.pipe(take(10)).subscribe(() => {
      this.percent += 10;
    });
    this.canidateSortingService
      .updateCandidatesJobSorting(data, jobObjectId)
      .subscribe({
        next: (c: any) => {
          this.percent = 100;
          this.getCandidatesJobSorting(this.jobObjectId);
          this.notification.create(
            'success',
            'Sorting Criteria Updated Successfully!',
            'Your candidate list was successfully sorted.'
          );
        },
        error: (error: any) => {
          if (error.status !== 500) {
            this.notification.create('error', 'Error', error.error.error);
          }
          this.isUpdatingPercent = false;
        },
      });
  }
}
