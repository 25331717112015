import { Component, ViewEncapsulation } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { ScreeningSettingsManagerService } from 'src/app/Managers/screening-settings-manager.service';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  Heading,
  Link,
  Underline,
} from 'ckeditor5';

@Component({
  selector: 'app-company-basics',
  templateUrl: './company-basics.component.html',
  styleUrl: './company-basics.component.scss',
})
export class CompanyBasicsComponent {
  isEditableAboutUs: boolean = true;
  isVisibleAboutModal: boolean = false;
  aboutUsContent: string = '';
  updatedTextWithLinks: string = '';
  selectedLogoFile: any | null = null;
  logoFileName: string | null = null;
  bannerFileName: string | null = null;
  selectedBannerFile: any | null = null;
  private hexColorRegex: any = /^#([0-9A-F]{3}|[0-9A-F]{6})$/i;
  // Define fields as string | null to accommodate initial null values
  primary_containers: string | null = null;
  primary_text: string | null = null;
  secondary_color: string | null = null;

  // Define validation states for each color field
  validationState: Record<
    'primary_containers' | 'primary_text' | 'secondary_color',
    boolean | null
  > = {
    primary_containers: null,
    primary_text: null,
    secondary_color: null,
  };

  editorConfig = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ header: [1, 2, 3, false] }],
      ['link'],
    ],
  };

  public Editor = ClassicEditor;
  public config = {
    toolbar: [
      'heading', // For headings (H1, H2, H3, etc.)
      'bold', // For bold
      'italic', // For italic
      'link', // For links
      'underline', // For underline
    ],
    plugins: [
      Essentials, // Basic essentials plugins
      Bold, // Bold plugin
      Italic, // Italic plugin
      Link, // Link plugin
      Heading, // Heading plugin
      Underline,
    ],
  };
  constructor(
    private notification: NzNotificationService,
    public auth: AuthenticationService,
    public screeningMgr: ScreeningSettingsManagerService
  ) {}
  toggleSubmenu() {
    const bodyTag = document.body;
    bodyTag.classList.toggle('is-submenu');
    this.auth.isSubmenu = !this.auth.isSubmenu;
  }
  openAboutModal(): void {
    const contentText = this.aboutUsContent || '';

    try {
      const updatedContentText = contentText.replace(
        /href="(?!https?:\/\/)([^"]+)"/g,
        'href="https://$1"'
      );

      // Logging the updated content to verify changes

      this.updatedTextWithLinks = updatedContentText;

      this.selectedLogoFile =
        this.screeningMgr.companyBasicData.appearance.logo;
      this.selectedBannerFile =
        this.screeningMgr.companyBasicData.appearance.banner;
    } catch (error) {
      console.error('Error processing links in aboutUsContent:', error);
    }
    this.isVisibleAboutModal = true;
  }
  handleAboutCancel(): void {
    this.isVisibleAboutModal = false;
  }
  toggleAboutUs() {
    this.isEditableAboutUs = false;
  }
  async discardAboutUs() {
    await this.screeningMgr.getCompanyBasicSettings();

    const {
      about,
      appearance: {
        primary_containers,
        primary_text,
        secondary_color,
        logo,
        banner,
      },
    } = this.screeningMgr.companyBasicData;

    this.aboutUsContent = about;
    console.log('about', this.aboutUsContent);

    // Assign default values if null
    this.primary_containers = primary_containers ?? '#000000';
    this.primary_text = primary_text ?? '#000000';
    this.secondary_color = secondary_color ?? '#000000';

    this.selectedLogoFile = logo;
    this.selectedBannerFile = banner;

    this.isEditableAboutUs = true;
  }
  saveAboutUs() {
    // Ensure aboutUsContent is a valid string
    const contentText = this.aboutUsContent || '';

    try {
      // Ensure all links in contentText are absolute
      const absoluteContent = contentText.replace(
        /href="(?!https?:\/\/)([^"]*)"/g,
        'href="http://$1"'
      );

      let content = {
        about: absoluteContent,
      };

      this.screeningMgr.saveCompanyAboutUS(content);
    } catch (error) {
      console.error('Error processing links in aboutUsContent:', error);
    }
  }

  // Logo upload
  changeLogoFile(event: any) {
    const file = event?.target?.files ? event.target.files[0] : null;
    if (file) {
      if (file.size <= 10 * 1024 * 1024) {
        const allowedTypes = [
          'image/png',
          'image/svg+xml',
          'image/jpeg',
          'image/jpg',
        ];

        if (allowedTypes.includes(file.type)) {
          const img = new Image();
          img.onload = () => {
            if (img.width <= 300 && img.height <= 200) {
              this.selectedLogoFile = file;
              this.logoFileName = file.name;
              let logo = {
                file: file,
                type: 'logo',
              };
              this.screeningMgr.saveCompanyLogo(logo);
            } else {
              event.target.value = null;
              this.notification.create(
                'error',
                'Error',
                'Image dimensions must be 300px wide and 200px high or less!'
              );
            }
          };
          img.src = URL.createObjectURL(file);
        } else {
          event.target.value = null;
          this.notification.create(
            'error',
            'Error',
            'Only PNG, SVG, JPG, or JPEG files are allowed!'
          );
        }
      } else {
        event.target.value = null;
        this.notification.create(
          'error',
          'Error',
          'File size must be less than 10MB!'
        );
      }
    }
  }

  onLogoDropped(files: FileList) {
    const file = files[0];
    if (file) {
      this.changeLogoFile({ target: { files: [file] } });
    }
  }
  // Banner upload
  changeBannerFile(event: any) {
    const file = event?.target?.files ? event.target.files[0] : null;
    if (file) {
      if (file.size <= 10 * 1024 * 1024) {
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];

        if (allowedTypes.includes(file.type)) {
          const img = new Image();
          img.onload = () => {
            if (img.width <= 1128 && img.height <= 191) {
              this.selectedBannerFile = file;
              this.bannerFileName = file.name;
              let banner = {
                file: file,
                type: 'banner',
              };
              this.screeningMgr.saveCompanyBanner(banner);
            } else {
              event.target.value = null;
              this.notification.create(
                'error',
                'Error',
                'Image dimensions must be 1128px wide and 191px high or less!'
              );
            }
          };
          img.src = URL.createObjectURL(file);
        } else {
          event.target.value = null;
          this.notification.create(
            'error',
            'Error',
            'Only PNG, SVG, JPG, or JPEG files are allowed!'
          );
        }
      } else {
        event.target.value = null;
        this.notification.create(
          'error',
          'Error',
          'File size must be less than 10MB!'
        );
      }
    }
  }

  onBannerDropped(files: FileList) {
    const file = files[0];
    if (file) {
      this.changeBannerFile({ target: { files: [file] } });
    }
  }
  async deleteLogo() {
    await this.screeningMgr.deleteCompanyImage('logo');
    this.logoFileName = null;
  }
  async deleteBanner() {
    await this.screeningMgr.deleteCompanyImage('banner');
    this.bannerFileName = null;
  }
  getFileNameFromUrl(url: string): string {
    const urlObj = new URL(url);
    const pathParts = urlObj.pathname.split('/');
    return pathParts[pathParts.length - 1];
  }
  // color hex inputs
  validateHexColor(
    field: 'primary_containers' | 'primary_text' | 'secondary_color'
  ): void {
    const color = this[field];
    this.validationState[field] = color ? this.isValidHexColor(color) : null;
  }
  handleKeydown(
    event: KeyboardEvent,
    field: 'primary_containers' | 'primary_text' | 'secondary_color'
  ): void {
    if (event.key === 'Enter') {
      this.validateAndSubmitHexColor(field);
    }
  }

  validateAndSubmitHexColor(
    field: 'primary_containers' | 'primary_text' | 'secondary_color'
  ): void {
    const color = this[field];
    if (color && this.isValidHexColor(color)) {
      this.updateColor(field, color);
    } else {
      this.validationState[field] = false;
    }
  }

  isValidHexColor(color: any): boolean {
    return this.hexColorRegex.test(color);
  }

  async updateColor(
    field: 'primary_containers' | 'primary_text' | 'secondary_color',
    color: string
  ): Promise<void> {
    try {
      let colorData = {
        type: field,
        value: color,
      };
      this.screeningMgr.saveCompanyColor(colorData);
      console.log(`Successfully updated ${field} with color: ${color}`);
    } catch (error) {
      console.error(`Failed to update ${field}:`, error);
    }
  }
  async ngOnInit(): Promise<void> {
    await this.screeningMgr.getCompanyBasicSettings();

    const {
      about,
      appearance: {
        primary_containers,
        primary_text,
        secondary_color,
        logo,
        banner,
      },
    } = this.screeningMgr.companyBasicData;

    this.aboutUsContent = about;
    console.log('about', this.aboutUsContent);

    // Assign default values if null
    this.primary_containers = primary_containers ?? '#000000';
    this.primary_text = primary_text ?? '#000000';
    this.secondary_color = secondary_color ?? '#000000';

    this.selectedLogoFile = logo;
    this.selectedBannerFile = banner;

    if (logo) {
      this.logoFileName = this.getFileNameFromUrl(logo);
    }
    if (banner) {
      this.bannerFileName = this.getFileNameFromUrl(banner);
    }
  }

  ngOnDestroy(): void {}
}
