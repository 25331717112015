<div class="page">
    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">
        <nz-breadcrumb>
            <nz-breadcrumb-item>
                <a><span nz-icon nzType="home"></span></a>
            </nz-breadcrumb-item>
            <nz-breadcrumb-item>Settings</nz-breadcrumb-item>
            <nz-breadcrumb-item>Data Sharing</nz-breadcrumb-item>
        </nz-breadcrumb>

        <div nz-row [nzGutter]="16">
            <div nz-col [nzSpan]="10">
                <div class="heading-flex">
                    <h1>Data Sharing</h1>
                    <button nz-button nzType="default" (click)="editProfileModal()">Edit</button>
                </div>
                <nz-table #basicTable [nzData]="listOfCompanies" [nzFrontPagination]="false">
                    <thead>
                        <tr>
                            <th>Company</th>
                            <th>Shared Data</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of basicTable.data">
                            <td>{{ data.companyName }}</td>
                            <td>
                                <nz-space *ngFor="let item of data.tags; index as i;">
                                    <nz-tag>{{item}}</nz-tag>
                                </nz-space>
                            </td>
                            <td><button nz-button nzType="link" (click)="editProfileModal()">Edit</button></td>
                        </tr>
                    </tbody>
                </nz-table>
            </div>
        </div>
        <nz-modal [(nzVisible)]="isVisibleProfileModal" nzTitle="Data Sharing" (nzOnCancel)="handleProfileCancel()"
            [nzFooter]="null" nzWidth="700px" [nzMaskClosable]="false">
            <ng-container *nzModalContent>
                <nz-table #basicTable [nzData]="listOfCompaniesData" [nzFrontPagination]="false">
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Shared Data</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of basicTable.data">
                            <td>{{ data.data }}</td>
                            <td>{{ data.sharedData }}</td>
                            <td><nz-switch></nz-switch></td>
                        </tr>
                    </tbody>
                </nz-table>
                <br>
                <div class="custom-footer">
                    <button nz-button nzBlock nzSize="large" [nzType]="'primary'" class="mb-10" nzShape="round">Save
                        Changes</button>
                    <button nz-button nzType="link" nzBlock (click)="handleProfileCancel()"
                        nzShape="round">Cancel</button>
                </div>
            </ng-container>

        </nz-modal>
    </main>
</div>