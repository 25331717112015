<nz-modal [(nzVisible)]="isVisible" nzTitle="Edit the Sorting Range for Job ID {{jobObjectId}} ({{jobName}})"
    (nzOnCancel)="handleOk()" [nzFooter]="null" nzWidth="650px" [nzMaskClosable]="false">
    <ng-container *nzModalContent>
        <div class="center-form" *ngIf="sortingForm">
            <div class="border-box">
                <p class="mb-30">Use the slider to select how to sort candidates based on the
                    CLARA Fit Score.</p>
                <div class="flex-boxes">
                    <div class="small-box">
                        <h4>{{ sortingMgr.updateSortingJobData.not_qualified.max_score | number:'1.0-0' }}</h4>

                        <p>Fit Score</p>
                    </div>

                </div>
                <div class="slider-area">
                    <nz-slider [(ngModel)]="sortingMgr.updateSortingJobData.not_qualified.max_score"
                        (ngModelChange)="onChangeScore($event)" [nzTipFormatter]="titleTemplate"></nz-slider>
                    <ng-template #titleTemplate let-value>
                        <span>{{ value | number:'1.0-0' }}</span>
                    </ng-template>
                </div>
                <div class="info-slider">
                    <p>Candidates with scores between 0-{{sortingMgr.updateSortingJobData.not_qualified.max_score |
                        number:'1.0-0'}}
                        will
                        be sorted into the Not Qualified
                        category.</p>
                    <p>Candidates with scores between
                        {{sortingMgr.updateSortingJobData.pre_qualified.min_score}}-100
                        will be sorted into the Pre-Qualified
                        category.</p>
                </div>

            </div>

            <div class="custom-footer">
                <button (click)="submitingSorting()" nz-button nzBlock nzSize="large" [nzType]="'primary'" class="mb-10"
                    nzShape="round">
                    Save and Sort
                </button>
                <button nz-button nzType="link" nzBlock (click)="handleOk()" nzShape="round">Cancel</button>
            </div>

        </div>
        <div class="is-confirm" *ngIf="sortingConfirm">
            <h2>Sorting Candidates</h2>
            <p *ngIf="!sortingMgr.isUpdatingPercent">All changes will be lost. Are you sure you want to run the
                Candidate sorting?</p>
            <nz-progress *ngIf="sortingMgr.isUpdatingPercent" [nzPercent]="sortingMgr.percent"
                nzType="circle"></nz-progress>
            <div class="custom-footer" *ngIf="!sortingMgr.isUpdatingPercent">
                <button (click)="submitSorting()" nz-button nzBlock nzSize="large" [nzType]="'primary'" class="mb-10"
                    nzShape="round">
                    Sort Candidates
                </button>
                <button nz-button nzType="link" nzBlock (click)="backSorting()" nzShape="round">Back</button>
            </div>
        </div>
    </ng-container>
</nz-modal>