<div class="job-detail color-black" *ngIf="jobDetailData && jobDetailData.title != ''">
    <div class="left-panel">
        <p><b>About the job</b></p>
        <p
            [innerHTML]="replaceEscapeSequences(jobDetailData.extracted_details && jobDetailData.extracted_details.about)">
        </p>

        <div *ngIf="jobDetailData.extracted_details && jobDetailData.extracted_details.responsibilities?.length">
            <p><b>Responsibilities</b></p>
            <ul>
                <li *ngFor="let item of jobDetailData.extracted_details.responsibilities">{{item.description}}</li>
            </ul>
        </div>

        <div *ngIf="jobDetailData.extracted_details && jobDetailData.extracted_details.required_experience?.length">
            <p><b>Required Experience</b></p>
            <ul>
                <li *ngFor="let item of jobDetailData.extracted_details.required_experience">{{item.description}}</li>
            </ul>
        </div>

        <div *ngIf="jobDetailData.extracted_details && jobDetailData.extracted_details.nice_to_have_experience?.length">
            <p><b>Nice to have Experience</b></p>
            <ul>
                <li *ngFor="let item of jobDetailData.extracted_details.nice_to_have_experience">{{item.description}}
                </li>
            </ul>
        </div>

        <div *ngIf="jobDetailData.extracted_details && jobDetailData.extracted_details.required_skills?.length">
            <p><b>Required Skills</b></p>
            <ul>
                <li *ngFor="let item of jobDetailData.extracted_details.required_skills">{{item.description}}</li>
            </ul>
        </div>

        <div *ngIf="jobDetailData.extracted_details && jobDetailData.extracted_details.nice_to_have_skills?.length">
            <p><b>Nice to have Skills</b></p>
            <ul>
                <li *ngFor="let item of jobDetailData.extracted_details.nice_to_have_skills">{{item.description}}</li>
            </ul>
        </div>

        <div *ngIf="jobDetailData.extracted_details && jobDetailData.extracted_details.people_skills?.length">
            <p><b>People Skills</b></p>
            <ul>
                <li *ngFor="let item of jobDetailData.extracted_details.people_skills">{{item.description}}</li>
            </ul>
        </div>
    </div>
    <div class="right-panel">
        <h3>Job Posting</h3>
        <ul class="info-list">
            <li>
                <span>Job Name</span>
                <span>{{jobDetailData.title}}</span>
            </li>
            <li *ngIf="jobDetailData.remote_id">
                <span>Job ID</span>
                <span>{{jobDetailData.remote_id}}</span>
            </li>
            <li>
                <span>Date Posted</span>
                <span>{{jobDetailData.posting_date}}</span>
            </li>
            <li>
                <span>ATS Posting</span>
                <span>{{jobDetailData.ats | uppercase}}</span>
            </li>
        </ul>
        <h3>Detail</h3>
        <ul class="info-list">
            <li>
                <span>Position</span>
                <span>{{jobDetailData.position}}</span>
            </li>
            <li>
                <span>Location</span>
                <span>{{jobDetailData.location}}</span>
            </li>
            <li>
                <span>Role Duration</span>
                <span>{{jobDetailData.role_duration}}</span>
            </li>
        </ul>
        <h3>Education</h3>
        <p *ngIf="jobDetailData.extracted_details && jobDetailData.extracted_details.education">
            {{jobDetailData.extracted_details.education.degree}}
        </p>

    </div>

    <div class="no-job-details-message" *ngIf="jobDetailData && jobDetailData.title === ''">
        No job details available.
    </div>