import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './Guards/auth.guard';
import { loginStatusGuard } from './Guards/login-status.guard';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { UsersComponent } from './pages/users/users.component';
import { JobsComponent } from './pages/jobs/jobs.component';
import { PartnersComponent } from './pages/partners/partners.component';
import { CreateCompanyComponent } from './pages/partners/create-company/create-company.component';
import { ApplicationsComponent } from './pages/jobs/applications/applications.component';
import { MyAccountComponent } from './pages/settings/my-account/my-account.component';
import { IntegrationsComponent } from './pages/settings/integrations/integrations.component';
import { AISettingsComponent } from './pages/settings/aisettings/aisettings.component';
import { CandidateSortingComponent } from './pages/settings/candidate-sorting/candidate-sorting.component';
import { ScreeningActionsComponent } from './pages/settings/screening-actions/screening-actions.component';
import { RegisterComponent } from './pages/register/register.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { VerifyEmailComponent } from './pages/verify-email/verify-email.component';
import { GetStartedComponent } from './pages/partners/get-started/get-started.component';
import { NotAuthorizedComponent } from './pages/errors/not-authorized/not-authorized.component';
import { NotificationsSettingsComponent } from './pages/settings/notifications-settings/notifications-settings.component';
import { ProfileComponent } from './pages/users/candidates/profile/profile.component';
import { ConfirmIdentityComponent } from './pages/users/candidates/confirm-identity/confirm-identity.component';
import { FollowUpQuestionsComponent } from './pages/users/candidates/follow-up-questions/follow-up-questions.component';
import { CandidatesListComponent } from './pages/candidates-list/candidates-list.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { OptDataPolicyComponent } from './pages/opt-data-policy/opt-data-policy.component';
import { CreateCandidateAccountComponent } from './pages/create-candidate-account/create-candidate-account.component';
import { JobProcessingComponent } from './pages/settings/job-processing/job-processing.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { CandidateMyProfileComponent } from './pages/candidate-my-profile/candidate-my-profile.component';
import { UnsubscribeEmailComponent } from './pages/unsubscribe-email/unsubscribe-email.component';
import { UploadNewJobComponent } from './pages/jobs/upload-new-job/upload-new-job.component';
import { UploadCandidatesComponent } from './pages/jobs/upload-candidates/upload-candidates.component';
import { ImpersonationComponent } from './pages/impersonation/impersonation.component';
import { ApplyJobComponent } from './pages/jobs/apply-job/apply-job.component';
import { MyApplicationsComponent } from './pages/users/candidates/my-applications/my-applications.component';
import { ApplicationSubmittedComponent } from './pages/users/candidates/application-submitted/application-submitted.component';
import { SubscriptionComponent } from './pages/settings/subscription/subscription.component';
import { ShorterFollowUpQuestionsComponent } from './pages/jobs/applications/shorter-follow-up-questions/shorter-follow-up-questions.component';
import { EditJobFlowComponent } from './pages/jobs/edit-job-flow/edit-job-flow.component';
import { CompanyBasicsComponent } from './pages/settings/company-basics/company-basics.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [loginStatusGuard],
    title: 'Login',
  },
  { path: 'register', component: RegisterComponent, title: 'Register' },
  {
    path: 'unsubscribe',
    component: UnsubscribeEmailComponent,
    title: 'Unsubscribe',
  },
  {
    path: 'create-account',
    component: CreateCandidateAccountComponent,
    title: 'Create Account',
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    title: 'Forgot Password',
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    title: 'Reset Password',
  },
  {
    path: 'create-company',
    component: CreateCompanyComponent,
    title: 'Create Company',
  },
  {
    path: 'get-started',
    component: GetStartedComponent,
    title: 'Get Started',
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
    title: 'Terms and Conditions',
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    title: 'Privacy Policy',
  },
  {
    path: 'opt-data-policy',
    component: OptDataPolicyComponent,
    title: 'Opt Data Policy',
  },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [authGuard],
    title: 'Dashboard',
  },
  {
    path: 'verify-email',
    component: VerifyEmailComponent,
    title: 'Email Verification',
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [authGuard],
    data: {
      permission: { content_type: 'user', desiredPermission: 'view' },
    },
    title: 'Users',
  },
  {
    path: 'users/:id',
    component: ProfileComponent,
    canActivate: [authGuard],
    title: 'Profile',
  },
  {
    path: 'candidates',
    component: CandidatesListComponent,
    canActivate: [authGuard],
    data: {
      permission: { content_type: 'candidate', desiredPermission: 'view' },
    },
    title: 'Candidates',
  },
  {
    path: 'my-profile',
    component: CandidateMyProfileComponent,
    canActivate: [authGuard],
    title: 'My Profile',
  },
  {
    path: 'candidates/:id',
    component: ProfileComponent,
    canActivate: [authGuard],
    data: {
      permission: { content_type: 'candidate', desiredPermission: 'view' },
    },
    title: 'Candidates',
  },
  {
    path: 'confirm-identity',
    component: ConfirmIdentityComponent,
    title: 'Confirm Identity',
  },
  {
    path: 'follow-up-questions',
    component: FollowUpQuestionsComponent,
    title: 'Follow Up Questions',
  },
  {
    path: 'jobs',
    component: JobsComponent,
    canActivate: [authGuard],
    data: {
      permission: { content_type: 'job', desiredPermission: 'view' },
    },
    title: 'Jobs',
  },
  {
    path: 'jobs/upload-new-job',
    component: UploadNewJobComponent,
    canActivate: [authGuard],
    data: {
      permission: { content_type: 'job', desiredPermission: 'view' },
    },
    title: 'Upload New Job',
  },

  {
    path: 'jobs/upload-candidates/:jobObjectId',
    component: UploadCandidatesComponent,
    canActivate: [authGuard],
    data: {
      permission: { content_type: 'job', desiredPermission: 'view' },
    },
    title: 'Upload Candidates',
  },
  {
    path: 'jobs/shorter-follow-up-questions/:jobObjectId',
    component: ShorterFollowUpQuestionsComponent,
    canActivate: [authGuard],
    data: {
      permission: { content_type: 'job', desiredPermission: 'view' },
    },
    title: 'Shorter Follow-up Questions (FQ’s)',
  },
  {
    path: 'jobs/job-flow',
    component: EditJobFlowComponent,
    canActivate: [authGuard],
    data: {
      permission: { content_type: 'job', desiredPermission: 'view' },
    },
    title: 'Edit Job Flow',
  },
  {
    path: 'jobs/job-flow/:jobObjectId?',
    component: EditJobFlowComponent,
    canActivate: [authGuard],
    data: {
      permission: { content_type: 'job', desiredPermission: 'view' },
    },
    title: 'Edit Job Flow',
  },
  {
    path: 'jobs/candidates/:jobObjectId',
    component: ApplicationsComponent,
    canActivate: [authGuard],
    data: {
      permission: { content_type: 'job', desiredPermission: 'view' },
    },
    title: 'Applications',
  },
  {
    path: 'apply-job',
    component: ApplyJobComponent,
    title: 'Apply Job | Clara',
  },
  {
    path: 'application-submitted/:jobObjectId',
    component: ApplicationSubmittedComponent,
    title: 'Your application was received! | Clara',
  },
  {
    path: 'my-applications/:jobObjectId',
    component: MyApplicationsComponent,
    title: 'My Applications | Clara',
  },
  {
    path: 'partners',
    component: PartnersComponent,
    canActivate: [authGuard],
    data: {
      permission: { content_type: 'partner', desiredPermission: 'view' },
    },
    title: 'Partners',
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
    canActivate: [authGuard],
    title: 'Welcome',
  },
  {
    path: 'impersonation',
    component: ImpersonationComponent,
    canActivate: [authGuard],
    title: 'Impersonation',
  },
  {
    path: 'my-account',
    component: MyAccountComponent,
    canActivate: [authGuard],
    title: 'My Account',
  },
  {
    path: 'ai-settings',
    component: AISettingsComponent,
    canActivate: [authGuard],
    data: {
      permission: {
        content_type: 'partnersettings',
        desiredPermission: 'view',
      },
    },
    title: 'AI Settings',
  },
  {
    path: 'company-basics',
    component: CompanyBasicsComponent,
    canActivate: [authGuard],
    data: {
      permission: {
        content_type: 'partnersettings',
        desiredPermission: 'view',
      },
    },
    title: 'Company Basics',
  },
  {
    path: 'job-processing',
    component: JobProcessingComponent,
    canActivate: [authGuard],
    data: {
      permission: {
        content_type: 'partnersettings',
        desiredPermission: 'view',
      },
    },
    title: 'Job Settings',
  },
  {
    path: 'subscription',
    component: SubscriptionComponent,
    canActivate: [authGuard],
    data: {
      permission: {
        content_type: 'partnersettings',
        desiredPermission: 'view',
      },
    },
    title: 'Subscription Settings',
  },
  {
    path: 'notifications-settings',
    component: NotificationsSettingsComponent,
    canActivate: [authGuard],
    data: {
      permission: {
        content_type: 'partnersettings',
        desiredPermission: 'view',
      },
    },
    title: 'Notifications',
  },
  {
    path: 'candidate-sorting',
    component: CandidateSortingComponent,
    canActivate: [authGuard],
    data: {
      permission: {
        content_type: 'partnersettings',
        desiredPermission: 'view',
      },
    },
    title: 'Candidate Sorting',
  },

  {
    path: 'integrations',
    component: IntegrationsComponent,
    canActivate: [authGuard],
    data: {
      permission: {
        content_type: 'partnersettings',
        desiredPermission: 'view',
      },
    },
    title: 'Integrations',
  },
  {
    path: 'screening-actions',
    component: ScreeningActionsComponent,
    canActivate: [authGuard],
    data: {
      permission: {
        content_type: 'partnersettings',
        desiredPermission: 'view',
      },
    },
    title: 'Screening Actions',
  },

  {
    path: 'not-authorized',
    component: NotAuthorizedComponent,
    title: 'Permission Denied- Dein',
  },
  {
    path: '**',
    component: NotFoundComponent,
    title: '404 Page Not Found- Dein',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
