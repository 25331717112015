import { Component } from '@angular/core';
import { JobsManagerService } from 'src/app/Managers/jobs-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ApplicationManagerService } from 'src/app/Managers/applications-manager.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-edit-job-flow',
  templateUrl: './edit-job-flow.component.html',
  styleUrl: './edit-job-flow.component.scss',
})
export class EditJobFlowComponent {
  jobForm!: FormGroup; // The main form group
  currentStep: number = 1;
  totalSteps: number = 2;
  jobObjectId: string = '';
  user: any;
  jobTitle: string = '';

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public jobsMgr: JobsManagerService,
    private router: Router,
    public applicationMgr: ApplicationManagerService,
    private notification: NzNotificationService
  ) {}

  handleCancelModal(): void {
    this.jobsMgr.resetGlobalFlowModal = false;
  }

  getStepClass(step: number): string {
    if (step < this.currentStep) {
      return 'done';
    } else if (step === this.currentStep) {
      return 'active';
    } else {
      return 'inactive';
    }
  }
  nextStep(): void {
    if (this.jobForm.valid) {
      // Check for duplicates in stages and screening steps separately
      const hasStageDuplicates = this.checkForStageDuplicates();
      const hasScreeningStepDuplicates = this.checkForScreeningStepDuplicates();

      if (hasStageDuplicates) {
        this.notification.create(
          'error',
          'Error',
          'There are duplicate status names in statuses. Please ensure all status names are unique.'
        );
        return;
      }

      if (hasScreeningStepDuplicates) {
        this.notification.create(
          'error',
          'Error',
          'There are duplicate screening step names in screening steps. Please ensure all screening step names are unique.'
        );
        return;
      }

      this.currentStep++;
    } else {
      this.notification.create(
        'error',
        'Error',
        'Please fill out the empty field'
      );
      Object.values(this.jobForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  // Method to check for duplicates in stages
  // Method to check for duplicates in stages
  private checkForStageDuplicates(): boolean {
    const statusesArray = this.statusesArray.controls;

    // Create a set to track unique stage names (case insensitive)
    const stageNames = new Set<string>();

    // Check for duplicates in stages
    for (const stageControl of statusesArray) {
      const stageName = stageControl.get('job_status_name')?.value;

      // Normalize stage name to lowercase for case insensitive comparison
      if (stageName) {
        const normalizedStageName = stageName.toLowerCase();

        if (stageNames.has(normalizedStageName)) {
          return true; // Duplicate found
        }
        stageNames.add(normalizedStageName);
      }
    }

    return false; // No duplicates found
  }

  // Method to check for duplicates in screening steps
  private checkForScreeningStepDuplicates(): boolean {
    const screeningStepsArray = this.screeningStepsArray.controls;

    // Create a set to track unique screening step names (case insensitive)
    const screeningStepNames = new Set<string>();

    // Check for duplicates in screening steps
    for (const screeningControl of screeningStepsArray) {
      const screeningStepName = screeningControl.get('job_stage_name')?.value;

      // Normalize screening step name to lowercase for case insensitive comparison
      if (screeningStepName) {
        const normalizedScreeningStepName = screeningStepName.toLowerCase();

        if (screeningStepNames.has(normalizedScreeningStepName)) {
          return true; // Duplicate found
        }
        screeningStepNames.add(normalizedScreeningStepName);
      }
    }

    return false; // No duplicates found
  }

  async submitJobFlow(): Promise<void> {
    if (this.jobObjectId) {
      await this.jobsMgr.updateJobFlow(
        this.user.role,
        this.jobObjectId,
        this.jobForm.value
      );
      if (this.user.role === 'Recruiter') {
        this.router.navigate(['jobs/candidates/', this.jobObjectId]);
      }
    } else {
      await this.jobsMgr.updatePartnerJobFlow(this.jobForm.value);
      this.jobsMgr.selectedIndex = 2;
      this.router.navigate(['/job-processing']);
    }
  }
  async submitGlobalJobFlow(): Promise<void> {
    await this.jobsMgr.updateJobFlowGlobal(
      this.jobObjectId,
      this.jobForm.value
    );
    this.router.navigate(['jobs/candidates/', this.jobObjectId]);
  }

  previousStep(): void {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    this.jobForm = this.fb.group({
      statuses: this.fb.array([]),
      screening_steps: this.fb.array([]),
    });

    this.route.params.subscribe(async (params) => {
      this.jobObjectId = params['jobObjectId?'];
    });

    if (this.jobObjectId) {
      this.applicationMgr.jobDetail(this.jobObjectId, async () => {
        this.jobTitle = this.applicationMgr.jobDetailData['title'];
      });
      this.jobsMgr.getJobFlow(this.jobObjectId).then(() => {
        this.loadStatuses();
        this.loadScreeningSteps();
      });
    } else {
      this.jobsMgr.getPartnerJobFlow().then(() => {
        this.loadStatuses();
        this.loadScreeningSteps();
      });
    }
  }
  dropStages(event: CdkDragDrop<FormArray>): void {
    const formArray = this.jobForm.get('statuses') as FormArray;
    moveItemInArray(
      formArray.controls,
      event.previousIndex,
      event.currentIndex
    );
    formArray.controls.forEach((control, index) => {
      control.patchValue({ index });
    });

    formArray.updateValueAndValidity();
  }

  dropScreening(event: CdkDragDrop<FormArray>): void {
    const formArray = this.jobForm.get('screening_steps') as FormArray;
    moveItemInArray(
      formArray.controls,
      event.previousIndex,
      event.currentIndex
    );
    formArray.controls.forEach((control, index) => {
      control.patchValue({ index });
    });

    formArray.updateValueAndValidity();
  }
  get statusesArray(): FormArray {
    return this.jobForm.get('statuses') as FormArray;
  }

  get screeningStepsArray(): FormArray {
    return this.jobForm.get('screening_steps') as FormArray;
  }

  loadStatuses(): void {
    const statusesData = this.jobsMgr.stages?.statuses || [];
    const statusesArray = this.statusesArray;

    statusesData.forEach((status) => {
      statusesArray.push(
        this.fb.group({
          job_stage_id: [status.job_stage_id],
          job_status_name: [status.job_status_name, Validators.required],
          type: [status.type],
          index: [status.index],
        })
      );
    });

    if (statusesArray.length === 0) {
      this.addNewStatus();
    }
  }

  loadScreeningSteps(): void {
    const stepsData = this.jobsMgr.stages?.screening_steps || [];
    const screeningStepsArray = this.screeningStepsArray;

    stepsData.forEach((step) => {
      screeningStepsArray.push(
        this.fb.group({
          job_stage_id: [step.job_stage_id],
          job_stage_name: [step.job_stage_name, Validators.required],
          type: [step.type],
          index: [step.index],
        })
      );
    });

    if (screeningStepsArray.length === 0) {
      this.addNewScreeningStep();
    }
  }

  addNewStatus(): void {
    const currentMaxIndex = this.statusesArray.controls.reduce(
      (maxId, group) => {
        const id = parseInt(group.get('index')?.value, 10); // Convert to number
        return id > maxId ? id : maxId;
      },
      0
    );
    this.statusesArray.push(
      this.fb.group({
        job_status_name: ['', Validators.required],
        type: ['user_added'],
        index: currentMaxIndex + 1,
      })
    );
  }
  removeStage(index: number): void {
    this.statusesArray.removeAt(index);
  }

  addNewScreeningStep(): void {
    const currentMaxIndex = this.screeningStepsArray.controls.reduce(
      (maxId, group) => {
        const id = parseInt(group.get('index')?.value, 10); // Convert to number
        return id > maxId ? id : maxId;
      },
      0
    );
    this.screeningStepsArray.push(
      this.fb.group({
        job_stage_name: ['', Validators.required],
        type: ['user_added'],
        index: currentMaxIndex + 1,
      })
    );
  }

  removeScreeningStep(index: number): void {
    this.screeningStepsArray.removeAt(index);
  }
  getStatusesPreview(): { id: string; name: string; type: string }[] {
    return this.statusesArray.controls.map((group) => {
      return {
        id: group.get('job_stage_id')?.value,
        name: group.get('job_status_name')?.value,
        type: group.get('type')?.value,
      };
    });
  }

  getScreeningStepsPreview(): { id: string; name: string; type: string }[] {
    return this.screeningStepsArray.controls.map((group) => {
      return {
        id: group.get('job_stage_id')?.value,
        name: group.get('job_stage_name')?.value,
        type: group.get('type')?.value,
      };
    });
  }
  ngOnDestroy(): void {
    this.jobsMgr.stages = null;
    this.jobObjectId = '';
  }
}
