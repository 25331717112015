import { Injectable } from '@angular/core';
import { notificationsSettings } from '../models/models';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NotificationsSettingService } from '../Services/notifications-setting.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsSettingsManagerService {
  public isLoading: boolean = false;
  notificationsSettingData: notificationsSettings[] = [];

  constructor(
    private notification: NzNotificationService,
    private notificationServices: NotificationsSettingService
  ) {}

  public getNotificationsSettings(): void {
    this.isLoading = true;
    this.notificationServices.getNotificationsSettings().subscribe({
      next: (c: any) => {
        this.notificationsSettingData = c.response;

        this.isLoading = false;
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoading = false;
      },
    });
  }
  public updatedNotificationsSettings(data: any): void {
    this.isLoading = true;
    this.notificationServices.updatedNotificationsSettings(data).subscribe({
      next: (c: any) => {
        this.isLoading = false;
        this.getNotificationsSettings();
        this.notification.create(
          'success',
          'Notification updated!',
          'Notifications are updated successfully'
        );
      },
      error: (error: any) => {
        if (error.status !== 500) {
          this.notification.create('error', 'Error', error.error.error);
        }
        this.isLoading = false;
      },
    });
  }
}
