<div class="page">

    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">

        <div nz-row [nzGutter]="16">
            <div nz-col [nzSpan]="20">
                <nz-breadcrumb [nzSeparator]="iconTemplate">
                    <nz-breadcrumb-item>
                        <a routerLink="/">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5.333 11.333h5.334m-3.322-9.49L2.824 5.359c-.303.236-.454.353-.563.5-.096.13-.168.278-.212.434C2 6.469 2 6.66 2 7.043v4.824c0 .746 0 1.12.145 1.405.128.25.332.455.583.583.285.145.659.145 1.405.145h7.734c.746 0 1.12 0 1.405-.145.25-.128.455-.332.583-.583.145-.285.145-.659.145-1.405V7.043c0-.383 0-.574-.05-.75a1.332 1.332 0 0 0-.211-.434c-.11-.147-.26-.264-.563-.5L8.655 1.843c-.234-.182-.351-.274-.48-.309a.667.667 0 0 0-.35 0c-.129.035-.246.127-.48.309z"
                                    stroke="#1C1A20" stroke-opacity=".36" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>

                        </a>
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item><a routerLink="/jobs">Jobs</a></nz-breadcrumb-item>
                    <nz-breadcrumb-item>Upload New Job</nz-breadcrumb-item>
                </nz-breadcrumb>
                <ng-template #iconTemplate><span nz-icon nzType="right" nzTheme="outline"></span></ng-template>
            </div>
        </div>

        <div class="heading">
            <a routerLink="/jobs"><span nz-icon class="heading-icon" nzType="left" nzTheme="outline"></span></a>
            <h1 class="font-heading">Upload New Job</h1>
        </div>
        <div nz-row [nzGutter]="16">
            <div nz-col [nzSpan]="18">
                <div class="white-box">
                    <div class="white-box-header">
                        <div class="job-steps">
                            <div class="steps-item" [ngClass]="getStepClass(1)">
                                <div class="step-dot">
                                    <div class="white-dot"></div><span class="checkmark" nz-icon nzType="check"></span>
                                </div>
                                <span class="step-title">Upload</span>
                            </div>
                            <div class="steps-item" [ngClass]="getStepClass(2)">
                                <div class="step-dot">
                                    <div class="white-dot"></div><span class="checkmark" nz-icon nzType="check"></span>
                                </div>
                                <span class="step-title">Finalize</span>
                            </div>
                            <div class="steps-item" [ngClass]="getStepClass(3)">
                                <div class="step-dot">
                                    <div class="white-dot"></div><span class="checkmark" nz-icon nzType="check"></span>
                                </div>
                                <span class="step-title">Application Materials</span>
                            </div>
                            <div class="steps-item" [ngClass]="getStepClass(4)">
                                <div class="step-dot">
                                    <div class="white-dot"></div><span class="checkmark" nz-icon nzType="check"></span>
                                </div>
                                <span class="step-title">Follow-up Questions</span>
                            </div>
                            <div class="steps-item" [ngClass]="getStepClass(5)">
                                <div class="step-dot">
                                    <div class="white-dot"></div><span class="checkmark" nz-icon nzType="check"></span>
                                </div>
                                <span class="step-title">AI Settings</span>
                            </div>
                        </div>

                    </div>
                    <div class="white-box-body">
                        <ng-container *ngIf="currentStep === 1">
                            <div class="gray-text-box">
                                <h4 class="font-heading">Best Practices</h4>
                                <p>The Office of Federal Contract Compliance (OFCCP) is a federal agency in the U.S.
                                    Department of Labor,
                                    responsible for
                                    ensuring non-discrimination practices in federal contractors and subcontractors.
                                    This includes compliance with
                                    affirmative action requirements and don’t discriminate based on race, color, sex,
                                    sexual orientation, gender
                                    identity,
                                    religion, national origin, disability, or status as a protected veteran.</p>
                                <ng-container *ngIf="isExpanded">
                                    <p><strong>Requirements include:</strong></p>
                                    <ul>
                                        <li>Have an Affirmative Action Program.</li>
                                        <li>Ensure job advertisements are non-discriminatory and include language
                                            stating such.</li>
                                        <li>Allow candidates and employees to self-identify their demographic data.</li>
                                        <li>Allow reasonable accommodations for disabilities.</li>
                                        <li>Have an internal audit and reporting system to ensure non-discriminatory
                                            processes are being followed.
                                        </li>
                                        <li>Follow recordkeeping requirements.</li>
                                        <li>Include required non-discrimination notices provided by OFCCP.</li>
                                    </ul>
                                    <p>
                                        For more information, visit the OFCCP website at <a
                                            href="https://www.dol.gov/agencies/ofccp" target="_blank">Office of Federal
                                            Contract Compliance Programs</a> and see its Internet Applicant
                                        Recordkeeping Rule FAQs at <a
                                            href="https://www.dol.gov/agencies/ofccp/faqs/internet-applicants"
                                            target="_blank">Internet Applicant Recordkeeping Rule</a>
                                    </p>
                                </ng-container>

                                <div class="button-container">
                                    <button nz-button nzType="text" class="btn-dark" (click)="toggleReadMore()">{{
                                        isExpanded ?
                                        'Read Less' :
                                        'Read More' }}</button>
                                </div>
                            </div>
                            <app-upload-file (fileSelected)="onFileSelected($event)"
                                (jobTitleChanged)="onJobTitleChange($event)"
                                (jobLocationChanged)="onJobLocationChange($event)"
                                (onJobAllowMultipleLinksChange)="onJobAllowMultipleLinksChange($event)"></app-upload-file>

                        </ng-container>
                        <ng-container *ngIf="currentStep === 2">
                            <app-edit-description (editDescription)="onEditDescription($event)"></app-edit-description>
                        </ng-container>
                        <ng-container *ngIf="currentStep === 3">
                            <app-application-materials></app-application-materials>
                        </ng-container>
                        <ng-container *ngIf="currentStep === 4">
                            <app-sjmfollow-up-questions
                                (submitFollowUpQuestions)="onSubmitFollowUpQuestions($event)"></app-sjmfollow-up-questions>
                        </ng-container>
                        <ng-container *ngIf="currentStep === 5">
                            <app-sjmaisettings></app-sjmaisettings>
                        </ng-container>
                    </div>
                    <div class="white-box-footer">
                        <button (click)="previousStep()" nz-button nzSize="large" [nzType]="'default'"
                            nzShape="round">Back</button>

                        <button *ngIf="currentStep === 1" (click)="nextStepUploadJob()" nz-button nzSize="large"
                            [nzType]="'primary'" nzShape="round" [disabled]="!selectedFile || !jobTitle"> <span
                                *ngIf="sjmMgr.uploadingJob" nz-icon nzType="loading"
                                nzTheme="outline"></span>Next:Finalize</button>

                        <button *ngIf="currentStep === 2" (click)="editDescription()" nz-button nzSize="large"
                            [nzType]="'primary'" nzShape="round"> <span *ngIf="sjmMgr.finalizingJob" nz-icon
                                nzType="loading" nzTheme="outline"></span>Next:
                            Application Materials</button>

                        <button *ngIf="currentStep === 3" (click)="submitApplicationMaterials()" nz-button
                            nzSize="large" [nzType]="'primary'" nzShape="round"> <span *ngIf="sjmMgr.updatingMaterial"
                                nz-icon nzType="loading" nzTheme="outline"></span>Next: Follow-up Questions</button>

                        <div class="d-flex" *ngIf="currentStep === 4">
                            <button (click)="skipFollowUpQuestions()" nz-button nzSize="large" [nzType]="'default'"
                                nzShape="round" class="mr-16">Skip this step</button>

                            <button (click)="submitFollowUpQuestions()" nz-button nzSize="large" [nzType]="'primary'"
                                nzShape="round"> <span *ngIf="followUpMgr.isLoading" nz-icon nzType="loading"
                                    nzTheme="outline"></span>
                                Next: AI Settings</button>
                        </div>
                        <button *ngIf="currentStep === 5" (click)="finishUploadingJob()" nz-button nzSize="large"
                            [nzType]="'primary'" nzShape="round"> <span *ngIf="sjmMgr.uploadingJob" nz-icon
                                nzType="loading" nzTheme="outline"></span>Finish Uploading Job</button>
                    </div>

                </div>
            </div>
        </div>


    </main>
</div>