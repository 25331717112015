<div class="upload-container" appDnd (fileDropped)="onFileDropped($event)" *ngIf="selectedFiles.length === 0">
    <input type="file" #fileDropRef id="fileDropRef" multiple accept=".pdf, .doc, .docx, .pages"
        (change)="onFileSelected($event)" />
    <div class="button-icon">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.5 22.167h21V24.5h-21v-2.333zM15.167 6.799v13.034h-2.334V6.8L5.75 13.883l-1.65-1.65 9.9-9.9 9.9 9.9-1.65 1.65-7.083-7.081v-.003z"
                fill="#1C1A20" fill-opacity=".7" />
        </svg>
    </div>
    <p>Click or drag Candidate files to batch upload</p>
    <h3>Note: Please note that CLARA supports only one file per Candidate. If you have multiple files for a single
        Candidate, please merge them before uploading them into CLARA.</h3>
    <h4>Formats supported: PDF, Word, and Pages.</h4>
</div>
<div *ngIf="selectedFiles.length > 0">
    <div class="preview-file" *ngFor="let file of selectedFiles; let i = index">
        <ng-container>
            <div class="d-flex align-middle">
                <span class="checkmark-green" nz-icon nzType="check"></span>
                <span class="file-name">{{ file.name }}</span>
            </div>
            <span class="cancel-file" nz-icon nzType="close" nzTheme="outline" (click)="removeFile(i)"></span>
        </ng-container>
    </div>
</div>