<div class="page">

    <!-- Side menu -->
    <app-side-menu></app-side-menu>
    <!-- main -->
    <main class="main">

        <div nz-row [nzGutter]="16">
            <div nz-col [nzSpan]="20">
                <nz-breadcrumb>
                    <nz-breadcrumb-item>
                        <a routerLink="/"><span nz-icon nzType="home"></span></a>
                    </nz-breadcrumb-item>
                    <nz-breadcrumb-item><a routerLink="/jobs">Jobs</a></nz-breadcrumb-item>
                    <nz-breadcrumb-item>{{jobTitle ? jobTitle : 'Job Settings'}}</nz-breadcrumb-item>
                </nz-breadcrumb>
            </div>
        </div>

        <div class="heading">
            <a routerLink="/jobs"><span nz-icon class="heading-icon" nzType="left" nzTheme="outline"></span></a>
            <h1 class="font-heading">Edit Job Flow</h1>
        </div>
        <nz-spin [nzSpinning]="jobsMgr.isLoadingJobFlow">
            <form [formGroup]="jobForm">
                <div class="form-group">
                    <div nz-row [nzGutter]="16">
                        <div nz-col [nzSpan]="18">
                            <div class="white-box">
                                <div class="white-box-header">
                                    <div class="job-steps">
                                        <div class="steps-item" [ngClass]="getStepClass(1)">
                                            <div class="step-dot">
                                                <div class="white-dot"></div><span class="checkmark" nz-icon
                                                    nzType="check"></span>
                                            </div>
                                            <span class="step-title">Edit Flow</span>
                                        </div>
                                        <div class="steps-item" [ngClass]="getStepClass(2)">
                                            <div class="step-dot">
                                                <div class="white-dot"></div><span class="checkmark" nz-icon
                                                    nzType="check"></span>
                                            </div>
                                            <span class="step-title">Finalize</span>
                                        </div>

                                    </div>

                                </div>
                                <div class="white-box-body">
                                    <ng-container *ngIf="currentStep === 1">
                                        <div class="form-group">
                                            <div class="text-box">
                                                <p class="ico-title"><svg width="24" height="24" viewBox="0 0 24 24"
                                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16.001A8 8 0 0 0 12 20zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z"
                                                            fill="#F2B440" />
                                                    </svg>
                                                    <strong>{{jobObjectId ? 'NOTE: Custom Job Flow' : 'NOTE'}}</strong>
                                                </p>
                                                <ng-container *ngIf="jobObjectId; else noJobFlow">
                                                    <p class="m-0">Once customized, this local job flow will remain
                                                        unchanged and will not be affected by any future
                                                        updates to the global job flows.</p>
                                                </ng-container>
                                                <ng-template #noJobFlow>
                                                    <p class="m-0">Updating the Job Flow will update all jobs, except
                                                        those
                                                        with customized flows.</p>
                                                </ng-template>
                                            </div>
                                            <div class="text-box">
                                                <p><strong>Job Statuses</strong></p>
                                                <p>Job statuses represent the various stages a job progresses through
                                                    during its
                                                    lifecycle (e.g., active, closed). These
                                                    statuses are applied to jobs managed within CLARA. If a job is
                                                    imported via
                                                    an
                                                    ATS
                                                    integration, its statuses will
                                                    automatically sync with those in your ATS.</p>
                                                <p class="m-0">There must be at least 1 job status in CLARA.</p>
                                            </div>
                                            <div class="panels" *ngIf="jobsMgr.stages">
                                                <div formArrayName="statuses" cdkDropList
                                                    (cdkDropListDropped)="dropStages($event)">
                                                    <div class="edit-panel"
                                                        *ngFor="let stage of statusesArray.controls; let i = index"
                                                        [formGroupName]="i" cdkDrag>
                                                        <div class="d-flex align-middle flex-1">
                                                            <div class="holder"><svg width="20" height="20"
                                                                    viewBox="0 0 20 20" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <mask id="34vhsv3ala" style="mask-type:alpha"
                                                                        maskUnits="userSpaceOnUse" x="0" y="0"
                                                                        width="20" height="21">
                                                                        <path fill="#D9D9D9" d="M0 0h20v20H0z" />
                                                                    </mask>
                                                                    <g mask="url(#34vhsv3ala)">
                                                                        <path
                                                                            d="M7.5 16.667c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 0 1-.49-1.176c0-.459.164-.851.49-1.178.326-.326.719-.49 1.177-.49.458 0 .85.164 1.177.49.327.327.49.72.49 1.178 0 .458-.163.85-.49 1.177-.326.326-.719.49-1.177.49zm5 0c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 0 1-.49-1.176c0-.459.164-.851.49-1.178.326-.326.719-.49 1.177-.49.458 0 .85.164 1.177.49.327.327.49.72.49 1.178 0 .458-.163.85-.49 1.177-.326.326-.719.49-1.177.49zm-5-5c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 0 1-.49-1.176c0-.459.164-.851.49-1.178.326-.326.719-.49 1.177-.49.458 0 .85.164 1.177.49.327.327.49.72.49 1.178 0 .458-.163.85-.49 1.177-.326.326-.719.49-1.177.49zm5 0c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 0 1-.49-1.176c0-.459.164-.851.49-1.178.326-.326.719-.49 1.177-.49.458 0 .85.164 1.177.49.327.327.49.72.49 1.178 0 .458-.163.85-.49 1.177-.326.326-.719.49-1.177.49zm-5-5c-.458 0-.85-.163-1.177-.49A1.605 1.605 0 0 1 5.833 5c0-.458.164-.85.49-1.177.326-.326.719-.49 1.177-.49.458 0 .85.164 1.177.49.327.327.49.72.49 1.177 0 .459-.163.851-.49 1.178-.326.326-.719.49-1.177.49zm5 0c-.458 0-.85-.163-1.177-.49A1.605 1.605 0 0 1 10.833 5c0-.458.164-.85.49-1.177.326-.326.719-.49 1.177-.49.458 0 .85.164 1.177.49.327.327.49.72.49 1.177 0 .459-.163.851-.49 1.178-.326.326-.719.49-1.177.49z"
                                                                            fill="#1C1A20" fill-opacity=".16" />
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                            <div class="panel-title"
                                                                *ngIf="stage.get('job_status_name')?.value === 'Active' || stage.get('job_status_name')?.value === 'Closed'">
                                                                {{ stage.get('job_status_name')?.value }}
                                                            </div>
                                                            <div class="input-item"
                                                                *ngIf="stage.get('job_status_name')?.value !== 'Active' && stage.get('job_status_name')?.value !== 'Closed'">
                                                                <nz-form-control>
                                                                    <input nz-input placeholder="Enter Job Stage"
                                                                        class="w-full"
                                                                        formControlName="job_status_name" />
                                                                </nz-form-control>
                                                            </div>
                                                        </div>
                                                        <button class="ico-delete" [disabled]="(stage.get('job_status_name')?.value === 'Active' || 
                                                                             stage.get('job_status_name')?.value === 'Closed') && 
                                                                             (stage.get('type')?.value === 'system')"
                                                            (click)="removeStage(i)"
                                                            [ngClass]="{'disabled': (stage.get('job_status_name')?.value === 'Active' || 
                                                                                          stage.get('job_status_name')?.value === 'Closed') && 
                                                                                          (stage.get('type')?.value === 'system')}">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M3.333 6.67h13.334v10.833a.833.833 0 0 1-.834.834H4.167a.833.833 0 0 1-.834-.834V6.67zM5 8.337v8.333h10V8.337H5zm2.5 1.666h1.667v5H7.5v-5zm3.333 0H12.5v5h-1.667v-5zm-5-5.833V2.503a.833.833 0 0 1 .834-.833h6.666a.833.833 0 0 1 .834.833V4.17h4.166v1.667H1.667V4.17h4.166zM7.5 3.337v.833h5v-.833h-5z"
                                                                    fill="#1C1A20" fill-opacity=".6" />
                                                            </svg>
                                                        </button>

                                                    </div>
                                                </div>

                                            </div>
                                            <div class="add-button">
                                                <button nz-button nzSize="large" [nzType]="'default'" nzShape="round"
                                                    (click)="addNewStatus()">Add new
                                                    Job
                                                    Status</button>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="text-box">
                                                <p><strong>Screening Steps</strong></p>
                                                <p>Screening steps represent the various stages that a candidate follows
                                                    as they
                                                    are
                                                    screened for hiring (e.g. preliminary
                                                    screening, phone interview, hiring manager interviews, offer, hired,
                                                    onboarding). If a job is imported via an ATS
                                                    integration, its screening steps will automatically sync with those
                                                    in your
                                                    ATS.
                                                </p>
                                                <p class="m-0">There must be at least 1 screening steps in CLARA.</p>
                                            </div>
                                            <div class="panels" *ngIf="jobsMgr.stages">
                                                <div formArrayName="screening_steps" cdkDropList
                                                    (cdkDropListDropped)="dropScreening($event)">
                                                    <div class="edit-panel"
                                                        *ngFor="let step of screeningStepsArray.controls; let i = index"
                                                        [formGroupName]="i" cdkDrag>
                                                        <div class="d-flex align-middle flex-1">
                                                            <div class="holder"><svg width="20" height="20"
                                                                    viewBox="0 0 20 20" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <mask id="34vhsv3ala" style="mask-type:alpha"
                                                                        maskUnits="userSpaceOnUse" x="0" y="0"
                                                                        width="20" height="21">
                                                                        <path fill="#D9D9D9" d="M0 0h20v20H0z" />
                                                                    </mask>
                                                                    <g mask="url(#34vhsv3ala)">
                                                                        <path
                                                                            d="M7.5 16.667c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 0 1-.49-1.176c0-.459.164-.851.49-1.178.326-.326.719-.49 1.177-.49.458 0 .85.164 1.177.49.327.327.49.72.49 1.178 0 .458-.163.85-.49 1.177-.326.326-.719.49-1.177.49zm5 0c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 0 1-.49-1.176c0-.459.164-.851.49-1.178.326-.326.719-.49 1.177-.49.458 0 .85.164 1.177.49.327.327.49.72.49 1.178 0 .458-.163.85-.49 1.177-.326.326-.719.49-1.177.49zm-5-5c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 0 1-.49-1.176c0-.459.164-.851.49-1.178.326-.326.719-.49 1.177-.49.458 0 .85.164 1.177.49.327.327.49.72.49 1.178 0 .458-.163.85-.49 1.177-.326.326-.719.49-1.177.49zm5 0c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 0 1-.49-1.176c0-.459.164-.851.49-1.178.326-.326.719-.49 1.177-.49.458 0 .85.164 1.177.49.327.327.49.72.49 1.178 0 .458-.163.85-.49 1.177-.326.326-.719.49-1.177.49zm-5-5c-.458 0-.85-.163-1.177-.49A1.605 1.605 0 0 1 5.833 5c0-.458.164-.85.49-1.177.326-.326.719-.49 1.177-.49.458 0 .85.164 1.177.49.327.327.49.72.49 1.177 0 .459-.163.851-.49 1.178-.326.326-.719.49-1.177.49zm5 0c-.458 0-.85-.163-1.177-.49A1.605 1.605 0 0 1 10.833 5c0-.458.164-.85.49-1.177.326-.326.719-.49 1.177-.49.458 0 .85.164 1.177.49.327.327.49.72.49 1.177 0 .459-.163.851-.49 1.178-.326.326-.719.49-1.177.49z"
                                                                            fill="#1C1A20" fill-opacity=".16" />
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                            <div class="panel-title"
                                                                *ngIf="step.get('job_stage_name')?.value === 'Hired'">
                                                                {{ step.get('job_stage_name')?.value }}
                                                            </div>
                                                            <div class="input-item"
                                                                *ngIf="step.get('job_stage_name')?.value !== 'Hired'">
                                                                <nz-form-control>
                                                                    <input nz-input placeholder="Enter Screening Step"
                                                                        class="w-full"
                                                                        formControlName="job_stage_name" />
                                                                </nz-form-control>
                                                            </div>
                                                        </div>
                                                        <button class="ico-delete" [disabled]="(step.get('job_stage_name')?.value === 'Hired') && 
                                                                        (step.get('type')?.value === 'system')"
                                                            (click)="removeScreeningStep(i)"
                                                            [ngClass]="{'disabled': (step.get('job_stage_name')?.value === 'Hired') && 
                                                                                     (step.get('type')?.value === 'system')}">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M3.333 6.67h13.334v10.833a.833.833 0 0 1-.834.834H4.167a.833.833 0 0 1-.834-.834V6.67zM5 8.337v8.333h10V8.337H5zm2.5 1.666h1.667v5H7.5v-5zm3.333 0H12.5v5h-1.667v-5zm-5-5.833V2.503a.833.833 0 0 1 .834-.833h6.666a.833.833 0 0 1 .834.833V4.17h4.166v1.667H1.667V4.17h4.166zM7.5 3.337v.833h5v-.833h-5z"
                                                                    fill="#1C1A20" fill-opacity=".6" />
                                                            </svg>
                                                        </button>

                                                    </div>
                                                </div>

                                            </div>
                                            <div class="add-button">
                                                <button nz-button nzSize="large" [nzType]="'default'" nzShape="round"
                                                    (click)="addNewScreeningStep()">Add new
                                                    Screening Step</button>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="currentStep === 2">

                                        <div class="text-box">
                                            <p class="ico-title"><svg width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16.001A8 8 0 0 0 12 20zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z"
                                                        fill="#F2B440" />
                                                </svg>
                                                <strong>{{jobObjectId ? 'NOTE: Custom Job Flow' : 'NOTE'}}</strong>
                                            </p>
                                            <ng-container *ngIf="jobObjectId; else noJobFlow">
                                                <p class="m-0">Once customized, this local job flow will remain
                                                    unchanged and will not be affected by any future
                                                    updates to the global job flows.</p>
                                            </ng-container>
                                            <ng-template #noJobFlow>
                                                <p class="m-0">Updating the Job Flow will update all jobs, except those
                                                    with customized flows.</p>
                                            </ng-template>
                                        </div>
                                        <div class="form-group">
                                            <div class="text-box">
                                                <p><strong>Job Statuses</strong></p>
                                                <p>Job statuses represent the various stages a job progresses through
                                                    during its
                                                    lifecycle (e.g., active, closed). These
                                                    statuses are applied to jobs managed within CLARA. If a job is
                                                    imported via
                                                    an
                                                    ATS
                                                    integration, its statuses will
                                                    automatically sync with those in your ATS.</p>
                                                <p class="m-0">There must be at least 1 job status in CLARA.</p>
                                            </div>
                                            <ul>
                                                <li *ngFor="let stage of getStatusesPreview()">
                                                    {{ stage.name }}
                                                    <span *ngIf="stage.type === 'user_added'">Custom</span>
                                                </li>
                                            </ul>
                                            <div class="text-box">
                                                <p><strong>Screening Steps</strong></p>
                                                <p>Screening steps represent the various stages that a candidate follows
                                                    as they
                                                    are
                                                    screened for hiring (e.g. preliminary
                                                    screening, phone interview, hiring manager interviews, offer, hired,
                                                    onboarding). If a job is imported via an ATS
                                                    integration, its screening steps will automatically sync with those
                                                    in your
                                                    ATS.
                                                </p>
                                                <p class="m-0">There must be at least 1 screening steps in CLARA.</p>
                                            </div>
                                            <ul>
                                                <li *ngFor="let step of getScreeningStepsPreview()">
                                                    {{ step.name }}
                                                    <span *ngIf="step.type === 'user_added'">Custom</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="white-box-footer">
                                    <button (click)="previousStep()" *ngIf="currentStep === 2" class="mr-8" nz-button
                                        nzSize="large" [nzType]="'default'" nzShape="round">Back</button>

                                    <button *ngIf="currentStep === 1" nz-button nzSize="large" [nzType]="'primary'"
                                        nzShape="round" (click)="nextStep()">
                                        Next:Finalize</button>

                                    <button *ngIf="currentStep === 2" nz-button nzSize="large" [nzType]="'primary'"
                                        nzShape="round" (click)="submitJobFlow()">
                                        Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </nz-spin>
        <nz-modal [(nzVisible)]="jobsMgr.resetGlobalFlowModal" [nzClosable]="false" [nzFooter]="null" nzWidth="572px"
            nzCentered [nzMaskClosable]="false">
            <ng-container *nzModalContent>
                <div class="custom-modal">

                    <div class="close" (click)="handleCancelModal()">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m10 8.822 4.125-4.125 1.178 1.178L11.178 10l4.125 4.125-1.178 1.178L10 11.178l-4.125 4.125-1.178-1.178L8.822 10 4.697 5.875l1.178-1.178L10 8.822z"
                                fill="#1C1A20" fill-opacity=".6" />
                        </svg>
                    </div>
                    <h2 class="font-heading text-center">Reset to Global Job Flow</h2>
                    <p>Are you sure you want to reset the job flow for Office Manager to the global job flow settings?
                    </p>

                    <div class="custom-footer">
                        <button nz-button nzSize="large" [nzType]="'default'" nzShape="round" class="mr-8"
                            (click)="handleCancelModal()"><span class="color-theme">Close</span></button>
                        <button (click)="submitGlobalJobFlow()" nz-button nzSize="large" [nzType]="'primary'"
                            nzShape="round">Reset Job Flow</button>
                    </div>
                </div>
            </ng-container>
        </nz-modal>
    </main>
</div>