import { Component, EventEmitter, Output } from '@angular/core';
import { FollowUpQuestionsManagerService } from 'src/app/Managers/follow-up-questions-manager.service';
import { SjmManagerService } from 'src/app/Managers/sjm-manager.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-sjmfollow-up-questions',
  templateUrl: './sjmfollow-up-questions.component.html',
  styleUrl: './sjmfollow-up-questions.component.scss',
})
export class SJMfollowUpQuestionsComponent {
  isExpanded = false;
  showEditable = false;
  jobObjectId: string = '';
  form!: FormGroup;
  deleted_questions: { question_obj_id: string }[] = [];
  @Output() submitFollowUpQuestions = new EventEmitter<any | []>();
  constructor(
    private fb: FormBuilder,
    public followUpMgr: FollowUpQuestionsManagerService,
    public sjmMgr: SjmManagerService,
    private cdr: ChangeDetectorRef
  ) {}

  openQuestionsModal(): void {
    this.followUpMgr.isVisibleJobQuestionsModal = true;
  }
  handleCancel(): void {
    this.followUpMgr.isVisibleJobQuestionsModal = false;
  }
  toggleReadMore() {
    this.isExpanded = !this.isExpanded;
  }
  toggleEditable() {
    this.showEditable = true;
    this.cdr.markForCheck();
    this.addFollowUpQuestion();
  }

  get followUpQuestions(): FormArray {
    return this.form.get('followUpQuestions') as FormArray;
  }

  addFollowUpQuestion(): void {
    const questionGroup = this.fb.group({
      category: ['', [Validators.required]],
      question: ['', [Validators.required]],
      type: ['free_text', [Validators.required]],
      options: this.fb.array([]),
      config: this.fb.group({
        is_multi_select: [false], // Checkbox for multi-select
        is_user_entered: [false], // Checkbox for user-entered answer
      }),
    });
    this.followUpQuestions.push(questionGroup);
  }

  removeFollowUpQuestion(index: number): void {
    const questionToRemove = this.followUpQuestions.at(index).value;
    this.deleted_questions.push({
      question_obj_id: questionToRemove._id,
    });
    this.followUpQuestions.removeAt(index);
  }
  addOption(index: number): void {
    const optionsArray = this.followUpQuestions
      .at(index)
      .get('options') as FormArray;

    const optionNumber = String.fromCharCode(97 + optionsArray.length); // 97 is the ASCII code for 'a'

    optionsArray.push(
      this.fb.group({
        number: [optionNumber],
        option: [''],
      })
    );
  }

  // Remove an option from the multi_options-options array
  removeOption(questionIndex: number, optionIndex: number): void {
    const optionsArray = this.followUpQuestions
      .at(questionIndex)
      .get('options') as FormArray;
    optionsArray.removeAt(optionIndex); // Remove the option at the specified index
  }

  // Method to handle question type selection
  selectType(type: string, index: number): void {
    const questionGroup = this.followUpQuestions.at(index) as FormGroup;
    questionGroup.get('type')?.setValue(type);

    // Clear options if type changes away from multi_options-select
    if (type !== 'multi_options') {
      const optionsArray = questionGroup.get('options') as FormArray;
      optionsArray.clear();
    }
  }

  // Get the options form array for a specific question
  getOptionsArray(index: number): FormArray {
    return this.followUpQuestions.at(index).get('options') as FormArray;
  }

  toggleEdit(categoryId: number, questionIndex: number): void {
    const category = this.followUpMgr.questionsOnJob.find(
      (c) => c.id === categoryId
    );
    if (category) {
      const question = category.questions[questionIndex];
      question.isEditable = !question.isEditable;
    }
  }
  async ngOnInit() {
    this.jobObjectId = this.sjmMgr.sjmJobObjId;
    this.form = this.fb.group({
      followUpQuestions: this.fb.array([]),
    });
    await this.followUpMgr.getJobFollowUpQuestions(this.jobObjectId);
  }
  ngOnDestroy() {
    this.followUpMgr.questionsOnJob = [];
    this.showEditable = false;
  }
}
